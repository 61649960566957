import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core'

import ModalService from './modal.service'

@Component({
  selector: 'app-modal',

  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export default class ModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() id: string

  @Input() errorModal: string

  private element: any

  private focusedElementBeforeModal

  private firstFocusableElementInModal: HTMLScriptElement

  private lastFocusableElementInModal: HTMLScriptElement

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement
    this.focusedElementBeforeModal = document.activeElement
  }

  ngAfterViewInit(): void {
    this.firstFocusableElementInModal = this.getFirstFocusableElementInModal()
    this.lastFocusableElementInModal = this.getLastFocusableElementInModal()
  }

  ngOnInit(): void {
    const modal = this

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id')

      return
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element)

    // close modal on background click
    this.element.addEventListener('click', (event: any) => {
      if (event.target.className === 'modal') {
        modal.close()
      }
    })

    this.element.style.display = 'none'
    // handle tab and escape
    document.body.addEventListener('keydown', this.handleTabAndEscape(modal))

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this)
  }

  /**
   * Remove self from modal service when directive is destroyed
   */
  ngOnDestroy(): void {
    this.modalService.remove(this.id)
    this.element.remove()
    // remove keyup eventlistener
    document.body.removeEventListener('keyup', this.handleTabAndEscape(this))
  }

  /**
   * open modal
   */
  open(): void {
    // Save current focus
    this.focusedElementBeforeModal = document.activeElement
    this.element.style.display = 'block'
    document.body.classList.add('modal-open')
    this.firstFocusableElementInModal.focus()
  }

  /**
   * close modal
   */
  close(): void {
    this.element.style.display = 'none'
    document.body.classList.remove('modal-open')

    // Set focus back to element that had it before the modal was opened
    this.focusedElementBeforeModal.focus()
  }

  /**
   * Sets modal__body classes
   */
  setModalBodyClasses(): string {
    if (this.errorModal === 'true') {
      return 'modal__body modal__body--error'
    }

    return 'modal__body'
  }

  /**
   * Sets tab focus to first tabbable element
   */
  setFocusToFirstChildInModal(): void {
    const firstTabStop = this.getFirstFocusableElementInModal()
    firstTabStop.focus()
  }

  /**
   * Sets tab focus to last tabbable element
   */
  setFocusToLastChildInModal(): void {
    const lastTabStop = this.getLastFocusableElementInModal()
    lastTabStop.focus()
  }

  /**
   * Gets the first tabbable element
   */
  getFirstFocusableElementInModal(): HTMLScriptElement {
    const focusableElements = this.getfocusableElements()

    return (focusableElements as Array<HTMLScriptElement>)[0]
  }

  /**
   * Gets the last tabbable element
   */
  getLastFocusableElementInModal(): HTMLScriptElement {
    const focusableElements = this.getfocusableElements()

    return (focusableElements as Array<HTMLScriptElement>)[focusableElements.length - 1]
  }

  /**
   * Gets all tabbable element
   */
  getfocusableElements(): any {
    const focusableElementsString =
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), ' +
      'iframe, object, embed, [tabindex="0"], [contenteditable]'

    return this.element.querySelectorAll(focusableElementsString)
  }

  /**
   * Trap tab in modal. Escape button closes the modal
   *
   * @param modal the current modal .
   */
  // eslint-disable-next-line class-methods-use-this
  private handleTabAndEscape(modal: this): (event: KeyboardEvent) => any {
    return (event: any) => {
      if (event.which === 9) {
        if (document.activeElement === modal.firstFocusableElementInModal) {
          event.preventDefault()
          modal.setFocusToLastChildInModal()
        }
        if (document.activeElement === modal.lastFocusableElementInModal) {
          event.preventDefault()
          modal.setFocusToFirstChildInModal()
        }
      } else if (event.which === 27) {
        modal.close()
      }
    }
  }
}
