<div *ngIf="customerForm" class="countryCodePickerWrapper pure-material-textfield-outlined" [formGroup]="customerForm">
  <span class="picker-header form__label form__label--required">{{ 'country' | translate }}</span>

  <div class="selectedFlagWrapper">
    <div class="selectedFlagWrapper__inner" (click)="toggleDropDown()">
      <div class="flagIcon flagIcon--{{ selectedFlag }}"></div>
      <div class="dropDownArrow"></div>
    </div>

    <input
      (click)="toggleDropDown()"
      class="countryInput inputText inputText--large"
      [(value)]="selectedCountryDisplayName"
      autocomplete="countryCode"
      readonly
    />

    <input
      class="inputText filterInput"
      placeholder="Filter"
      [(ngModel)]="filterValue"
      [ngModelOptions]="{ standalone: true }"
      (keyup)="keyup($event, $event.target.value)"
      [ngClass]="{ show: showDropdown, hide: !showDropdown }"
      tabindex="0"
    />

    <ul [ngClass]="{ show: showDropdown, hide: !showDropdown }" class="countryList" tabindex="0">
      <div>
        <li
          *ngFor="let country of sortedCountries | filterCountries: { name: filterValue }"
          class="countryList__item"
          (click)="countrySelected(country.code, country.name)"
          tabindex="0"
          (focus)="addSelectedClass($event)"
          (keydown)="triggerClick($event)"
        >
          <p class="countryName">{{ country.name }}</p>
        </li>
      </div>
    </ul>
  </div>
</div>
