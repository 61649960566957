import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import BookingService from '../../core/booking.service'
import Insurance from '../../core/models/insurance'
import Reservation from '../../core/models/reservation'

@Component({
  selector: 'app-confirmation-additional-services',
  templateUrl: './confirmation-additional-services.component.html',
  styleUrls: ['./confirmation-additional-services.component.scss'],
})
export default class ConfirmationAdditionalServicesComponent implements OnInit {
  @Input() reservation: Reservation

  insurances: Insurance
  sum: number
  currency: string

  constructor(private router: Router, private service: BookingService) {}

  ngOnInit(): void {
    this.insurances = this.reservation?.insurances

    const days =
      this.reservation?.pickupDate && this.reservation?.returnDate
        ? this.getDays(this.reservation?.pickupDate, this.reservation?.returnDate)
        : 1
    this.sum = days * this.service.getTotalCostForInsurances(this.insurances)

    // eslint-disable-next-line
    if (this.insurances?.pai?.dailyRate?.currency) {
      this.currency = this.insurances.pai.dailyRate.currency
    } else if (this.insurances?.cdw?.dailyRate?.currency) {
      this.currency = this.insurances.cdw.dailyRate.currency
    } else if (this.insurances?.scw?.dailyRate?.currency) {
      this.currency = this.insurances.scw.dailyRate.currency
    } else if (this.insurances?.tp?.dailyRate?.currency) {
      this.currency = this.insurances.tp.dailyRate.currency
    } else {
      this.currency = ''
    }
  }

  /**
   * Navigate to additional services
   */
  navigateToAdditionalServices(): void {
    this.router.navigate(['/additional-services'], { queryParams: { edit: 'edit' } })
  }

  getDays(start: Date, end: Date): number {
    const date1 = new Date(start)
    const date2 = new Date(end)

    const rentalMinutes = (date2.getTime() - date1.getTime()) / 60000
    const rentalDays = Math.floor(rentalMinutes / 1440)
    const overhangingMinutes = rentalMinutes % 1440

    let insuranceDays = rentalDays

    // There is a grace period where the customer doesn't get charged an extra day of insurance if
    // the rental is less than 30 minutes over the last day
    if (overhangingMinutes >= 30) {
      insuranceDays += 1
    }

    return insuranceDays === 0 ? 1 : insuranceDays
  }
}
