<ul class="mainNav__items">
  <li class="mainNav__item">
    <a class="navigation__desktop__link" [routerLink]="['/my-booking']">
      {{ 'my-booking' | translate }}
    </a>
  </li>
  <li class="mainNav__item">
    <a
      *ngIf="finland()"
      class="navigation__desktop__link {{ privacyPolicyLinkClassName }} menu-{{ privacyPolicyLinkClassName }}"
      href="//www.hertz.fi/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp"
      target="_blank"
    >
      {{ 'privacy-policy' | translate }}
    </a>
    <a
      *ngIf="sweden()"
      class="navigation__desktop__link {{ privacyPolicyLinkClassName }} menu-{{ privacyPolicyLinkClassName }}"
      href="//www.hertz.se/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp"
      target="_blank"
    >
      {{ 'privacy-policy' | translate }}
    </a>
    <a
      *ngIf="norway()"
      class="navigation__desktop__link {{ privacyPolicyLinkClassName }} menu-{{ privacyPolicyLinkClassName }}"
      href="//www.hertz.com/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp"
      target="_blank"
    >
      {{ 'privacy-policy' | translate }}
    </a>
    <a
      *ngIf="denmark()"
      class="navigation__desktop__link {{ privacyPolicyLinkClassName }} menu-{{ privacyPolicyLinkClassName }}"
      href="//www.hertzdk.dk/rentacar/navigation/templates/legalView.jsp"
      target="_blank"
    >
      {{ 'privacy-policy' | translate }}
    </a>
  </li>
  <li class="mainNav__item mainNav__helpLnk">
    <a class="navigation__desktop__link" [routerLink]="['/help']">
      {{ 'help' | translate }}
    </a>
  </li>
</ul>

<div class="mainNav__langPicker">
  <app-change-language></app-change-language>
</div>
