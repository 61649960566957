import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import CoreModule from '../core/core.module'
import HelpRoutingModule from './help-routing.module'
import HelpComponent from './help.component'

@NgModule({
  imports: [CommonModule, HelpRoutingModule, CoreModule],
  declarations: [HelpComponent],
  exports: [HelpComponent],
})
// eslint-disable-next-line
export class HelpModule {}
