<div class="block marginTop">
  <div class="grid-row">
    <div class="carRentalHeading">
      <h1 class="page__heading">{{ 'my-booking-title' | translate }}</h1>
      <p class="page__subHeading">{{ 'my-booking-subtitle' | translate }}</p>
      <p class="reservation">{{ 'reservation-nr' | translate }} {{ booking?.reservation.id }}</p>
    </div>

    <div class="separatorLine">
      <div class="border-vertical-yellow"></div>
    </div>

    <div class="carInformationHeading">
      <div class="carImageWrapper">
        <img
          src="{{ car?.carGroupImageUrl ? car?.carGroupImageUrl : '../../../assets/img/volvo-xc40.jpg' }}"
          alt="{{ car?.carGroupDescription }}"
        />
      </div>

      <div class="carTextWrapper">
        <div class="carModelWrapper">
          <p class="car__model page__subHeading">
            {{ car?.carGroupDescription }}
            <span *ngIf="!car?.carGroupIsGuaranteedModel">
              {{ 'my-booking-change-car-or-similar' | translate }}
            </span>
          </p>
        </div>

        <p class="car__class">
          {{ car?.carGroupAcrissDescription }}
        </p>
      </div>
    </div>
  </div>
</div>
