<div id="driver-details-step">
  <app-stepper></app-stepper>

  <!-- HEADER START -->
  <header class="block blockPadding">
    <div class="row">
      <div class="col">
        <h1 class="page__heading">{{ 'driver-details' | translate }}</h1>
        <p class="page__subHeading">{{ 'driver-details-subtitle' | translate }}</p>
      </div>
    </div>
  </header>
  <div class="separator-line"></div>

  <!-- HEADER END -->
  <div class="block smallPaddingTop">
    <div [ngClass]="{ column: finland() || denmark(), row: sweden() || norway() }">
      <!-- AUTOFILL START -->
      <div class="col-md-6 autoFillMobile autoFillMobile--borderBottom" *ngIf="sweden() || norway()">
        <app-driver-details-autofill-se *ngIf="sweden()" (autoFill)="onAutofill($event)">
        </app-driver-details-autofill-se>
        <app-driver-details-autofill-no *ngIf="norway()" (autoFill)="onAutofill($event)">
        </app-driver-details-autofill-no>
      </div>
      <!-- AUTOFILL END -->
      <!-- CUSTOMER FORM START -->
      <div class="col-md-6 marginAuto">
        <form id="customerForm" *ngIf="customerForm" [formGroup]="customerForm" (ngSubmit)="onSubmit()">
          <div class="block--gray marginBottom paddingBottom">
            <!-- SVENGELSKA DRIVER SKA LINJERA MED INPUT -->
            <ng-container *ngIf="sweden() && getSelectedLang() !== languageOptions.nativeLanguage">
              <div class="alignFixSweEng">
                <div class="alignFixSweEng__inner">
                  <div class="alignFixSweEng__inner__block"></div>
                  <h2 class="section__heading alignFixSweEng__inner__heading">{{ 'customer' | translate }}</h2>
                </div>
              </div>
            </ng-container>

            <h2
              *ngIf="!sweden() || (sweden() && getSelectedLang() === languageOptions.nativeLanguage)"
              class="section__heading"
            >
              {{ 'customer' | translate }}
            </h2>

            <div class="marginBottom-sm">
              <div class="form__block">
                <label
                  class="pure-material-textfield-outlined"
                  [ngClass]="{ 'pure-material-textfield-outlined-error': firstName.invalid && firstName.touched }"
                >
                  <input placeholder=" " type="text" formControlName="firstName" autocomplete="firstName" />
                  <span>{{ 'first-name' | translate }}</span>
                </label>

                <p class="form__error leftPadding" *ngIf="firstName.invalid && firstName.touched">
                  {{ 'first-name-error' | translate }}
                </p>
              </div>

              <div class="form__block">
                <label
                  class="pure-material-textfield-outlined"
                  [ngClass]="{ 'pure-material-textfield-outlined-error': lastName.invalid && lastName.touched }"
                >
                  <input placeholder=" " type="text" formControlName="lastName" autocomplete="lastName" />
                  <span>{{ 'last-name' | translate }}</span>
                </label>

                <p class="form__error leftPadding" *ngIf="lastName.invalid && lastName.touched">
                  {{ 'last-name-error' | translate }}
                </p>
              </div>

              <div class="form__block">
                <label
                  *ngIf="!finland()"
                  class="pure-material-textfield-outlined"
                  [ngClass]="{ 'pure-material-textfield-outlined-error': address.invalid && address.touched }"
                >
                  <input placeholder=" " type="text" formControlName="address" autocomplete="street-address" />
                  <span>{{ 'address' | translate }}</span>
                </label>

                <label
                  *ngIf="finland() && getSelectedLang() === languageOptions.nativeLanguage"
                  class="pure-material-textfield-outlined"
                  [ngClass]="{ 'pure-material-textfield-outlined-error': address.invalid && address.touched }"
                >
                  <input placeholder=" " type="text" formControlName="address" autocomplete="street-address" />
                  <span>{{ 'address-finland' | translate }}</span>
                </label>

                <label
                  *ngIf="finland() && getSelectedLang() !== languageOptions.nativeLanguage"
                  class="pure-material-textfield-outlined"
                  [ngClass]="{ 'pure-material-textfield-outlined-error': address.invalid && address.touched }"
                >
                  <input placeholder=" " type="text" formControlName="address" autocomplete="street-address" />
                  <span>{{ 'address' | translate }}</span>
                </label>

                <p class="form__error leftPadding" *ngIf="address.invalid && address.touched">
                  {{ 'address-error' | translate }}
                </p>
              </div>

              <div class="form__block">
                <label
                  class="pure-material-textfield-outlined"
                  [ngClass]="{ 'pure-material-textfield-outlined-error': postCode.invalid && postCode.touched }"
                >
                  <input placeholder=" " type="text" formControlName="postCode" autocomplete="postal-code" />
                  <span>{{ 'zip-code' | translate }}</span>
                </label>

                <p class="form__error leftPadding" *ngIf="postCode.invalid && postCode.touched">
                  {{ 'zip-error' | translate }}
                </p>
              </div>

              <div class="form__block">
                <label
                  class="pure-material-textfield-outlined"
                  [ngClass]="{ 'pure-material-textfield-outlined-error': city.invalid && city.touched }"
                >
                  <input placeholder=" " type="text" formControlName="city" autocomplete="address-level2" />
                  <span>{{ 'city' | translate }}</span>
                </label>
                <p class="form__error leftPadding" *ngIf="city.invalid && city.touched">
                  {{ 'city-error' | translate }}
                </p>
              </div>

              <app-country-picker
                [formControlIsRequired]="formControlIsRequired('countryCode')"
                [customerForm]="customerForm"
                [selectedCountry]="selectedCountry"
              ></app-country-picker>

              <div class="form__error error-area" *ngIf="countryCode.invalid && countryCode.touched">
                <p class="form__error">
                  {{ 'country-error' | translate }}
                </p>
              </div>
            </div>

            <div class="separator"></div>
            <div class="form__block">
              <!-- Telephone / Flag picker -->
              <app-telephone-country-code-picker
                [formControlIsRequired]="formControlIsRequired('telephone')"
                [customerForm]="customerForm"
              ></app-telephone-country-code-picker>

              <div class="form__errorRow error-area" *ngIf="telephone.invalid && telephone.touched">
                <p class="form__error">
                  {{ 'telephone-error' | translate }}
                </p>
              </div>

              <div class="form__block">
                <label
                  class="pure-material-textfield-outlined"
                  [ngClass]="{ 'pure-material-textfield-outlined-error': emailAddress.invalid && emailAddress.touched }"
                >
                  <input
                    [ngClass]="{ 'inputText-disabled': emailIsDisabled }"
                    placeholder=" "
                    type="email"
                    formControlName="emailAddress"
                    autocomplete="email"
                  />
                  <span>{{ 'email' | translate }}</span>
                  <span *ngIf="emailIsDisabled" tabindex="0" (click)="editEmail()" class="btn__edit"></span>
                </label>
                <p class="form__error leftPadding" *ngIf="emailAddress.invalid && emailAddress.touched">
                  {{ 'email-error' | translate }}
                </p>
              </div>
            </div>
          </div>

          <div class="block--gray paddingBottom">
            <div>
              <div class="form__row flex-column">
                <label class="section__heading">{{ 'customer-driving-license' | translate }}</label>
                <a
                  tabindex="0"
                  id="driver-details-license-info-link"
                  class="btn__link btn__help {{ infoButtonOrLinkClassName }}"
                  (click)="openModal('driving-license-modal')"
                >
                  {{ 'help' | translate }}</a
                >

                <app-modal id="driving-license-modal">
                  <app-driver-details-driving-license-help></app-driver-details-driving-license-help>
                </app-modal>
              </div>

              <div formGroupName="driverLicense">
                <app-issuing-country-picker
                  [formControlIsRequired]="formControlIsRequired('driverLicense.licenseIssueLocation')"
                  [customerForm]="customerForm"
                  [selectedIssuingCountry]="selectedIssuingCountry"
                ></app-issuing-country-picker>

                <div class="form__block">
                  <label
                    class="pure-material-textfield-outlined"
                    [ngClass]="{
                      'pure-material-textfield-outlined-error': licenseNumber.invalid && licenseNumber.touched
                    }"
                  >
                    <input
                      ssnValidator
                      placeholder=" "
                      type="text"
                      formControlName="licenseNumber"
                      (keyup)="licenceNumberkeyup($event.target.value)"
                    />
                    <span>{{ 'driver-license-number' | translate }}</span>
                  </label>
                  <p
                    class="form__error leftPadding"
                    *ngIf="selectedIssuingCountry === 'SE' && licenseNumber.invalid && licenseNumber.touched"
                  >
                    {{ 'driver-license-input-help-se' | translate }}
                  </p>
                  <p
                    class="form__error leftPadding"
                    *ngIf="selectedIssuingCountry === 'NO' && licenseNumber.invalid && licenseNumber.touched"
                  >
                    {{ 'driver-license-input-help-no' | translate }}
                  </p>
                  <p
                    class="form__error leftPadding"
                    *ngIf="selectedIssuingCountry === 'FI' && licenseNumber.invalid && licenseNumber.touched"
                  >
                    {{ 'driver-license-input-help-fi' | translate }}
                  </p>
                  <p
                    class="form__error leftPadding"
                    *ngIf="
                      selectedIssuingCountry !== 'SE' &&
                      selectedIssuingCountry !== 'NO' &&
                      selectedIssuingCountry !== 'FI' &&
                      licenseNumber.invalid &&
                      licenseNumber.touched
                    "
                  >
                    {{ 'number-error' | translate }}
                  </p>
                </div>

                <ng-container *ngIf="!driverCountryAndLicenseCountryFIFI()">
                  <div class="form__block">
                    <app-date-picker
                      [controlName]="'licenseIssueDate'"
                      [dateControl]="customerForm.get('driverLicense.licenseIssueDate')"
                      [country]="selectedIssuingCountry"
                      [inputTitle]="'issue-date'"
                      [errorMessage]="'issue-date-error'"
                      [errorMessageExpired]="'issue-date-expired'"
                      [checkIfExpiredMethod]="isDateExpiredPast"
                    >
                    </app-date-picker>

                    <app-date-picker
                      [controlName]="'licenseExpireDate'"
                      [dateControl]="customerForm.get('driverLicense.licenseExpireDate')"
                      [dateToCompare]="customerForm.get('driverLicense.licenseBirthDate')"
                      [country]="selectedIssuingCountry"
                      [inputTitle]="'due-date'"
                      [errorMessage]="'due-date-error'"
                      [errorMessageExpired]="'due-date-expired'"
                      [errorMessageDueDate]="'due-date-not-valid'"
                      [dueDateHasError]="driverLicenseHarDueDateError"
                      [checkIfExpiredMethod]="isDateExpiredFuture"
                      [checkIfDueDateIsValid]="isDateBirthDatePlus120"
                    >
                    </app-date-picker>

                    <app-date-picker
                      [controlName]="'licenseBirthDate'"
                      [dateControl]="customerForm.get('driverLicense.licenseBirthDate')"
                      [dateToCompare]="customerForm.get('driverLicense.licenseExpireDate')"
                      [country]="selectedIssuingCountry"
                      [inputTitle]="'date-of-birth'"
                      [errorMessage]="'date-of-birth-error'"
                      [errorMessageExpired]="'date-of-birth-expired'"
                      [checkIfExpiredMethod]="isDateExpiredPast"
                      [checkIfDueDateIsValid]="isDateBirthDatePlus120"
                      [errorMessageAge]="'date-of-birth-age'"
                      [checkIfAgeIsValid]="isAgeOver18"
                    >
                    </app-date-picker>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- CUSTOMER FORM END -->
    </div>
  </div>

  <div class="block block--smallPadding">
    <div class="col--center-text wrapperPadding">
      <input
        type="button"
        (click)="onSubmit()"
        form="customerForm"
        class="btn__primary btn__md"
        value="{{ 'continue' | translate }}"
      />
      <ng-container *ngIf="customerForm?.invalid && customerForm?.touched && submitActionActive">
        <p
          *ngFor="let errorText of getTranslationArray('driver-details-error-global')"
          class="form__error form__label--global"
        >
          {{ errorText }}
        </p>
      </ng-container>
    </div>
  </div>
</div>
