import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import BookingService from '../core/booking.service'
import GoogleTagManagerService from '../core/google-tag-manager.service'
import LocalStorageService from '../core/local-storage.service'
import Country from '../core/models/country'
import Insurance from '../core/models/insurance'
import Reservation from '../core/models/reservation'

@Component({
  selector: 'app-additional-services',
  templateUrl: './additional-services.component.html',
  styleUrls: ['./additional-services.component.scss'],
})
export default class AdditionalServicesComponent implements OnInit {
  insurances: Insurance

  reservation: Reservation

  sum: number

  currency: string

  liability: boolean

  specialLiability: boolean

  country: Country

  days: number

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private localStorageService: LocalStorageService,
    private googleTagManagerService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    this.country = this.localStorageService.getCurrentCountry()
    this.insurances = this.localStorageService.getInsurances()
    this.liability = this.localStorageService.getLiability()
    this.specialLiability = this.localStorageService.getSpecialLiability()
    const booking = this.localStorageService.getBooking()
    this.reservation = booking?.reservation
    this.days =
      this.reservation?.pickupDate && this.reservation?.returnDate
        ? this.getDays(this.reservation?.pickupDate, this.reservation?.returnDate)
        : 1

    if (!!this.insurances?.scw && this.insurances?.scw.selected) {
      this.selectConnectedInsurance('tp')
      this.selectConnectedInsurance('cdw')
    }
    this.sum = this.days * this.bookingService.getTotalCostForInsurances(this.insurances)

    // eslint-disable-next-line
    if (this.insurances?.pai?.dailyRate?.currency) {
      this.currency = this.insurances.pai.dailyRate.currency
    } else if (this.insurances?.cdw?.dailyRate?.currency) {
      this.currency = this.insurances.cdw.dailyRate.currency
    } else if (this.insurances?.scw?.dailyRate?.currency) {
      this.currency = this.insurances.scw.dailyRate.currency
    } else if (this.insurances?.tp?.dailyRate?.currency) {
      this.currency = this.insurances.tp.dailyRate.currency
    } else {
      this.currency = ''
    }
  }

  selectConnectedInsurance(key: string): void {
    if (!!this.insurances[key] && !this.insurances[key].restriction) {
      this.insurances[key].selected = true
    }
  }

  /**
   * Navigation to next page or to confirmation page if "edit" queryParam is available
   */
  nextPage(): void {
    this.localStorageService.saveInsurances(this.insurances)

    if (this.route.snapshot.queryParams.edit === 'edit') {
      this.confirmCheckIn()
    } else {
      this.localStorageService.setStepValid('/driver-details')
      this.router.navigate(['/driver-details'])
    }

    this.googleTagManagerService.triggerGtmEvent({
      event: 'next_step',
      step_on_event: 'additional_insurances',
      ...this.bookingService.getInsuranceSelection(this.insurances),
    })
  }

  insuranceCostChanged(): void {
    this.sum = this.days * this.bookingService.getTotalCostForInsurances(this.insurances)
  }

  /**
   * PUT booking information to backend and navigate to confirmation page on click
   */
  confirmCheckIn(): void {
    const callback = () => {
      this.localStorageService.setStepValid('/confirmation')
      this.router.navigate(['/confirmation'])
    }
    this.bookingService.putBooking(callback.bind(this))
  }

  getDays(start: Date, end: Date): number {
    const date1 = new Date(start)
    const date2 = new Date(end)

    const rentalMinutes = (date2.getTime() - date1.getTime()) / 60000
    const rentalDays = Math.floor(rentalMinutes / 1440)
    const overhangingMinutes = rentalMinutes % 1440

    let insuranceDays = rentalDays

    // There is a grace period where the customer doesn't get charged an extra day of insurance if
    // the rental is less than 30 minutes over the last day
    if (overhangingMinutes >= 30) {
      insuranceDays += 1
    }

    return insuranceDays === 0 ? 1 : insuranceDays
  }
}
