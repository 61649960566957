<div class="stepper">
  <ul class="stepper__items">
    <ng-container *ngFor="let stepUrl of getStepUrls(); let index = index">
      <li
        *ngIf="linkEnabled(index)"
        [class]="getStepperClass(index)"
        routerLinkActive="stepper__item--selected"
        routerLink="/{{ stepUrl }}"
      >
        <a class="stepper__item__link">
          {{ stepUrl | translate }}
        </a>
      </li>
      <li *ngIf="!linkEnabled(index)" [class]="getStepperClass(index)">
        <a class="stepper__item__link">
          {{ stepUrl | translate }}
        </a>
      </li>
    </ng-container>
  </ul>
</div>
