<div class="modal-content">
  <h2>{{ 'driver-details-bisnode-help-subtitle' | translate }}</h2>
  <hr />
  <p class="modal__paragraph">
    {{ 'driver-details-bisnode-help-p1' | translate }}
  </p>
  <p class="modal__paragraph">
    {{ 'driver-details-bisnode-help-p2' | translate }}
  </p>
  <p class="modal__paragraph">
    {{ 'driver-details-bisnode-help-p3' | translate }}
  </p>
  <button class="btn__modal" tabindex="0" (click)="closeModal('driver-details-bisnode-help-modal')">
    {{ 'close' | translate }}
  </button>
</div>
