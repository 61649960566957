<div id="credit-card-step">
  <app-stepper></app-stepper>
  <header class="block blockPadding">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h1 class="page__heading">{{ 'credit-card-title' | translate }}</h1>
          <ng-container *ngIf="!finland()">
            <p class="page__subHeading">{{ 'credit-card-subtitle' | translate }}</p>
          </ng-container>
          <ng-container
            *ngIf="
              finland() && getSelectedLang() !== languageOptions.nativeLanguage && !creditCard?.maskedCreditCardNumber
            "
          >
            <p class="page__subHeading">{{ 'credit-card-subtitle' | translate }}</p>
          </ng-container>
        </div>
      </div>
    </div>
  </header>

  <div class="separator-line"></div>

  <ng-container *ngIf="creditCard?.maskedCreditCardNumber && !showCreditCardNeeded">
    <app-credit-card-registered
      [creditCardNumber]="creditCard?.maskedCreditCardNumber"
      [creditCardName]="creditCard?.paymentMethod"
    ></app-credit-card-registered>
  </ng-container>

  <ng-container *ngIf="!creditCard?.maskedCreditCardNumber || showCreditCardNeeded">
    <app-credit-card-needed></app-credit-card-needed>
  </ng-container>

  <app-modal id="credit-card-error-modal" errorModal="true">
    <app-credit-card-error></app-credit-card-error>
  </app-modal>
</div>
