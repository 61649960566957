<div
  *ngIf="customerForm"
  class="countryCodePickerWrapper pure-material-textfield-outlined marginBottom-sm"
  [formGroup]="customerForm"
>
  <span [ngClass]="{ 'inputText-disabled': phoneIsDisabled }" class="picker-header form__label form__label--required">
    {{ 'telephone' | translate }}</span
  >

  <div class="selectedFlagWrapper">
    <div *ngIf="!phoneIsDisabled" class="selectedFlagWrapper__inner" (click)="toggleDropDown()" tabindex="0">
      <div class="flagIcon flagIcon--{{ selectedFlag }}"></div>
      <div class="dropDownArrow"></div>
    </div>

    <input
      (input)="handleChange($event)"
      class="inputText inputText--large phoneInput"
      formControlName="telephone"
      [ngClass]="{ 'inputText-disabled': phoneIsDisabled }"
      type="tel"
      autocomplete="tel"
      inputmode="tel"
    />

    <span *ngIf="phoneIsDisabled" tabindex="0" (click)="editPhoneNumber()" class="btn__edit"></span>

    <input
      class="inputText filterInput"
      placeholder="Filter"
      [(ngModel)]="filterValue"
      [ngModelOptions]="{ standalone: true }"
      (keyup)="keyup($event, $event.target.value)"
      [ngClass]="{ show: show, hide: !show }"
      tabindex="0"
    />

    <ul [ngClass]="{ show: show, hide: !show }" class="countryList" tabindex="0">
      <div>
        <li
          *ngFor="let country of sortedCountries | filterCountries: { name: filterValue }"
          class="countryList__item"
          (click)="countryCodeSelected(country.code, country.dial_code)"
          tabindex="0"
          (focus)="addSelectedClass($event)"
          (keydown)="triggerClick($event)"
        >
          <p class="countryName">{{ country.name }}</p>
          <p class="dialCode">{{ country.dial_code }}</p>
        </li>
      </div>
    </ul>
  </div>
</div>
