import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { BehaviorSubject } from 'rxjs'

import environment from '../../environments/environment'
import LocalStorageService from './local-storage.service'
import Country from './models/country'

@Injectable({
  providedIn: 'root',
})
export default class TranslateService {
  localeSubject = new BehaviorSubject<string>('')

  data: any = {}

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}

  get currentLocaleOrDefault(): string {
    const locale = this.localStorageService.getLocale()

    return locale || 'en-NO'
  }
  /**
   * @param locale string
   */
  set momentLocale(locale) {
    // Set locale on runtime
    moment.locale(locale)
    this.localeSubject.next(locale)
  }
  /**
   * Tells application to use the provided language
   *
   * @param lang the language to use
   */
  useLanguage(lang: string): Promise<Record<string, unknown>> {
    this.localStorageService.saveCurrentLocale(lang)
    this.momentLocale = lang

    if (lang) {
      // OneTrust has another variant of the norwegian language code
      const oneTrustLang = lang === 'nb-NO' ? 'NO-NO' : lang.toUpperCase()
      document.documentElement.setAttribute('lang', oneTrustLang)
      if ((window as any).OneTrust) (window as any).OneTrust.changeLanguage(oneTrustLang)
    }

    return new Promise<Record<string, unknown>>((resolve, _reject) => {
      const langPath = `assets/i18n/${lang || 'en-NO'}.json`
      this.http.get<Record<string, unknown>>(langPath).subscribe({
        next: (translation) => {
          this.data = { ...(translation || Object) }
          resolve(this.data)
        },
        error: () => {
          this.data = {}
          resolve(this.data)
        },
      })
    })
  }

  mapLocale = (country: Country): string => {
    switch (country) {
      case Country.Sweden:
        return 'sv-SE'
      case Country.Norway:
        return 'nb-NO'
      case Country.Finland:
        return 'fi-FI'
      case Country.Denmark:
        return 'da-DK'
      default:
        return 'en-NO'
    }
  }

  /**
   * Sets the app language
   */
  setAppLanguage(): any {
    const locale = this.mapLocale(this.localStorageService.getCurrentCountry())
    this.localStorageService.saveCurrentLocale(locale)
    this.updateMomentLocale()
    this.useLanguage(locale)
  }

  /**
   * Maps a country string to timezone
   *
   * @param country The string to be mapped to timezone string
   */
  mapCountryToTimeZone = (country: Country): string => {
    switch (country) {
      case Country.Sweden:
        return 'Europe/Stockholm'
      case Country.Norway:
        return 'Europe/Oslo'
      case Country.Finland:
        return 'Europe/Helsinki'
      case Country.Denmark:
        return 'Europe/Copenhagen'
      default:
        return 'Europe/Stockholm'
    }
  }

  /**
   * Sets the app timezone
   */
  setAppTimeZone(): any {
    const country = this.localStorageService.getCurrentCountry()
    const timeZone = this.mapCountryToTimeZone(country)
    this.localStorageService.saveTimeZone(timeZone)
  }

  /**
   * Updates moment locales to use custom date formatting
   */
  updateMomentLocale(): void {
    const country = this.localStorageService.getCurrentCountry()
    this.momentLocale = this.currentLocaleOrDefault

    switch (country) {
      case Country.Sweden:
        moment.updateLocale('sv', {
          longDateFormat: {
            L: 'YYYY-MM-DD',
            LL: 'ddd',
            LLL: 'D MMMM YYYY [kl.] HH:mm',
            LLLL: 'dddd, D MMMM YYYY',
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            lll: 'D MMM YYYY HH:mm',
            llll: 'ddd D MMM YYYY HH:mm',
          },
        })
        moment.updateLocale('en-SE', {
          longDateFormat: {
            L: 'DD/MM/YYYY',
            LL: 'ddd',
            LLL: 'D MMMM YYYY HH:mm',
            LLLL: 'ddd, MMMM D, YYYY',
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            lll: 'D MMM YYYY HH:mm',
            llll: 'ddd D MMM YYYY HH:mm',
          },
        })

        return
      case Country.Norway:
        moment.updateLocale('nb', {
          weekdaysShort: 'søn_man_tir_ons_tor_fre_lør'.split('_'),
          longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD.MM.YYYY',
            LL: 'ddd',
            LLL: 'D. MMMM YYYY [kl.] HH:mm',
            LLLL: 'ddd, D MMMM YYYY',
          },
        })
        moment.updateLocale('en-NO', {
          longDateFormat: {
            L: 'DD/MM/YYYY',
            LL: 'ddd',
            LLL: 'D MMMM YYYY HH:mm',
            LLLL: 'ddd, MMMM D, YYYY',
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            lll: 'D MMM YYYY HH:mm',
            llll: 'ddd D MMM YYYY HH:mm',
          },
        })

        return
      case Country.Finland:
        moment.updateLocale('fi', {
          longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH.mm.ss',
            L: 'DD.MM.YYYY',
            LL: 'ddd',
            LLL: 'Do MMMM[ta] YYYY, [klo] HH.mm',
            LLLL: 'ddd, Do MMMM[ta] YYYY',
            l: 'D.M.YYYY',
            ll: 'Do MMM YYYY',
            lll: 'Do MMM YYYY, [klo] HH:mm',
            llll: 'ddd, Do MMM YYYY, [klo] HH.mm',
          },
        })
        moment.updateLocale('en-FI', {
          longDateFormat: {
            L: 'DD/MM/YYYY',
            LL: 'ddd',
            LLL: 'D MMMM YYYY HH:mm',
            LLLL: 'ddd, MMMM D, YYYY',
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            lll: 'D MMM YYYY HH:mm',
            llll: 'ddd D MMM YYYY HH:mm',
          },
        })

        return
      case Country.Denmark:
        moment.updateLocale('da', {
          longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD.MM.YYYY',
            LL: 'ddd',
            LLL: 'D. MMMM YYYY HH:mm',
            LLLL: 'ddd, D MMMM YYYY',
          },
        })
        moment.updateLocale('en-DK', {
          longDateFormat: {
            L: 'DD/MM/YYYY',
            LL: 'ddd',
            LLL: 'D MMMM YYYY HH:mm',
            LLLL: 'ddd, MMMM D, YYYY',
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            lll: 'D MMM YYYY HH:mm',
            llll: 'ddd D MMM YYYY HH:mm',
          },
        })
        break
      default:
    }
  }

  /**
   * Get translation by given key
   *
   * @param key translation key
   */
  getTranslation(key: string): string {
    if (environment.production) {
      return this.data[key] || ''
    }

    return this.data[key] || '{Translation missing}'
  }

  /**
   * Gets an array of keys to easily loop in template
   *
   * @param key the translation key to get from
   */
  getTranslationArray(key: string): Array<string> {
    if (environment.production) {
      return this.data[key] || ''
    }

    return this.data[key] || ['{Translation missing}']
  }
}
