<div class="container" *ngIf="!!additionalInformation && !!showInfoBox">
  <div class="block block--border block--yellow text-center">
    <a href="#" class="closex" aria-label="close" (click)="dismissInfoBox()">&times;</a>
    <div class="container">
      <strong>
        {{ 'shared-additional-information-header' | translate }}
      </strong>
      <p class="less-margin">
        {{ 'shared-additional-information-paragraph' | translate }}
      </p>
    </div>
    <a target="_blank" href="{{ getReadMoreUrl() }}" class="btn__link">{{ 'read-more' | translate | uppercase }}</a>
  </div>
</div>
