import { Component } from '@angular/core'

import CountryComponent from '../country/country.component'
import LocalStorageService from '../local-storage.service'
import { PRIVACY_POLICY_LINK_CLASSNAME } from 'src/constants/gtmSelectors'

@Component({
  selector: 'app-navigation-drawer',
  templateUrl: './navigation-drawer.component.html',
  styleUrls: ['./navigation-drawer.component.scss'],
})
export default class NavigationDrawerComponent extends CountryComponent {
  privacyPolicyLinkClassName = PRIVACY_POLICY_LINK_CLASSNAME

  // eslint-disable-next-line
  constructor(localStorageService: LocalStorageService) {
    super(localStorageService)
  }

  /**
   * Animate on open
   */
  handleOpen(): void {
    const { body } = document
    const mobileNavOverlay: HTMLElement = document.getElementById('mobileNavOverlay')
    const mobileNav: HTMLElement = document.getElementById('mobileNav')
    body.classList.add('noscroll')
    mobileNavOverlay.style.left = '0'
    mobileNav.style.transform = 'translateX(0)'
  }

  /**
   * Animate on close
   */
  handleClose(): void {
    const { body } = document
    const mobileNavOverlay: HTMLElement = document.getElementById('mobileNavOverlay')
    const mobileNav: HTMLElement = document.getElementById('mobileNav')
    body.classList.remove('noscroll')
    mobileNavOverlay.style.left = '100%'
    mobileNav.style.transition = ''
    mobileNav.style.transform = 'translateX(100%)'
  }
}
