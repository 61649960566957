import { Component } from '@angular/core'

import ModalService from '../../shared/modal/modal.service'

@Component({
  selector: 'app-driver-details-bisnode-help',
  templateUrl: './driver-details-bisnode-help.component.html',
  styleUrls: ['./driver-details-bisnode-help.component.scss'],
})
export default class DriverDetailsBisnodeHelpComponent {
  constructor(private modalService: ModalService) {}

  /**
   * Closes modal by given id
   *
   * @param id modal id
   */
  closeModal(id: string): void {
    this.modalService.close(id)
  }
}
