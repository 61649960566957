<div class="modal-content">
  <h3 class="modal__paragraph--center">{{ 'operated-by' | translate }}</h3>
  <h1>{{ location?.name }}</h1>
  <hr />

  <div class="modal__inner">
    <p class="modal__textWrapper__item modal__paragraph modal__address__label">
      {{ 'address' | translate }}
    </p>
    <p class="modal__textWrapper__item modal__paragraph--bold modal__address__content">
      {{ location?.address }}
    </p>

    <p class="modal__textWrapper__item modal__paragraph modal__phone__label">
      {{ 'telephone' | translate }}
    </p>
    <a class="modal__textWrapper__item btn__link--text modal__phone__content" href="tel:{{ location?.phoneNumber }}">
      {{ location?.phoneNumber }}
    </a>

    <p class="modal__textWrapper__item modal__paragraph modal__openingHours__label">
      {{ 'my-booking-open' | translate }}
    </p>
    <div *ngIf="openingHours" class="modal__textWrapper__item modal__paragraph modal__openingHours__content">
      <div *ngFor="let prop of openingHours" class="openingHours__list" [ngClass]="{ highlightDay: prop.isToday }">
        <ng-container *ngIf="prop.openingTime && prop.closingTime; else elseTemplate">
          <span class="dayShort">{{ prop.day | localizedDateFormat: 'LL' | async }}</span>
          {{ prop.openingTime | localizedDateFormat: 'LT' | async }} -
          {{ prop.closingTime | localizedDateFormat: 'LT' | async }}
        </ng-container>
        <ng-template #elseTemplate>
          <span class="dayShort">{{ prop.day | localizedDateFormat: 'LL' | async }}</span>
          {{ 'closed' | translate }}
        </ng-template>
      </div>
    </div>

    <button class="btn__link--text modal__link" (click)="goToHelp()">
      {{ 'more-help' | translate }}
    </button>
  </div>

  <button class="btn__modal" tabindex="0" (click)="closeModal('contact-station-modal')">
    {{ 'close' | translate }}
  </button>
</div>
