export function isNorwegianLicenseNumberValid(ssn: string): boolean {
  return isLastDigitValid(convertCharToIntArray(ssn)) && isSecondLastDigitValid(convertCharToIntArray(ssn))
}

function isLastDigitValid(numbers: number[]): boolean {
  const digit: number = calculateLastDigit(numbers)
  return digit === numbers[numbers.length - 1]
}

function isSecondLastDigitValid(numbers: number[]): boolean {
  const digit: number = calculateSecondLastDigit(numbers)
  return digit < 10 && digit === numbers[numbers.length - 2]
}

function convertCharToIntArray(ssn: string): number[] {
  const numbers: number[] = ssn.split('').map(Number)
  return numbers
}

function calculateLastDigit(numbers: number[]): number {
  const checksum: number =
    (5 * numbers[0] +
      4 * numbers[1] +
      3 * numbers[2] +
      2 * numbers[3] +
      7 * numbers[4] +
      6 * numbers[5] +
      5 * numbers[6] +
      4 * numbers[7] +
      3 * numbers[8] +
      2 * numbers[9]) %
    11
  return checksum === 0 ? checksum : 11 - checksum
}

function calculateSecondLastDigit(numbers: number[]): number {
  const checksum: number =
    (3 * numbers[0] +
      7 * numbers[1] +
      6 * numbers[2] +
      1 * numbers[3] +
      8 * numbers[4] +
      9 * numbers[5] +
      4 * numbers[6] +
      5 * numbers[7] +
      2 * numbers[8]) %
    11
  return checksum === 0 ? checksum : 11 - checksum
}
