import { Injectable } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { Subject } from 'rxjs'

/**
 * For updating birthDate on ssn change
 */
@Injectable({ providedIn: 'root' })
export default class SSnService {
  newSsn = new Subject<Date>()
  autoFillEvent = new Subject<{ control: AbstractControl; controlName: string }>()
}
