import { Component, Input, OnInit } from '@angular/core'

import * as moment from 'moment'

import ModalService from 'src/app/shared/modal/modal.service'
import { INFO_BUTTON_OR_LINK_CLASSNAME } from 'src/constants/gtmSelectors'

import environment from '../../../environments/environment'
import CountryComponent from '../../core/country/country.component'
import LocalStorageService from '../../core/local-storage.service'
import Location from '../../core/models/location'

@Component({
  selector: 'app-location-more-information',
  templateUrl: './location-more-information.component.html',
  styleUrls: ['./location-more-information.component.scss'],
})
export default class LocationMoreInformationComponent extends CountryComponent implements OnInit {
  @Input() place: Location

  @Input() station: Location

  @Input() date: Date

  @Input() title: string

  @Input() map: boolean

  @Input() operatedBy: boolean

  @Input() returnOrPickup: string

  @Input() gtmSelectorStep: string

  url: string

  openingHours: Array<{ open: string; close: string; weekday: string }>

  isEnglishLanguage: boolean

  showInfoTextsPickup: boolean

  showInfoTextsDropOff: boolean

  isToday: boolean

  locationToShow: { address: string; city: string; name: string }

  informationTexts: {
    misc: { english: string; native: string }
    location: { english: string; native: string }
    dropOff: { english: string; native: string }
    parking: { english: string; native: string }
    return: { english: string; native: string }
  }

  infoButtonOrLinkClassName = INFO_BUTTON_OR_LINK_CLASSNAME

  constructor(private localStorageService: LocalStorageService, private modalService: ModalService) {
    super(localStorageService)
  }

  ngOnInit(): void {
    this.setOpeningHours(this.station)
    this.setMapUrl()
    this.setInformationTexts()
    this.locationToShow = this.getLocationToShow()
  }

  openModal(id: string): void {
    this.modalService.open(id)
  }

  getWeekday(day: any, rowElement: any): string {
    this.isEnglishLanguage = this.localStorageService.getLocale()?.includes('en')

    if (moment(new Date()).isoWeekday() == day.weekday) {
      rowElement.classList.add('activeDay')
    }

    return moment()
      .weekday(this.isEnglishLanguage ? day.weekday : day.weekday - 1)
      .format('ddd')
  }

  /**
   * Set map url by Latitude and Longitude to open Google Maps on native app or browser (depending on your phones settings)
   * https://developers.google.com/maps/documentation/urls/guide
   */
  setMapUrl(): void {
    if (this.place?.geoLat && this.place?.geoLon) {
      const { geoLat, geoLon } = this.place
      this.url = `${environment.googleMapsBaseUrl}search/?api=1&query=${geoLat}%2C${geoLon}`
    }
  }

  private setOpeningHours(location: Location) {
    let openingHours: any = []
    for (let i: number = 1; i < 8; i++) {
      if (location?.regularOpeningHours[i]) {
        openingHours.push({
          open: location?.regularOpeningHours[i].openingTime,
          close: location?.regularOpeningHours[i].closingTime,
          weekday: i,
        })
      } else {
        openingHours.push({ weekday: i, open: null, close: null })
      }
    }

    this.openingHours = openingHours
  }

  private setInformationTexts() {
    this.isEnglishLanguage = this.localStorageService.getLocale()?.includes('en')

    this.informationTexts = {
      misc: {
        english: this.place?.infoTexts.english.misc,
        native: this.place?.infoTexts.native.misc,
      },
      location: {
        english: this.place?.infoTexts.english.location,
        native: this.place?.infoTexts.native.location,
      },
      dropOff: {
        english: this.place?.infoTexts.english.dropOff,
        native: this.place?.infoTexts.native.dropOff,
      },
      parking: {
        english: this.place?.infoTexts.english.parking,
        native: this.place?.infoTexts.native.parking,
      },
      return: {
        english: this.place?.infoTexts.english.return,
        native: this.place?.infoTexts.native.return,
      },
    }

    if (
      this.informationTexts.misc.native ||
      this.informationTexts.location.native ||
      this.informationTexts.parking.native
    ) {
      this.showInfoTextsPickup = true
    }

    if (this.informationTexts.dropOff.native || this.informationTexts.return.native) {
      this.showInfoTextsDropOff = true
    }
  }

  /**
   * Priority for what location should be shown in the more information dialog box
   */
  private getLocationToShow() {
    if (this.place?.dropPointName || this.place?.dropPointAddress || this.place?.dropPointCity) {
      return {
        name: this.place?.dropPointName,
        address: this.place?.dropPointAddress,
        city: this.place?.dropPointCity,
      }
    }
    if (this.place?.name || this.place?.address || this.place?.city) {
      return { name: this.place.name, address: this.place.address, city: this.place.city }
    }
    if (this.station?.dropPointName || this.station?.dropPointAddress || this.station?.dropPointCity) {
      return {
        name: this.station.dropPointName,
        address: this.station.dropPointAddress,
        city: this.station.dropPointCity,
      }
    }
    if (this.station?.name || this.station?.address || this.station?.city) {
      return { name: this.station?.name, address: this.station?.address, city: this.station?.city }
    }
  }
}
