import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import CoreModule from '../core/core.module'
import SharedModule from '../shared/shared.module'
import MyBookingReadMoreComponent from './my-booking-read-more/my-booking-read-more.component'
import MyBookingRoutingModule from './my-booking-routing.module'
import MyBookingSelectCarComponent from './my-booking-select-car/my-booking-select-car.component'
import MyBookingComponent from './my-booking.component'

@NgModule({
  imports: [CommonModule, CoreModule, MyBookingRoutingModule, SharedModule],
  declarations: [MyBookingComponent, MyBookingSelectCarComponent, MyBookingReadMoreComponent],
  exports: [MyBookingComponent],
})
export default class MyBookingModule {}
