import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-info-text-box',
  templateUrl: './info-text-box.component.html',
  styleUrls: ['./info-text-box.component.scss'],
})
export default class InfoTextBoxComponent {
  @Input() title: string

  @Input() icon: string

  @Input() class: string

  @Input() body: { english: string; native: string }

  @Input() isEnglishLanguage: boolean
}
