// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const environment = {
  name: 'test-server',
  production: false,
  baseUrl: '/api',
  booking: '/booking',
  paymentComplete: '/payment/complete',
  paymentInitiate: '/payment/initiate',
  quickFillNorwayBaseUrl: '/quickfill/norway',
  quickFillSwedenBaseUrl: '/quickfill/sweden',
  googleMapsBaseUrl: 'https://www.google.com/maps/',
  additionalInformationSe: 'https://www.hertz.se/rentacar/misc/index.jsp?targetPage=svSE_corona-information.jsp',
  additionalInformationDk: 'https://www.hertzdk.dk/rentacar/misc/index.jsp?targetPage=covid19.jsp',
  additionalInformationNo: 'https://www.hertz.no/p/hertz-together/corona',
  additionalInformationFi: 'https://www.hertz.fi/rentacar/misc/index.jsp?targetPage=fiFI_corona.jsp',
}
export default environment

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
