import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'

import LocalStorageService from './local-storage.service'

@Injectable()

// eslint-disable-next-line
export class AuthGuardService implements CanActivate {
  constructor(private localStorageService: LocalStorageService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.localStorageService.getStepValid(state.url.split('?')[0])
  }
}
