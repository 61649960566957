import { Component, Input, OnInit } from '@angular/core'

import { INFO_BUTTON_OR_LINK_CLASSNAME } from '../../../constants/gtmSelectors'
import Location from '../../core/models/location'
import DialogService from '../dialog/dialog.service'

@Component({
  selector: 'app-shared-location',
  templateUrl: './shared-location.component.html',
  styleUrls: ['./shared-location.component.scss'],
})
export default class SharedLocationComponent implements OnInit {
  @Input() place: Location

  @Input() station: Location

  @Input() date: Date

  @Input() title: string

  @Input() id: string

  @Input() map: boolean

  @Input() operatedBy: boolean

  @Input() returnOrPickup: string

  @Input() gtmSelectorStep: string

  displayMoreInfo: boolean

  infoButtonOrLinkClassName = INFO_BUTTON_OR_LINK_CLASSNAME

  constructor(private dialogService: DialogService) {}

  openDialog(id: string): void {
    this.dialogService.open(id)
  }

  ngOnInit(): void {
    this.displayMoreInfo = this.shouldDisplayMoreInfo()
  }

  private shouldDisplayMoreInfo() {
    if (this.place?.dropPointName || this.place?.dropPointAddress || this.place?.dropPointCity) {
      return true
    }
    if (this.place?.name || this.place?.address || this.place?.city) {
      return true
    }

    return false
  }
}
