<div id="confirmation-step">
  <app-stepper></app-stepper>

  <app-shared-additional-information [additionalInformation]="booking?.additionalInformation">
  </app-shared-additional-information>

  <div class="rowPadding">
    <div class="col-md-12">
      <div class="col--center-text">
        <div class="banner-confirmation">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
            />
          </svg>
          <h1>{{ 'confirmation-title' | translate }}</h1>
        </div>

        <ng-container *ngIf="!finland() && !sweden()">
          <p class="page__subHeading">{{ 'confirmation-subtitle' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="finland() && getSelectedLang() !== languageOptions.nativeLanguage">
          <p class="page__subHeading">{{ 'confirmation-subtitle' | translate }}</p>
        </ng-container>

        <p class="reservation">{{ 'reservation-nr' | translate }} {{ booking?.reservation.id }}</p>
      </div>

      <app-confirmation-car [car]="booking?.reservation.car"></app-confirmation-car>
    </div>
  </div>

  <div class="card block--nomargin">
    <app-shared-location
      [returnOrPickup]="'pickup'"
      [date]="booking?.reservation?.pickupDate"
      [place]="booking?.pickupPlace"
      [station]="booking?.pickupStation"
      [title]="'my-booking-pickup-location'"
      [id]="'my-booking-more-information-pickup'"
      [operatedBy]="booking?.pickupPlace?.operatedByStation"
      [gtmSelectorStep]="'confirmation'"
    >
    </app-shared-location>
    <div class="card__divider">
      <div></div>
    </div>
    <app-shared-location
      [returnOrPickup]="'return'"
      [date]="booking?.reservation?.returnDate"
      [place]="booking?.returnStation"
      [station]="booking?.returnStation"
      [title]="'my-booking-return-location'"
      [id]="'my-booking-more-information-return'"
      [operatedBy]="booking?.returnStation?.operatedByStation"
      [gtmSelectorStep]="'confirmation'"
    >
    </app-shared-location>
  </div>

  <div class="checkin-data">
    <div class="checkin-data__header">
      <h1>{{ 'checkin-data-header' | translate }}</h1>
      <div class="separator-line"></div>
      <p>{{ 'checkin-data-edit' | translate }}</p>
    </div>
    <div>
      <div class="flex-item" *ngIf="!skipInsurances">
        <app-confirmation-additional-services [reservation]="booking?.reservation">
        </app-confirmation-additional-services>
      </div>
      <div class="flex-item">
        <app-confirmation-customer-details
          [licenseNumber]="booking?.reservation.driver.driverLicense.licenseNumber"
          [customer]="booking?.reservation.driver"
        >
        </app-confirmation-customer-details>
      </div>
      <div class="flex-item lastItem" *ngIf="!skipPayment">
        <app-confirmation-credit-card [paymentDetails]="booking?.reservation.paymentDetails">
        </app-confirmation-credit-card>
      </div>
    </div>
  </div>
</div>
