import { Component, Input, OnInit } from '@angular/core'

import LocalStorageService from '../../core/local-storage.service'
import Booking from '../../core/models/booking'

@Component({
  selector: 'app-my-booking-select-car',
  templateUrl: './my-booking-select-car.component.html',
  styleUrls: ['./my-booking-select-car.component.scss'],
})
export default class MyBookingSelectCarComponent implements OnInit {
  @Input() car: any

  booking: Booking

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.booking = this.localStorageService.getBooking()
  }
}
