import LocalStorageService from '../local-storage.service'
import Country from '../models/country'
import LanguageOption from '../models/language-option'

/**
 * Base class used to show or hide template blocks in app
 */
abstract class CountryComponent {
  appCountry: Country

  constructor(localStorageService: LocalStorageService) {
    this.appCountry = localStorageService.getCurrentCountry()
  }

  /**
   * Check if booking location is Sweden
   */
  sweden(): boolean {
    return this.appCountry === Country.Sweden
  }

  /**
   * Check if booking location is Norway
   */
  norway(): boolean {
    return this.appCountry === Country.Norway
  }

  /**
   * Check if booking location is Denmark
   */
  denmark(): boolean {
    return this.appCountry === Country.Denmark
  }

  /**
   * Check if booking location is Finland
   */
  finland(): boolean {
    return this.appCountry === Country.Finland
  }

  /**
   * Get all required strings for available languages for selected country in application
   */
  getLangOptions(): LanguageOption {
    const languageOptions = new LanguageOption()
    if (this.sweden()) {
      languageOptions.nativeLanguage = 'sv-SE'
      languageOptions.alternativeLanguage = 'en-SE'
      languageOptions.nativeCountryShort = 'se'
      languageOptions.alternativeCountryShort = 'gb'
      languageOptions.nativeLanguageShort = 'sv'
      languageOptions.alternativeLanguageShort = 'en'
    }
    if (this.norway()) {
      languageOptions.nativeLanguage = 'nb-NO'
      languageOptions.alternativeLanguage = 'en-NO'
      languageOptions.nativeCountryShort = 'no'
      languageOptions.alternativeCountryShort = 'gb'
      languageOptions.nativeLanguageShort = 'nb'
      languageOptions.alternativeLanguageShort = 'en'
    }
    if (this.denmark()) {
      languageOptions.nativeLanguage = 'da-DK'
      languageOptions.alternativeLanguage = 'en-DK'
      languageOptions.nativeCountryShort = 'dk'
      languageOptions.alternativeCountryShort = 'gb'
      languageOptions.nativeLanguageShort = 'da'
      languageOptions.alternativeLanguageShort = 'en'
    }
    if (this.finland()) {
      languageOptions.nativeLanguage = 'fi-FI'
      languageOptions.alternativeLanguage = 'en-FI'
      languageOptions.nativeCountryShort = 'fi'
      languageOptions.alternativeCountryShort = 'gb'
      languageOptions.nativeLanguageShort = 'fi'
      languageOptions.alternativeLanguageShort = 'fi'
    }

    // TODO: show error page if not able to get country
    return languageOptions
  }
}
export default CountryComponent
