import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import * as moment from 'moment'

import Location from '../../core/models/location'
import ModalService from '../modal/modal.service'

@Component({
  selector: 'app-shared-contact-station',
  templateUrl: './shared-contact-station.component.html',
  styleUrls: ['./shared-contact-station.component.scss'],
})
export default class SharedContactStationComponent implements OnInit {
  @Input() location: Location

  @Input() date: Date

  openingHours: any

  constructor(private modalService: ModalService, private router: Router) {}

  ngOnInit(): void {
    this.createOpeningHoursArray()
  }

  /**
   * Creates an array of objects with "openingTime", "closingTime", "day" (day of the week based on key) and "isToday" (true if the current key is today)
   */
  createOpeningHoursArray(): void {
    const openingHours =
      this.location && this.location.regularOpeningHours ? this.location.regularOpeningHours : undefined
    const openingHoursArray = []

    if (openingHours) {
      Object.keys(openingHours).forEach((key) => {
        const obj = {
          openingTime: openingHours[key].openingTime,
          closingTime: openingHours[key].closingTime,
          day: moment().day(key).toDate(),
          isToday: moment().isoWeekday() === parseInt(key, 10),
        }
        openingHoursArray.push(obj)
      })
      this.openingHours = openingHoursArray
    }
  }

  /**
   * Closes modal by given id
   *
   * @param id string the modal id
   */
  closeModal(id: string): void {
    this.modalService.close(id)
  }

  /**
   * Navigate to help page
   */
  goToHelp(): void {
    this.router.navigate(['help'])
  }
}
