import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import BookingService from './booking.service'
import ChangeLanguageComponent from './change-language/change-language.component'
import DesktopNavigationComponent from './desktop-navigation/desktop-navigation.component'
import FilterCountriesPipe from './filter-countries.pipe'
import LocalizedDateFormatPipe from './localized-date-format.pipe'
import NavigationDrawerComponent from './navigation-drawer/navigation-drawer.component'
import StepperComponent from './stepper/stepper.component'
import TranslatePipe from './translate.pipe'

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [
    ChangeLanguageComponent,
    DesktopNavigationComponent,
    NavigationDrawerComponent,
    StepperComponent,
    TranslatePipe,
    LocalizedDateFormatPipe,
    FilterCountriesPipe,
  ],
  providers: [BookingService],
  exports: [
    DesktopNavigationComponent,
    NavigationDrawerComponent,
    StepperComponent,
    TranslatePipe,
    LocalizedDateFormatPipe,
    FilterCountriesPipe,
  ],
})
export default class CoreModule {}
