<ng-container *ngIf="loading">
  <div class="blockWrapper">
    <div class="block">
      <h1 class="page__heading">{{ 'online-check-in' | translate | uppercase }}</h1>
    </div>
    <div class="block block--gray">
      <div class="loaderContent">
        <h2 class="page__heading loaderHeading">{{ 'fetching-reservation' | translate }}</h2>
        <div class="loadingSpinner"></div>
        <p class="section__body loaderBody">{{ 'please-wait' | translate }}</p>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!loading">
  <header class="header" role="banner">
    <div>
      <a class="header__logo" [routerLink]="['/']">
        <img [src]="logoSrc" [alt]="logoAlt" />
      </a>

      <nav class="mainNav" role="navigation">
        <app-desktop-navigation></app-desktop-navigation>
      </nav>

      <app-navigation-drawer></app-navigation-drawer>
    </div>
  </header>

  <main role="main" class="main">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </main>

  <app-footer></app-footer>
</ng-container>
