import { LayoutModule } from '@angular/cdk/layout'
import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import AdditionalServicesModule from './additional-services/additional-services.module'
import AppRoutingModule from './app-routing.module'
import AppComponent from './app.component'
import ConfirmationModule from './confirmation/confirmation.module'
import { AuthGuardService } from './core/auth-guard.service'
import CoreModule from './core/core.module'
import RequestInterceptor from './core/http-interceptor.service'
import StepperService from './core/stepper/stepper.service'
import TranslateService from './core/translate.service'
import CreditCardRegistrationModule from './credit-card-registration/credit-card-registration.module'
import DriverDetailsModule from './driver-details/driver-details.module'
import { HelpModule } from './help/help.module'
import MyBookingModule from './my-booking/my-booking.module'
import SharedModule from './shared/shared.module'

export const getCurrentLocale = (): string => {
  return localStorage.getItem('locale') || 'en-NO' // TODO: om ex. svenska ändra flagg-komponenten
}

export const setupTranslateFactory = (service: TranslateService) => {
  return () => service.useLanguage(getCurrentLocale())
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    MyBookingModule,
    AdditionalServicesModule,
    DriverDetailsModule,
    CreditCardRegistrationModule,
    ConfirmationModule,
    CoreModule,
    HelpModule,
    SharedModule,
  ],
  providers: [
    TranslateService,
    AuthGuardService,
    StepperService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: getCurrentLocale() },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
