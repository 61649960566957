<div class="bookingWrapper" (click)="openDialog(id)">
  <div class="booking">
    <div class="pickupAndReturnLabel">
      <span>{{ title | translate }}</span>
    </div>
    <div class="booking__info">
      <div class="booking__info__dateSection">
        <div class="booking__info__timeAndDate section__date">
          {{ date | localizedDateFormat: 'LLLL' | async }}
        </div>
        <div class="booking__info__timeAndDate section__date">
          {{ date | localizedDateFormat: 'LT' | async }}
        </div>
      </div>

      <div class="booking__info__location">
        <!-- Display drop point information if available else location information. Fallbacks from name to address then city and displays in correct order if any field is missing -->
        <ng-container
          *ngIf="place?.dropPointName || place?.dropPointAddress || place?.dropPointCity; else locationNameBlock"
        >
          <p class="external__info__section section__body--noMargins">
            {{ place?.dropPointName || place?.dropPointAddress || place?.dropPointCity }}
          </p>
        </ng-container>
        <ng-template #locationNameBlock>
          <p class="external__info__section section__body--noMargins">
            {{ place?.name || place?.address || place?.city || place?.tracCode }}
          </p>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="returnOrPickup === 'pickup' && (place?.infoTexts?.english?.misc || place?.infoTexts?.native?.misc)"
  >
    <div class="importantInfo">
      <img class="alertIcon" src="../../../assets/icons/red_alert.svg" alt="alert icon" />
      <span>{{ 'important-pickup' | translate }}</span>
    </div>
  </ng-container>
  <ng-container
    *ngIf="returnOrPickup === 'return' && (place?.infoTexts?.english?.dropOff || place?.infoTexts?.native?.dropOff)"
  >
    <div class="importantInfo">
      <img class="alertIcon" src="../../../assets/icons/red_alert.svg" alt="alert icon" />
      <span>{{ 'important-return' | translate }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="displayMoreInfo">
    <div class="more">
      <div class="more__info">
        <div class="more__info__btn">
          <button
            id="{{ gtmSelectorStep }}-{{ returnOrPickup }}-location-info-button"
            class="btn__link--text {{ infoButtonOrLinkClassName }}"
            (click)="openDialog(id)"
          >
            {{ 'more-info' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="displayMoreInfo">
  <app-dialog [title]="title" [id]="id">
    <app-location-more-information
      [returnOrPickup]="returnOrPickup"
      [date]="date"
      [place]="place"
      [station]="station"
      [map]="place.geoLat && place.geoLon"
      [operatedBy]="operatedBy"
      [gtmSelectorStep]="gtmSelectorStep"
    ></app-location-more-information>
  </app-dialog>
</ng-container>
