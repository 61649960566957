import { Component, ElementRef, Input, OnInit } from '@angular/core'

/**
 * Accordion component - toggle visibility for wrapped content
 */
@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export default class AccordionComponent implements OnInit {
  /**
   * @param openedBtnText Text for opened button (to close accordion)
   */
  @Input() openedBtnText: string

  /**
   * @param closedBtnText Text for closed button (to open accordion)
   */
  @Input() closedBtnText: string

  /**
   * @param onlyMobile "true" to use toggling only in mobile view
   */
  @Input() onlyMobile: string

  /**
   * @param largeFont "true" to use larger font for button
   */
  @Input() largeFont: string

  /**
   *  @param textAlignLeft "true" to align button text left
   */
  @Input() textAlignLeft: string

  /**
   *  @param textAlignCenter "true" to align button text center
   */
  @Input() textAlignCenter: string

  @Input() gtmSelectorId?: string

  btnText: string

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.btnText = this.closedBtnText
  }

  /**
   * Sets button classes
   */
  setBtnClasses(): void {
    let btn: HTMLElement
    if (this.largeFont === 'true') {
      btn = this.el.nativeElement.firstElementChild.firstElementChild
    } else {
      btn = this.el.nativeElement.lastElementChild.lastElementChild
    }

    if (this.onlyMobile === 'true') {
      btn.classList.add('onlyMobile')
    }

    if (this.textAlignLeft === 'true') {
      this.el.nativeElement.firstElementChild.classList.add('wrapper--textAlignLeft')
    }

    if (this.textAlignCenter === 'true') {
      this.el.nativeElement.firstElementChild.classList.add('wrapper--textAlignCenter')
    }
  }

  /**
   * Toggle accordion
   *
   * @param e event
   */
  toggle(e): void {
    let panel: HTMLElement
    if (this.largeFont === 'true') {
      panel = e.target.nextElementSibling
    } else {
      panel = e.target.previousElementSibling
    }

    if (this.onlyMobile === 'true') {
      panel.classList.toggle('panel--onlyMobile')

      this.btnText = panel.classList.contains('panel--onlyMobile') ? this.closedBtnText : this.openedBtnText

      if (e.target.classList.contains('largeFont')) {
        e.target.classList.toggle('largeFont--arrowUp')
      } else {
        e.target.classList.toggle('btn__toggleIcon--small-arrowUp')
      }
    } else {
      panel.classList.toggle('hide')
      this.btnText = panel.classList.contains('hide') ? this.closedBtnText : this.openedBtnText
      if (e.target.classList.contains('largeFont')) {
        e.target.classList.toggle('largeFont--arrowUp')
      } else {
        e.target.classList.toggle('btn__toggleIcon--small-arrowUp')
      }
    }
  }
}
