import { Component, OnInit } from '@angular/core'

import CountryComponent from '../../core/country/country.component'
import LocalStorageService from '../../core/local-storage.service'
import LanguageOption from '../../core/models/language-option'
import TranslateService from '../../core/translate.service'
import ModalService from '../../shared/modal/modal.service'

@Component({
  selector: 'app-my-booking-read-more',
  templateUrl: './my-booking-read-more.component.html',
  styleUrls: ['./my-booking-read-more.component.scss'],
})
export default class MyBookingReadMoreComponent extends CountryComponent implements OnInit {
  languageOptions: LanguageOption

  constructor(
    private modalService: ModalService,
    private translate: TranslateService,
    localStorageService: LocalStorageService
  ) {
    super(localStorageService)
  }

  ngOnInit(): void {
    this.languageOptions = this.getLangOptions()
  }

  /**
   * Closes modal by given id
   *
   * @param id modal id
   */
  closeModal(id: string): void {
    this.modalService.close(id)
  }

  /**
   * Get selected language or default
   */
  getSelectedLang(): string {
    return this.translate.currentLocaleOrDefault
  }
}
