<div class="wrapper">
  <a
    *ngIf="largeFont === 'true'"
    href="javascript:void(0)"
    [ngClass]="setBtnClasses()"
    (click)="toggle($event)"
    id="{{ gtmSelectorId }}"
    class="btn__toggleIcon--small largeFont"
  >
    {{ btnText | translate }}
  </a>
  <div [ngClass]="onlyMobile === 'true' ? 'panel--onlyMobile' : 'hide'" class="panel">
    <ng-content></ng-content>
  </div>
  <a
    *ngIf="largeFont !== 'true'"
    href="javascript:void(0)"
    [ngClass]="setBtnClasses()"
    (click)="toggle($event)"
    id="{{ gtmSelectorId }}"
    class="btn__toggleIcon--small"
  >
    {{ btnText | translate }}
  </a>
</div>
