import { Component } from '@angular/core'

import ModalService from '../modal.service'

@Component({
  selector: 'app-credit-card-error',
  templateUrl: './credit-card-error.component.html',
  styleUrls: ['./credit-card-error.component.scss'],
})
export default class CreditCardErrorModalComponent {
  constructor(private modalService: ModalService) {}

  /**
   * Closes modal by given id
   *
   * @param id modal id
   */
  closeModal(id: string): void {
    this.modalService.close(id)
  }
}
