import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatMomentDateModule } from '@angular/material-moment-adapter'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'

import CoreModule from '../core/core.module'
import SharedModule from '../shared/shared.module'
import ChangeCountryService from './country-change-service'
import CountryPickerComponent from './country-picker/country-picker.component'
import DatePickerComponent from './date-picker/date-picker.component'
import DriverDetailsAutofillNoComponent from './driver-details-autofill-no/driver-details-autofill-no.component'
import DriverDetailsAutofillSeComponent from './driver-details-autofill-se/driver-details-autofill-se.component'
import DriverDetailsBisnodeHelpComponent from './driver-details-bisnode-help/driver-details-bisnode-help.component'
import DriverDetailsDrivingLicenseHelpComponent from './driver-details-driving-license-help/driver-details-driving-license-help.component'
import DriverDetailsRoutingModule from './driver-details-routing.module'
import DriverDetailsComponent from './driver-details.component'
import ChangeDueDateErrorService from './due-date-error-change-service'
import IssuingCountryPickerComponent from './issuing-country-picker/issuing-country-picker.component'
import TelephoneCountryCodePickerComponent from './telephone-country-code-picker/telephone-country-code-picker.component'

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    DriverDetailsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatMomentDateModule,
  ],
  declarations: [
    TelephoneCountryCodePickerComponent,
    DriverDetailsComponent,
    DriverDetailsDrivingLicenseHelpComponent,
    DriverDetailsAutofillSeComponent,
    DriverDetailsAutofillNoComponent,
    CountryPickerComponent,
    DatePickerComponent,
    IssuingCountryPickerComponent,
    DriverDetailsBisnodeHelpComponent,
  ],
  exports: [DriverDetailsComponent],
  providers: [ChangeCountryService, ChangeDueDateErrorService],
})
export default class DriverDetailsModule {}
