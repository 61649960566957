<div id="additional-services-step">
  <app-stepper></app-stepper>

  <div class="block blockPadding">
    <h1 class="page__heading">{{ 'additional-services' | translate }}</h1>
    <p class="page__subHeading">
      {{
        liability && (country == 0 || country == 1)
          ? ('additional-services-subtitle-liability' | translate)
          : specialLiability && country == 0
          ? ('additional-services-subtitle-specialLiability' | translate) + insurances?.cdw.withoutWaivable + '.'
          : ('additional-services-subtitle' | translate)
      }}
    </p>
  </div>

  <div class="separator-line"></div>

  <app-additional-services-insurances
    [insurances]="insurances"
    [liability]="liability"
    (insurancesCost)="insuranceCostChanged()"
  ></app-additional-services-insurances>

  <div class="finalCostStickyWrapper">
    <div class="finalCost">
      <div class="finalCostHeadlineWrapper">
        <p>{{ 'total-estimated-extra-charges' | translate }}</p>
        <p>
          <strong id="additional-insurances-total-cost">{{ sum }} {{ currency }}</strong>
        </p>
      </div>
      <button class="btn__primary btn__md" (click)="nextPage()">{{ 'continue' | translate }}</button>
      <p id="additional-insurances-extra-costs" class="finalCost__body">
        {{ 'extra-charges-at-pick-up' | translate }}
      </p>
    </div>
  </div>
</div>
