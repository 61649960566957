import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-confirmation-car',
  templateUrl: './confirmation-car.component.html',
  styleUrls: ['./confirmation-car.component.scss'],
})
export default class ConfirmationCarComponent {
  @Input() car: any
}
