import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { PRIVACY_POLICY_LINK_CLASSNAME, INFO_BUTTON_OR_LINK_CLASSNAME } from '../../constants/gtmSelectors'
import BookingService from '../core/booking.service'
import CountryComponent from '../core/country/country.component'
import LocalStorageService from '../core/local-storage.service'
import Booking from '../core/models/booking'
import LanguageOption from '../core/models/language-option'
import TranslateService from '../core/translate.service'
import ModalService from '../shared/modal/modal.service'

@Component({
  selector: 'app-my-booking',
  templateUrl: './my-booking.component.html',
  styleUrls: ['./my-booking.component.scss'],
})
export default class MyBookingComponent extends CountryComponent implements OnInit {
  booking: Booking

  // Will hold previously focused element
  focusedElementBeforeModal

  body: HTMLElement

  mobileOverlay: HTMLElement

  readMoreOverlay: HTMLElement

  readMoreModal: HTMLElement

  firstTabStop: HTMLScriptElement

  lastTabStop: HTMLScriptElement

  languageOptions: LanguageOption

  privacyPolicyLinkClassName = PRIVACY_POLICY_LINK_CLASSNAME
  infoButtonOrLinkClassName = INFO_BUTTON_OR_LINK_CLASSNAME

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    service: BookingService,
    private modalService: ModalService,
    private localStorageService: LocalStorageService
  ) {
    super(localStorageService)
  }

  ngOnInit(): void {
    this.localStorageService.removePaymentState()

    this.route.data.subscribe((data: { booking: Booking }) => {
      this.booking = data.booking
    })

    this.languageOptions = this.getLangOptions()
  }

  /**
   * Opens modal by given id
   *
   * @param id string the modal id
   */
  openModal(id: string): void {
    this.modalService.open(id)
  }

  /**
   * Closes modal by given id
   *
   * @param id string the modal id
   */
  closeModal(id: string): void {
    this.modalService.close(id)
  }

  /**
   * Navigate to next page
   */
  nextPage(): void {
    if (this.localStorageService.getSkipInsurances()) {
      this.localStorageService.setStepValid('/driver-details')
      this.router.navigate(['/driver-details'])
    } else {
      this.localStorageService.setStepValid('/additional-services')
      this.router.navigate(['/additional-services'])
    }
  }

  /**
   * Get selected language or default
   */
  getSelectedLang(): string {
    return this.translate.currentLocaleOrDefault
  }
}
