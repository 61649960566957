import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export default class CreditCardService {
  /**
   * Returns string to corresponding image assets
   * based on the paymentMethod
   *
   * @returns string
   */
  getCreditCardAsset(paymentMethod: string): string {
    // Fallback image
    let imgAsset = '/assets/icons/credit-card.svg'

    if (paymentMethod) {
      switch (paymentMethod.toUpperCase()) {
        case 'AMEX':
          imgAsset = '/assets/icons/amex.svg'
          break
        case 'DANKORT':
          imgAsset = '/assets/icons/dankort.svg'
          break
        case 'DINERS':
          imgAsset = '/assets/icons/diners.svg'
          break
        case 'MASTERCARD':
          imgAsset = '/assets/icons/mastercard.svg'
          break
        case 'VISA':
          imgAsset = '/assets/icons/visa.svg'
          break
        default:
          break
      }
    }

    return imgAsset
  }
}
