<app-shared-additional-information [additionalInformation]="booking?.additionalInformation">
</app-shared-additional-information>
<app-my-booking-select-car [car]="booking?.reservation.car"></app-my-booking-select-car>

<div class="card block--nomargin">
  <app-shared-location
    [returnOrPickup]="'pickup'"
    [date]="booking?.reservation?.pickupDate"
    [place]="booking?.pickupPlace"
    [station]="booking?.pickupStation"
    [title]="'my-booking-pickup-location'"
    [operatedBy]="booking?.pickupPlace?.operatedByStation"
    [id]="'my-booking-more-information-pickup'"
    [gtmSelectorStep]="'my-booking'"
  >
  </app-shared-location>
  <div class="card__divider">
    <div></div>
  </div>
  <app-shared-location
    [returnOrPickup]="'return'"
    [date]="booking?.reservation?.returnDate"
    [place]="booking?.returnStation"
    [station]="booking?.returnStation"
    [title]="'my-booking-return-location'"
    [operatedBy]="booking?.returnStation?.operatedByStation"
    [id]="'my-booking-more-information-return'"
    [gtmSelectorStep]="'my-booking'"
  >
  </app-shared-location>
</div>

<div class="block--nomargin">
  <div class="grid-row">
    <div class="grid-item">
      <h4 class="heading-big">{{ 'my-booking-online-check-in-title' | translate }}</h4>
      <p class="paragraph">{{ 'my-booking-online-check-in-text' | translate }}</p>
      <button
        id="my-booking-about-online-checkin-button"
        class="btn__link {{ infoButtonOrLinkClassName }}"
        (click)="openModal('read-more-modal')"
      >
        {{ 'read-more' | translate }}
      </button>
      <app-modal id="read-more-modal">
        <app-my-booking-read-more></app-my-booking-read-more>
      </app-modal>
    </div>
    <div class="grid-item">
      <ng-container *ngIf="!finland()">
        <h4 class="heading">
          {{ 'my-booking-online-complete-check-in-title' | translate }}
        </h4>
      </ng-container>

      <ng-container *ngIf="finland() && getSelectedLang() !== languageOptions.nativeLanguage">
        <h4 class="heading">
          {{ 'my-booking-online-complete-check-in-title' | translate }}
        </h4>
      </ng-container>

      <button class="btn__primary btn__md marginVertical" (click)="nextPage()">{{ 'continue' | translate }}</button>
      <a
        *ngIf="finland()"
        href="//www.hertz.fi/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp"
        target="_blank"
        id="my-booking-privacy-policy-link"
        class="btn__link {{ privacyPolicyLinkClassName }}"
      >
        {{ 'privacy-policy-read-more' | translate }}
      </a>
      <a
        *ngIf="sweden()"
        href="//www.hertz.se/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp"
        target="_blank"
        id="my-booking-privacy-policy-link"
        class="btn__link {{ privacyPolicyLinkClassName }}"
      >
        {{ 'privacy-policy-read-more' | translate }}
      </a>
      <a
        *ngIf="norway()"
        href="//www.hertz.com/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp"
        target="_blank"
        id="my-booking-privacy-policy-link"
        class="btn__link {{ privacyPolicyLinkClassName }}"
      >
        {{ 'privacy-policy-read-more' | translate }}
      </a>
      <a
        *ngIf="denmark()"
        href="//www.hertzdk.dk/rentacar/navigation/templates/legalView.jsp"
        target="_blank"
        id="my-booking-privacy-policy-link"
        class="btn__link {{ privacyPolicyLinkClassName }}"
      >
        {{ 'privacy-policy-read-more' | translate }}
      </a>
    </div>
  </div>
</div>
