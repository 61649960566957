import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import CountryComponent from '../../core/country/country.component'
import GoogleTagManagerService from '../../core/google-tag-manager.service'
import LocalStorageService from '../../core/local-storage.service'
import Insurance from '../../core/models/insurance'
import InsuranceType from '../../core/models/insurance-type'
import LanguageOption from '../../core/models/language-option'
import TranslateService from '../../core/translate.service'

@Component({
  selector: 'app-additional-services-insurances',
  templateUrl: './additional-services-insurances.component.html',
  styleUrls: ['./additional-services-insurances.component.scss'],
})
export default class AdditionalServicesInsurancesComponent extends CountryComponent implements AfterViewInit, OnInit {
  @Input() insurances: Insurance

  @Input() liability: boolean

  @Input() specialLiability: boolean

  @Output() readonly insurancesCost = new EventEmitter<number>()
  languageOptions: LanguageOption

  constructor(
    private translate: TranslateService,
    localStorageService: LocalStorageService,
    private googleTagManagerService: GoogleTagManagerService
  ) {
    super(localStorageService)
    this.specialLiability = localStorageService.getSpecialLiability()
  }

  ngOnInit(): void {
    this.languageOptions = this.getLangOptions()
  }

  ngAfterViewInit(): void {
    this.setAndStyleCheckboxes()
  }

  setAndStyleCheckboxes = () => {
    if (this.insurances) {
      Object.keys(this.insurances).forEach((key) => {
        const checkboxElement = document.getElementById(`${key}-checkbox`) as HTMLInputElement
        if (!checkboxElement) {
          return
        }
        if (this.isIncluded(this.insurances[key])) {
          checkboxElement.classList.add('inputCheckbox--included')
          checkboxElement.disabled = true
        } else if (this.isForceSelected(key)) {
          checkboxElement.classList.add('inputCheckbox--included')
        } else if (this.insurances[key].selected) {
          checkboxElement.checked = true
        }
      })
    }
  }

  /**
   * Checks boolean restricted and selected to find included insurances
   *
   * @returns boolean
   */
  isIncluded = (insuranceType: InsuranceType): boolean =>
    !!insuranceType && insuranceType.selected && insuranceType.restriction

  isLiability = (): boolean => this.liability

  isSpecialLiability = (): boolean => this.specialLiability

  isForceSelected(key: string): boolean {
    if (!!this.insurances.scw && this.insurances.scw.selected) {
      if (!!this.insurances[key] && (key === 'tp' || key === 'cdw')) {
        return true
      }
    }
    return false
  }

  handleConnectedInsuranceSelection(key: string): void {
    if (!!this.insurances[key] && !this.isIncluded(this.insurances[key])) {
      const checkbox = document.getElementById(`${key}-checkbox`) as HTMLInputElement

      if (this.insurances.scw.selected) {
        this.insurances[key].selected = true
        if (checkbox) {
          checkbox.classList.add('inputCheckbox--included')
          checkbox.disabled = true
          checkbox.checked = false
        }
      } else {
        this.insurances[key].selected = false
        if (checkbox) {
          checkbox.classList.remove('inputCheckbox--included')
          checkbox.disabled = false
        }
      }
    }
  }

  /**
   * Toggle value and notify parent component that selected insurances has changed
   */
  selectedInsuranceChanged(key: string): void {
    if (!!this.insurances && !!this.insurances[key]) {
      if (!this.isIncluded(this.insurances[key])) {
        this.insurances[key].selected = !this.insurances[key].selected
      }

      if (key === 'scw') {
        this.handleConnectedInsuranceSelection('tp')
        this.handleConnectedInsuranceSelection('cdw')
      }

      this.googleTagManagerService.triggerGtmEvent({
        event: 'insurance_clicked',
        step_on_event: 'additional_services',
        action: this.insurances[key].selected ? `${key} selected` : `${key} removed`,
        value: this.insurances[key].dailyRate?.totalWithTax ?? undefined,
      })

      this.insurancesCost.emit()
    }
  }

  getTranslationArray(key: string): Array<string> {
    return this.translate.getTranslationArray(key)
  }

  /**
   * Get selected language or default
   */
  getSelectedLang(): string {
    return this.translate.currentLocaleOrDefault
  }

  /**
   * Get currency of insurances
   */
  insuranceInTextCurrency(): string {
    if (this.finland() && this.getSelectedLang() === this.languageOptions.nativeLanguage) {
      return ''
    }
    try {
      return (
        this.insurances.cdw.dailyRate.currency ||
        this.insurances.tp.dailyRate.currency ||
        this.insurances.pai.dailyRate.currency ||
        this.insurances.scw.dailyRate.currency
      )
    } catch {
      return ''
    }
  }

  getNonLiabilityPriceWithCurrency(insuranceName: string): string {
    if (this.isLiability()) {
      return ''
    }
    const insurance = this.insurances?.[insuranceName]
    return `${insurance?.withoutWaivable} ${this.insuranceInTextCurrency()}`
  }
}
