import { isPlatformBrowser } from '@angular/common'
import { APP_ID, Component, Inject, PLATFORM_ID } from '@angular/core'
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router'

import LocalStorageService from './core/local-storage.service'
import ThemeService from './core/themes.service'
import TranslateService from './core/translate.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export default class AppComponent {
  logoSrc = ''

  logoAlt = ''

  loading = true

  constructor(
    private router: Router,
    private translate: TranslateService,
    private themeService: ThemeService,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    @Inject(APP_ID) private appId: string
  ) {
    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent)
    })
    if (this.localStorageService.getThemeName()) {
      this.themeService.setTheme(this.localStorageService.getThemeName())
    }
  }

  onActivate(_event: any): void {
    if (isPlatformBrowser(this.platformId)) {
      const scrollToTop = window.setInterval(() => {
        const pos = window.scrollY
        if (pos > 0) {
          window.scrollTo(0, pos - 50) // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop)
        }
      }, 16)
    }
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.logoSrc = this.themeService.getThemeLogo()
      this.logoAlt = this.themeService.getThemeName()
      this.loading = false
    }
  }
}
