import { Component, OnInit, Renderer2 } from '@angular/core'
import { Router } from '@angular/router'
import ScriptService from '../../shared/script.service'
import BookingService from '../../core/booking.service'
import LocalStorageService from '../../core/local-storage.service'
import CreditCardRegistrationService from '../credit-card-registration.services'

declare var payex: any

@Component({
  selector: 'app-credit-card-needed',
  templateUrl: './credit-card-needed.component.html',
  styleUrls: ['./credit-card-needed.component.scss'],
})
export default class CreditCardNeededComponent implements OnInit {
  constructor(
    private router: Router,
    private bookingService: BookingService,
    private renderer: Renderer2,
    private scriptService: ScriptService,
    private localStorageService: LocalStorageService,
    private creditCardRegistrationService: CreditCardRegistrationService
  ) {}

  ngOnInit(): void {
    const paymentState = this.localStorageService.getPaymentState()
    if (paymentState) {
      const scriptElement = this.scriptService.loadJsScript(this.renderer, paymentState.viewPaymentUrl)
      scriptElement.onload = () => {
        this.loadSeamlessView(paymentState.orderRef)
      }
    } else {
      this.bookingService.getViewPaymentUrl().subscribe((viewPaymentInfo) => {
        const scriptElement = this.scriptService.loadJsScript(this.renderer, viewPaymentInfo?.viewPaymentUrl)

        this.localStorageService.setPaymentState(viewPaymentInfo)

        scriptElement.onload = () => {
          this.loadSeamlessView(viewPaymentInfo?.orderRef)
        }
      })
    }
  }

  loadSeamlessView(orderRef: string): void {
    const self = this
    const config = {
      container: 'swedbank-pay-seamless-view-page',
      onPaymentCompleted: function () {
        self.confirmCheckIn(orderRef)
      },
    }
    payex.hostedView.creditCard(config).open()
  }

  /**
   * PUT booking information to backend and navigate to confirmation page on click
   */
  confirmCheckIn(orderRef: string): void {
    const callback = () => {
      this.localStorageService.removePaymentState()
      this.creditCardRegistrationService.setShowCreditCardNeeded(false)
      this.localStorageService.setStepValid('/confirmation')
      this.router.navigate(['/confirmation'])
    }

    this.localStorageService.saveOrderRef(orderRef)
    this.bookingService.putBooking(callback.bind(this))
  }
}
