<div class="blockPadding">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-sm-12">
        <div class="col--center-text">
          <h1 class="page__heading">{{ 'help' | translate }}</h1>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="horizontalPadding">
  <div class="block__body">
    <div class="col-md-6 offset-md-3 col-sm-12">
      <p class="section__body section__body--bold">{{ 'help-page-credit-card-needed-title' | translate }}</p>
      <p class="section__body">{{ 'help-page-credit-card-needed-info' | translate }}</p>

      <p class="section__body section__body--bold">{{ 'help-page-credit-card-amount-title' | translate }}</p>
      <p class="section__body">{{ 'help-page-credit-card-amount-info' | translate }}</p>

      <p class="section__body section__body--bold">{{ 'help-page-cancel-change-title' | translate }}</p>
      <p class="section__body">{{ 'help-page-cancel-change-info' | translate }}</p>

      <p class="section__body section__body--bold">{{ 'help-page-unreported-damages-title' | translate }}</p>
      <p class="section__body">{{ 'help-page-unreported-damages-info' | translate }}</p>

      <p class="section__body section__body--bold">{{ 'help-page-special-needs-title' | translate }}</p>
      <p class="section__body">{{ 'help-page-special-needs-info' | translate }}</p>

      <p class="section__body section__body--bold">{{ 'help-page-parking-title' | translate }}</p>
      <p class="section__body">{{ 'help-page-parking-info' | translate }}</p>

      <p class="section__body section__body--bold">{{ 'help-page-refuel-title' | translate }}</p>
      <p class="section__body">{{ 'help-page-refuel-info' | translate }}</p>
    </div>
  </div>
</div>

<div>
  <div class="col-md-6 offset-md-3 col-sm-12">
    <div class="col--center-text">
      <button class="btn__link" (click)="goBack()">
        {{ 'go-back' | translate }}
      </button>
    </div>
  </div>
</div>
