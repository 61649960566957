<app-accordion
  largeFont="true"
  onlyMobile="true"
  closedBtnText="auto-fill-button-text"
  openedBtnText="auto-fill-button-text"
>
  <div class="form-wrapper">
    <form #afForm="ngForm" *ngIf="autoFillForm" [formGroup]="autoFillForm" (ngSubmit)="getAutoFill()">
      <p class="quickFill">{{ 'driver-details-ac-title' | translate }}</p>

      <div class="hideInMobile">
        <p
          *ngIf="sweden() && getSelectedLang() === languageOptions.nativeLanguage"
          class="paragraph paragraph--alignCenter acBody"
        >
          {{ 'driver-details-ac-info-1' | translate }}<br />
          {{ 'driver-details-ac-info-2' | translate }}
        </p>
      </div>

      <div class="marginBottom">
        <label
          class="pure-material-textfield-outlined"
          [ngClass]="{ 'pure-material-textfield-outlined-error': pin.invalid && pin.touched }"
        >
          <input placeholder=" " formControlName="pin" />
          <span
            >{{ 'personal-identity-number' | translate }} ({{
              'personal-identity-number-placeholder' | translate
            }})</span
          >
        </label>
        <p class="form__error leftPadding" *ngIf="pin.invalid && pin.touched">
          {{ 'invalid-personal-identity-number' | translate }}
        </p>
      </div>

      <div>
        <label
          class="pure-material-textfield-outlined"
          [ngClass]="{ 'pure-material-textfield-outlined-error': zip.invalid && zip.touched }"
        >
          <input placeholder=" " formControlName="zip" type="number" inputmode="numeric" />
          <span>{{ 'zip-code' | translate }} (XXXXX)</span>
        </label>
        <p class="form__error leftPadding" *ngIf="zip.invalid && zip.touched">
          {{ 'invalid-zip-code' | translate }}
        </p>
      </div>

      <p class="form__error leftPadding" *ngIf="message">
        {{ message | translate }}
      </p>

      <div class="form__row">
        <button [disabled]="!afForm.form.valid" type="submit" class="btn__primary--outlined btn__short">
          {{ 'search' | translate }}
        </button>
      </div>
    </form>
  </div>
</app-accordion>
<app-modal id="autofill-error-modal" errorModal="true">
  <app-autofill-error></app-autofill-error>
</app-modal>
