import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

/**
 * For changing the due date error state when driver license due date or birth date is changed
 */
@Injectable({ providedIn: 'root' })
export default class ChangeDueDateErrorService {
  newDueDateError = new Subject<{ hasError: boolean }>()
}
