import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import LocalStorageService from '../local-storage.service'

@Injectable({
  providedIn: 'root',
})
export default class StepperService {
  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}

  getStepMap() {
    const steps = {
      0: 'additional-services',
      1: 'driver-details',
      2: 'credit-card',
      3: 'confirmation',
    }
    // Remaps the urls.
    // If skipPayment is set, remove credit-card step
    // If skipeInsurances is set, remove additional-services
    const urlMap = {
      ...Object.values(steps).filter(
        (e) =>
          !(
            (this.localStorageService.getSkipPayment() && e === 'credit-card') ||
            (this.localStorageService.getSkipInsurances() && e === 'additional-services')
          )
      ),
    }

    return urlMap
  }
}
