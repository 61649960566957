<div class="carWrapper">
  <div class="carImage">
    <img
      src="{{ car?.carGroupImageUrl ? car?.carGroupImageUrl : '../../../assets/img/volvo-xc40.jpg' }}"
      alt="{{ car?.carGroupDescription }}"
    />
  </div>
  <div class="carTextWrapper">
    <div class="carTextWrapper__inner">
      <p class="car__model">
        {{ car?.carGroupDescription }}
        <span class="car__orSimilar" *ngIf="!car?.carGroupIsGuaranteedModel">
          {{ 'my-booking-change-car-or-similar' | translate }}
        </span>
      </p>
    </div>
    <p class="car__class">
      {{ car?.carGroupAcrissDescription }}
    </p>
  </div>
</div>
