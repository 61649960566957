import { Injectable } from '@angular/core'

@Injectable()
export default class DialogService {
  private dialogs: Array<any> = []

  /**
   * Add dialog to array of active dialogs
   *
   * @param dialog the dialog to add
   */
  add(dialog: any): void {
    this.dialogs.push(dialog)
  }

  /**
   * Remove dialog from array of active dialogs
   *
   * @param id dialog id
   */
  remove(id: string): void {
    this.dialogs = this.dialogs.filter((x) => x.id !== id)
  }

  /**
   * Open dialog specified by id
   *
   * @param id the id of the dialog to open
   */
  open(id: string): void {
    const dialogElement: any = this.dialogs.filter((x) => x.id === id)[0]
    dialogElement.open()
  }

  /**
   * Close dialog specified by id
   *
   * @param id dialog id
   */
  close(id: string): void {
    const dialog: any = this.dialogs.filter((x) => x.id === id)[0]
    dialog.close()
  }
}
