<div class="block block--gray">
  <div class="col--center-text">
    <h2 class="section__heading">{{ 'credit-card-registered' | translate }}</h2>
    <p class="section__body">{{ 'credit-card-registered-info' | translate }}</p>
    <div class="credit-card">
      <img alt="credit card icon" [src]="getCreditCardAsset()" class="credit-card-icon" />
      <p class="section__body">
        {{ creditCardNumber }}
      </p>
    </div>
    <button id="credit-card-change-card-button" class="btn__link" (click)="changeCard()">
      {{ 'credit-card-change-card' | translate }}
    </button>
    <p *ngIf="!finland()" class="section__body section__body--small">
      {{ 'credit-card-disclaimer' | translate }}
    </p>
    <p
      *ngIf="finland() && getSelectedLang() !== languageOptions.nativeLanguage"
      class="section__body section__body--small"
    >
      {{ 'credit-card-disclaimer' | translate }}
    </p>
  </div>
</div>

<div class="block">
  <div class="container-fluid">
    <div class="row">
      <div class="col--center-text">
        <button class="btn__primary btn__md" (click)="nextPage()">{{ 'continue' | translate }}</button>
      </div>
    </div>
  </div>
</div>
