import { Component, OnInit } from '@angular/core'
import { DateAdapter } from '@angular/material/core'

import CountryComponent from '../country/country.component'
import LocalStorageService from '../local-storage.service'
import LanguageOption from '../models/language-option'
import TranslateService from '../translate.service'

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
})
export default class ChangeLanguageComponent extends CountryComponent implements OnInit {
  languageOptions: LanguageOption

  constructor(
    private translate: TranslateService,
    localStorageService: LocalStorageService,
    private adapter: DateAdapter<any>
  ) {
    super(localStorageService)
  }

  ngOnInit(): void {
    this.languageOptions = this.getLangOptions()
  }

  /**
   * Change language on flag-click
   *
   * @param language the language to change to
   */
  changeLanguage(language: string): void {
    this.translate.useLanguage(language)

    /**
     * Dynamicly set datepicker locale
     */
    this.adapter.setLocale(language)
  }

  /**
   * Get selected language or default
   */
  getSelectedLang(): string {
    return this.translate.currentLocaleOrDefault
  }
}
