import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'

import CoreModule from '../core/core.module'
import SharedModule from '../shared/shared.module'
import AdditionalServicesInsurancesComponent from './additional-services-insurances/additional-services-insurances.component'
import AdditionalServicesRoutingModule from './additional-services-routing.module'
import AdditionalServicesComponent from './additional-services.component'

@NgModule({
  imports: [CommonModule, AdditionalServicesRoutingModule, CoreModule, SharedModule, FormsModule],
  declarations: [AdditionalServicesComponent, AdditionalServicesInsurancesComponent],
  exports: [AdditionalServicesComponent],
})
export default class AdditionalServicesModule {}
