import { Component, Input, OnInit } from '@angular/core'

import LocalStorageService from 'src/app/core/local-storage.service'
import environment from 'src/environments/environment'

import Country from '../../core/models/country'

@Component({
  selector: 'app-shared-additional-information',
  templateUrl: './shared-additional-information.component.html',
  styleUrls: ['./shared-additional-information.component.scss'],
})
export default class SharedAdditionalInformationComponent implements OnInit {
  @Input() additionalInformation: boolean

  country: Country

  showInfoBox: boolean

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.country = this.localStorageService.getCurrentCountry()
    this.showInfoBox = true
  }

  getReadMoreUrl() {
    switch (this.country) {
      case Country.Sweden:
        return environment.additionalInformationSe
      case Country.Norway:
        return environment.additionalInformationNo
      case Country.Finland:
        return environment.additionalInformationFi
      case Country.Denmark:
        return environment.additionalInformationDk
      default:
        return ''
    }
  }

  dismissInfoBox() {
    this.showInfoBox = false
  }
}
