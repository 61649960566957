import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, Subject } from 'rxjs'
import { tap } from 'rxjs/operators'

import environment from '../../environments/environment'
import CountryComponent from './country/country.component'
import GoogleTagManagerService from './google-tag-manager.service'
import LocalStorageService from './local-storage.service'
import Booking from './models/booking'
import CreditCard from './models/credit-card'
import Driver from './models/driver'
import Insurance from './models/insurance'
import PayexViewPaymentInfo from './models/payex-view-payment-info'
import StepperService from './stepper/stepper.service'
import ThemeService from './themes.service'
import TranslateService from './translate.service'

@Injectable({
  providedIn: 'root',
})
export default class BookingService extends CountryComponent {
  autoFillSubject = new Subject()

  private autofillTries = 0

  private bookingUrl = `${environment.baseUrl}${environment.booking}`
  private paymentCompleteUrl = `${environment.baseUrl}${environment.paymentComplete}`
  private paymentInitiateUrl = `${environment.baseUrl}${environment.paymentInitiate}`
  private quickFillNorwayUrl = `${environment.baseUrl}${environment.quickFillNorwayBaseUrl}`
  private quickFillSwedenUrl = `${environment.baseUrl}${environment.quickFillSwedenBaseUrl}`

  constructor(
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private stepperService: StepperService,
    private themeService: ThemeService,
    private googleTagManagerService: GoogleTagManagerService
  ) {
    super(localStorageService)
  }

  /**
   * Get reservation from back end and saves to local storage.
   *
   * @param token auth token for reservation
   */
  getBooking(token: string): Observable<Booking> {
    const httpOptions = {
      headers: this.getTokenHeaders(token),
    }

    // ***** DO NOT REMOVE *****
    // CODE FOR USING LOCAL FAKE RESERVATION DATA
    // const fakesPath = 'assets/data/booking.json';
    // return this.http.get<Booking>(fakesPath)
    // ***** DO NOT REMOVE *****

    return this.http.get<Booking>(this.bookingUrl, httpOptions).pipe(
      tap((data) => {
        // check if the token has changed , then reset the steps
        const tokeninStorage = this.localStorageService.getToken()
        if (tokeninStorage !== token) {
          Object.values(this.stepperService.getStepMap()).forEach((val: string) =>
            this.localStorageService.resetStep(`/${val}`)
          )
        }
        this.themeService.setTheme(data.reservation.brand)
        this.localStorageService.saveBooking(data)
        this.localStorageService.saveAppCountry(data.pickupPlace.country)
        this.localStorageService.saveToken(token)
        this.localStorageService.saveSkipPayment(data.reservation.paymentDetails.skipPayment)
        this.translate.setAppLanguage()
        this.translate.setAppTimeZone()

        // If no data is missing from fields the checkin is complete so navigate to confirmation-page
        if (data.reservation.onlineCheckinDone) {
          Object.values(this.stepperService.getStepMap()).forEach((val: string) =>
            this.localStorageService.setStepValid(`/${val}`)
          )
          this.router.navigate(['/confirmation'])
        }
      })
    )
  }
  /**
   * PUT reservation to backend
   */
  putBooking(callback): void {
    const localStorageBooking = this.localStorageService.getBooking()
    const token = this.localStorageService.getToken()
    const httpOptions = {
      headers: this.getTokenHeaders(token),
    }
    this.http.put<Booking>(this.bookingUrl, localStorageBooking, httpOptions).subscribe({
      next: () => {
        if (callback) {
          callback()
          this.handleGtmEvent('check_in_completed', localStorageBooking)
        }
      },
      error: () => {
        // TODO: check if response is 200, else handle error
        this.handleGtmEvent('check_in_failed')
      },
    })
  }

  /**
   * Get customer info based on entered socialSecurityNumber and zipCode in auto fill input
   * Test details for bisnode: zipcode: 22654, socialSecurityNumber: 190101010106
   *
   * @param zipcode string the zipcode entered in auto fill
   * @param socialSecurityNumber string the socialSecurityNumber entered in auto fill
   */
  getCustomerForAutoCompleteSweden(socialSecurityNumber: string, zipcode: string): Observable<Driver> {
    const token = this.localStorageService.getToken()

    const httpOptions = {
      headers: this.getTokenHeaders(token),
    }

    return this.http
      .get<Driver>(
        `${this.quickFillSwedenUrl}?zipcode=${zipcode}&socialSecurityNumber=${socialSecurityNumber}`,
        httpOptions
      )
      .pipe(
        tap({
          next: (data) => {
            // Bugfix, CJ-195
            // Need to fetch current driver and replace only fields with valid data;
            const driver = this.localStorageService.getDriver()
            Object.keys(driver).forEach((key) => {
              if (data[key]) {
                driver[key] = data[key]
              }
            })
            this.localStorageService.saveDriver(driver)
          },
          // eslint-disable-next-line no-empty,@typescript-eslint/no-empty-function
          error: (_error) => {},
          complete: () => {
            this.autofillTries += 1
            // Show error modal too many tries on 3 tries or more, send too many tries error to client, save timeout timestamp
            if (this.autofillTries >= 3) {
              this.localStorageService.saveQuickFillTimeStamp()
              this.autofillTries = 0
            }
          },
        })
      )
  }

  /**
   * Get customer info based on entered phone number in the auto fill input
   *
   * @param phone string the phone number entered in auto fill
   */
  getCustomerForAutoCompleteNorway(phone: string): Observable<Driver> {
    const token = this.localStorageService.getToken()
    const httpOptions = {
      headers: this.getTokenHeaders(token),
    }

    return this.http.get<Driver>(`${this.quickFillNorwayUrl}?phone=${phone}`, httpOptions).pipe(
      tap({
        complete: () => {
          this.autofillTries += 1
          // Show error modal too many tries on 3 tries or more, send too many tries error to client, save timeout timestamp
          if (this.autofillTries >= 3) {
            this.localStorageService.saveQuickFillTimeStamp()
            this.autofillTries = 0
          }
        },
      })
    )
  }

  /**
   * Gets the value from autofill if any, otherwise from previously stored value in app
   *
   * @param autoFillValue The value from autofill
   * @param storedValue The value stored in the app
   */
  getValueFromAutoFillOrDefault(autoFillValue: any, storedValue: any): any {
    return autoFillValue || storedValue
  }

  getMaskedCreditCardNumber(orderRef: string): Observable<CreditCard> {
    const countryParam = this.localStorageService.getCurrentCountryAsString().toUpperCase()

    const httpOptions = {
      headers: this.getTokenHeaders(this.localStorageService.getToken()),
    }

    return this.http.get<CreditCard>(`${this.paymentCompleteUrl}/${countryParam}/${orderRef}`, httpOptions).pipe(
      tap((data) => {
        this.localStorageService.saveCreditCard(data)
      })
    )
  }

  getViewPaymentUrl(): Observable<PayexViewPaymentInfo> {
    const countryParam = this.localStorageService.getCurrentCountryAsString()?.toUpperCase()

    let language
    const locale = this.localStorageService.getLocale()
    if (locale?.substring(0, 2) !== 'en') {
      language = locale?.substring(3, 5).toUpperCase()
    } else {
      language = locale?.substring(0, 2).toUpperCase()
    }

    const httpOptions = {
      headers: this.getTokenHeaders(this.localStorageService.getToken()),
    }

    return this.http
      .get<PayexViewPaymentInfo>(`${this.paymentInitiateUrl}/${countryParam}?language=${language}`, httpOptions)
      .pipe(tap((_data) => {}))
  }

  getTotalCostForInsurances(insurance: Insurance): number {
    let sum = 0

    if (insurance?.cdw?.selected && !insurance?.cdw?.restriction) {
      sum += insurance.cdw.dailyRate.totalWithTax
    }

    if (insurance?.scw?.selected && !insurance?.scw?.restriction) {
      sum += insurance.scw.dailyRate.totalWithTax
    }

    if (insurance?.tp?.selected && !insurance?.tp?.restriction) {
      sum += insurance.tp.dailyRate.totalWithTax
    }

    if (insurance?.pai?.selected && !insurance?.pai?.restriction) {
      sum += insurance.pai.dailyRate.totalWithTax
    }

    return sum
  }

  getAutoFillEvent(): Observable<any> {
    return this.autoFillSubject.asObservable()
  }

  sendAutoFillEvent(message: string): void {
    this.autoFillSubject.next({ text: message })
  }

  getInsuranceSelection(insurances: Insurance): Record<string, unknown> {
    const insuranceSelection = {}
    Object.entries(insurances).forEach(([name, insurance]) => {
      insuranceSelection[`${name}_selected`] = insurance.selected
    })
    return insuranceSelection
  }

  private getTokenHeaders(token: string) {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token || '',
      ...(this.localStorageService.getXTokenHeader() ? { 'x-token-header': 'true' } : {}),
    })
  }

  private handleGtmEvent(eventType: 'check_in_completed' | 'check_in_failed', booking?: Booking) {
    const data = {
      event: eventType,
    }
    if (booking) {
      Object.assign(data, {
        pickup_location: booking.pickupStation,
        return_location: booking.returnStation,
        ...this.getInsuranceSelection(booking.reservation.insurances),
        price: booking.reservation.price,
      })
    }
    this.googleTagManagerService.triggerGtmEvent(data)
  }
}
