import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import CoreModule from '../core/core.module'
import SharedModule from '../shared/shared.module'
import ConfirmationAdditionalServicesComponent from './confirmation-additional-services/confirmation-additional-services.component'
import ConfirmationCarComponent from './confirmation-car/confirmation-car.component'
import ConfirmationCreditCardComponent from './confirmation-credit-card/confirmation-credit-card.component'
import ConfirmationCustomerDetailsComponent from './confirmation-customer-details/confirmation-customer-details.component'
import ConfirmationComponent from './confirmation.component'

@NgModule({
  imports: [CommonModule, CoreModule, SharedModule, RouterModule],
  declarations: [
    ConfirmationComponent,
    ConfirmationCarComponent,
    ConfirmationAdditionalServicesComponent,
    ConfirmationCustomerDetailsComponent,
    ConfirmationCreditCardComponent,
  ],
  exports: [ConfirmationComponent],
})
export default class ConfirmationModule {}
