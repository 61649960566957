import { Component } from '@angular/core'

import ModalService from '../modal.service'

@Component({
  selector: 'app-autofill-error',
  templateUrl: './autofill-error.component.html',
  styleUrls: ['./autofill-error.component.scss'],
})
export default class AutofillErrorModalComponent {
  constructor(private modalService: ModalService) {}

  /**
   * Closes modal by given id
   *
   * @param id modal id
   */
  closeModal(id: string): void {
    this.modalService.close(id)
  }
}
