<app-accordion
  largeFont="true"
  onlyMobile="true"
  closedBtnText="auto-fill-button-text"
  openedBtnText="auto-fill-button-text"
>
  <div class="form-wrapper">
    <div>
      <form #afForm="ngForm" *ngIf="autoFillForm" [formGroup]="autoFillForm" (ngSubmit)="getAutoFill()">
        <p class="quickFill">{{ 'driver-details-ac-title' | translate }}</p>
        <div>
          <label
            class="pure-material-textfield-outlined"
            [ngClass]="{ 'pure-material-textfield-outlined-error': phone.invalid && phone.touched }"
          >
            <input placeholder=" " type="number" formControlName="phone" inputmode="numeric" />
            <span>{{ 'driver-details-ac-enter-mobile' | translate }}</span>
          </label>
        </div>
        <p class="form__error leftPadding" *ngIf="message">
          {{ message | translate }}
        </p>

        <div class="form__row">
          <div>
            <a
              id="driver-details-address-search-info-link"
              class="btn__link btn__help {{ infoButtonOrLinkClassName }}"
              (click)="openModal('driver-details-bisnode-help-modal')"
            >
              {{ 'help' | translate }}</a
            >
          </div>
          <button [disabled]="!afForm.form.valid" type="submit" class="btn__primary--outlined btn__short">
            {{ 'search' | translate }}
          </button>

          <app-modal id="driver-details-bisnode-help-modal">
            <app-driver-details-bisnode-help></app-driver-details-bisnode-help>
          </app-modal>
        </div>
      </form>
    </div>
  </div>
</app-accordion>
<app-modal id="autofill-error-modal" errorModal="true">
  <app-autofill-error></app-autofill-error>
</app-modal>
