import { Component, OnInit } from '@angular/core'

import CountryComponent from '../../core/country/country.component'
import LocalStorageService from '../../core/local-storage.service'
import ModalService from '../../shared/modal/modal.service'

@Component({
  selector: 'app-driver-details-driving-license-help',
  templateUrl: './driver-details-driving-license-help.component.html',
  styleUrls: ['./driver-details-driving-license-help.component.scss'],
})
export default class DriverDetailsDrivingLicenseHelpComponent extends CountryComponent implements OnInit {
  isEu: boolean

  constructor(private modalService: ModalService, private localStorageService: LocalStorageService) {
    super(localStorageService)
  }

  ngOnInit(): void {
    if (this.norway()) {
      this.isEu = false
    } else {
      this.isEu = true
    }
  }

  /**
   * Closes modal by given id
   *
   * @param id modal id
   */
  closeModal(id: string): void {
    this.modalService.close(id)
  }

  /**
   * Toggles between norwegian and european driving license
   */
  toggleDrivingLicense(): void {
    this.isEu = !this.isEu
  }
}
