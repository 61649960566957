import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export default class MessagesService {
  messages = new BehaviorSubject<string>('')

  errorMessage = new BehaviorSubject<string>('')

  add(message: string): void {
    this.messages.next(message)
  }

  addErrorMessage(message: string): void {
    this.errorMessage.next(message)
  }
}
