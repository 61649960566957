<div class="block">
  <div class="container-fluid">
    <div id="swedbank-pay-seamless-view-page"></div>
  </div>
</div>
<div class="block block--gray">
  <h2 class="section__heading">{{ 'credit-card-info-title' | translate }}</h2>
  <p class="section__body">{{ 'credit-card-info-1' | translate }}</p>
  <p class="section__body">{{ 'credit-card-info-2' | translate }}</p>
</div>
