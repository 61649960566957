<button
  class="mainNav__langPicker__btn flagIcon flagIcon--{{ languageOptions.nativeCountryShort }}"
  *ngIf="getSelectedLang() !== languageOptions.nativeLanguage"
  (click)="changeLanguage(languageOptions.nativeLanguage)"
>
  <span class="visuallyhidden">{{ languageOptions.nativeLanguageShort | translate }}</span>
</button>

<button
  class="mainNav__langPicker__btn flagIcon flagIcon--{{ languageOptions.alternativeCountryShort }}"
  *ngIf="getSelectedLang() !== languageOptions.alternativeLanguage"
  (click)="changeLanguage(languageOptions.alternativeLanguage)"
>
  <span class="visuallyhidden">{{ languageOptions.alternativeLanguageShort | translate }}</span>
</button>
