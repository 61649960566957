import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'

import BookingService from '../core/booking.service'
import LocalStorageService from '../core/local-storage.service'
import Booking from '../core/models/booking'
import CreditCard from '../core/models/credit-card'

@Injectable({
  providedIn: 'root',
})
export default class CreditCardRegistrationResolverService implements Resolve<any> {
  constructor(private localStorageService: LocalStorageService, private bookingService: BookingService) {}

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<CreditCard> | Booking {
    const orderRef = this.localStorageService.getOrderRef()

    if (orderRef && !this.localStorageService.getSkipPayment()) {
      return this.bookingService.getMaskedCreditCardNumber(orderRef)
    }

    return this.localStorageService.getBooking()
  }
}
