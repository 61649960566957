import { Component, ElementRef, Input, OnInit } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { Subscription } from 'rxjs'

import countries from '../../../assets/data/flags'
import BookingService from '../../core/booking.service'
import ChangeCountryService from '../country-change-service'

@Component({
  selector: 'app-issuing-country-picker',
  templateUrl: './issuing-country-picker.component.html',
  styleUrls: ['./issuing-country-picker.component.scss'],
})
export default class IssuingCountryPickerComponent implements OnInit {
  @Input() customerForm: any

  @Input() formControlIsRequired: any

  @Input() selectedIssuingCountry: string

  autoFillSubscription: Subscription

  sortedCountries: any

  selectedFlag: string

  showDropdown: boolean

  filterValue = ''

  selectedListItem: any

  selectedCountryDisplayName: any

  constructor(
    private el: ElementRef,
    private service: BookingService,
    private changeCountryService: ChangeCountryService
  ) {
    this.autoFillSubscription = this.service.getAutoFillEvent().subscribe((message) => {
      if (message.text === 'autofill') {
        if (
          this.customerForm &&
          this.customerForm.controls &&
          this.customerForm.controls.driverLicense.controls.licenseIssueLocation.value
        ) {
          this.selectedIssuingCountry = this.customerForm.controls.driverLicense.controls.licenseIssueLocation.value

          this.changeCountryService.newSelectedIssuingCountry.next({ countryCode: this.selectedIssuingCountry })

          this.selectedFlag = this.selectedIssuingCountry.toLowerCase()
          this.customerForm.controls.driverLicense.controls.licenseIssueLocation.setValue(this.selectedIssuingCountry)
        }
      }
    })
  }

  get countryCode(): AbstractControl {
    return this.customerForm.get('driverLicense.licenseIssueLocation')
  }

  sortCountriesByName = (): any => {
    return countries.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }

      return 0
    })
  }

  sortCountries = (): any => {
    let sortedCountries = this.sortCountriesByName()

    const { topCountries, otherCountries } = this.filterTopCountries(sortedCountries)
    // Add topCountries on top of sortedCountries array
    sortedCountries = [...topCountries, ...otherCountries]

    return sortedCountries
  }

  filterTopCountries = (sortedCountries: any): any => {
    const otherCountries = []
    const topCountries = sortedCountries.filter((c) => {
      if (c.name === 'Sweden' || c.name === 'Norway' || c.name === 'Denmark' || c.name === 'Finland') {
        return true
      }
      otherCountries.push(c)

      return false
    })

    return { topCountries, otherCountries }
  }

  ngOnInit(): void {
    if (!this.customerForm) {
      return
    }

    this.sortedCountries = this.sortCountries()
    this.selectedFlag = this.selectedIssuingCountry.toLowerCase()

    this.setSelectedCountryDisplayNameFromCountryList()

    this.changeCountryService.newSelectedCountry.subscribe((res) => {
      this.selectedFlag = res.countryCode.toLowerCase()
      this.selectedCountryDisplayName = res.countryName
      this.customerForm.controls.driverLicense.controls.licenseIssueLocation.setValue(this.selectedFlag)
      this.updateCountryListSelected()
    })
  }

  setSelectedCountryDisplayNameFromCountryList(): void {
    this.selectedCountryDisplayName = Object.values(this.sortedCountries).find((element: any) => {
      if (element.code === this.selectedIssuingCountry) {
        return element.name
      }
      return undefined
      // eslint-disable-next-line
    })['name']
  }

  changeFlagIcon(countryCode: string): void {
    this.selectedFlag = countryCode.toLowerCase()
  }

  /**
   * Toggle flag dropdown list
   */
  toggleDropDown(): void {
    this.showDropdown = !this.showDropdown
    this.filterValue = ''

    const input = this.el.nativeElement.querySelector('.filterInput')
    setTimeout(() => {
      input.focus()
    }, 10)
  }

  /**
   * Add selected class when navigating the filter via tab-key
   *
   * @param e event focus event
   */
  addSelectedClass(e): void {
    const allItems = this.el.nativeElement.querySelectorAll('.countryList__item')
    allItems.forEach((el) => {
      if (el.classList.contains('countryList__item--selected')) {
        el.classList.remove('countryList__item--selected')
      }
    })

    e.target.classList.add('countryList__item--selected')
  }

  /**
   * When customer country is changed the css-selected issuingCountry should be changed as well so it shows correct when opening issuingCountry dropdown
   */
  updateCountryListSelected(): void {
    const allItems = this.el.nativeElement.querySelectorAll('.countryList__item')
    allItems.forEach((el) => {
      if (el.classList.contains('countryList__item--selected')) {
        el.classList.remove('countryList__item--selected')
      }
      if (el.innerText === this.selectedCountryDisplayName) {
        el.classList.add('countryList__item--selected')
      }
    })
  }

  /**
   * Trigger click via enter-key when li is focused
   *
   * @param e event keydown
   */
  triggerClick(e): void {
    // Add if up or down arrow put focus in input field
    if (e.keyCode === 38 || e.keyCode === 40) {
      const input = this.el.nativeElement.querySelector('.filterInput')
      input.focus()
    }

    if (e.keyCode !== 13) {
      return
    }

    e.target.click()
  }

  /**
   * Make country dropdown list navigable by up arrow, down arrow, tab and select on enter
   *
   * @param event event the keyup input event
   * @param filterValue the value entered in filter input
   */

  /* eslint-disable  complexity */
  keyup(event, filterValue): void {
    const allItems = this.el.nativeElement.querySelectorAll('.countryList__item')
    const { keyCode } = event

    // Handle no value, backspace,
    if (filterValue.length <= 1 && keyCode === 8) {
      allItems.forEach((element) => {
        if (element.classList.contains('countryList__item--selected')) {
          element.classList.remove('countryList__item--selected')
        }
      })

      return
    }

    if (keyCode !== 40 && keyCode !== 38 && keyCode !== 13 && keyCode !== 16) {
      this.selectedListItem = this.el.nativeElement.querySelector('.countryList__item')
      if (this.selectedListItem) {
        this.selectedListItem.classList.add('countryList__item--selected')
      }
    }

    // querySelectorAll to get element as a nodelist
    const selected = this.el.nativeElement.querySelectorAll('.countryList__item--selected')

    // Remove any excess selected classes
    allItems.forEach((element) => {
      if (element.classList.contains('countryList__item--selected')) {
        element.classList.remove('countryList__item--selected')
      }
    })

    // Down key
    if (keyCode === 40) {
      event.preventDefault()
      this.selectedListItem =
        !selected.length || selected[0].nextElementSibling === null ? allItems[0] : selected[0].nextElementSibling

      // Up key
    } else if (keyCode === 38) {
      event.preventDefault()
      this.selectedListItem =
        !selected.length || selected[0].previousElementSibling === null
          ? allItems[allItems.length - 1]
          : selected[0].previousElementSibling
    }

    // When typing add selected class to current country and scroll into view
    if (keyCode !== 13 && this.selectedListItem) {
      this.selectedListItem.classList.add('countryList__item--selected')
    }

    // On enter select country
    if (keyCode === 13) {
      if (this.selectedListItem) {
        this.selectedListItem.click()
      } else {
        this.selectedFlag = undefined
        this.selectedCountryDisplayName = ''
        this.customerForm.controls.driverLicense.controls.licenseIssueLocation.setValue('')
      }
    }
  }

  countrySelected(countryCode: string, countryName: string): void {
    this.changeCountryService.newSelectedIssuingCountry.next({ countryCode })

    this.selectedIssuingCountry = countryName
    this.selectedFlag = countryCode.toLowerCase()
    this.selectedCountryDisplayName = countryName
    this.customerForm.controls.driverLicense.controls.licenseIssueLocation.setValue(this.selectedFlag)
    this.toggleDropDown()
  }
}
