import { Pipe, PipeTransform } from '@angular/core'

import * as moment from 'moment'
import 'moment-timezone'
import { BehaviorSubject } from 'rxjs'

import LocalStorageService from './local-storage.service'
import TranslateService from './translate.service'

@Pipe({
  name: 'localizedDateFormat',
})
export default class LocalizedDateFormatPipe implements PipeTransform {
  /**
   * MomentPipe constructor
   *
   * @param TranslateService translate
   */
  constructor(private translate: TranslateService, private localStorageService: LocalStorageService) {}

  /**
   * Make moment dynamic
   *
   * @param string value
   * @param string format
   * @returns any
   */
  transform(value: string, format?: string): any {
    const timeZone = this.localStorageService.getTimeZone()
    format = format || 'L'
    const momentObs = new BehaviorSubject<string>(moment.locale())
    this.translate.localeSubject.subscribe(() => {
      const val = moment(value).tz(timeZone).format(format)
      momentObs.next(val)
    })

    return momentObs // needs to be piped into the async pipe e.g. {{ date | localizedDateFormat:'LLLL' | async }}
  }
}
