import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core'

import DialogService from './dialog.service'

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export default class DialogComponent implements OnInit, OnDestroy {
  @Input() id: string

  @Input() title: string

  private element: any

  private focusedElementBeforeDialog: any

  private firstFocusableElementInModal: HTMLScriptElement

  private lastFocusableElementInModal: HTMLScriptElement

  constructor(private dialogService: DialogService, private el: ElementRef) {
    this.element = el.nativeElement
    this.focusedElementBeforeDialog = document.activeElement
  }

  ngOnInit(): void {
    const dialog = this

    if (!this.id) {
      console.error('dialog must have an id')
      return
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element)

    // close dialog on background click
    this.element.addEventListener('click', (event: any) => {
      if (event.target.className === 'dialog' || event.target.className === 'dialog__outer') {
        dialog.close()
      }
    })

    this.element.style.display = 'none'

    document.body.addEventListener('keydown', this.handleTabAndEscape(dialog))

    // add this dialog instance to the modal service so it's accessible from controllers
    this.dialogService.add(this)
  }

  ngAfterViewInit(): void {
    this.firstFocusableElementInModal = this.getFirstFocusableElementInModal()
    this.lastFocusableElementInModal = this.getLastFocusableElementInModal()
  }

  /**
   * Remove self from modal service when directive is destroyed
   */
  ngOnDestroy(): void {
    this.dialogService.remove(this.id)
    this.element.remove()
    document.body.removeEventListener('keyup', this.handleTabAndEscape(this))
  }

  open(): void {
    this.focusedElementBeforeDialog = document.activeElement
    this.element.style.display = 'block'
    document.body.classList.add('dialog-open')
    this.setFocusToFirstChildInModal()
  }

  close(): void {
    this.element.style.display = 'none'
    document.body.classList.remove('dialog-open')

    this.focusedElementBeforeDialog.focus()
  }

  /**
   * Sets tab focus to first tabbable element
   */
  setFocusToFirstChildInModal(): void {
    const firstTabStop = this.getFirstFocusableElementInModal()
    firstTabStop.focus()
  }

  /**
   * Sets tab focus to last tabbable element
   */
  setFocusToLastChildInModal(): void {
    const lastTabStop = this.getLastFocusableElementInModal()
    lastTabStop.focus()
  }

  /**
   * Gets the first tabbable element
   */
  getFirstFocusableElementInModal(): HTMLScriptElement {
    const focusableElements = this.getfocusableElements()
    return (focusableElements as Array<HTMLScriptElement>)[0]
  }

  /**
   * Gets the last tabbable element
   */
  getLastFocusableElementInModal(): HTMLScriptElement {
    const focusableElements = this.getfocusableElements()
    return (focusableElements as Array<HTMLScriptElement>)[focusableElements.length - 1]
  }

  /**
   * Gets all tabbable element
   */
  getfocusableElements(): any {
    const focusableElementsString =
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), ' +
      'iframe, object, embed, [tabindex="0"], [contenteditable]'

    return this.element.querySelectorAll(focusableElementsString)
  }

  /**
   * Escape button closes the dialog
   */
  // eslint-disable-next-line class-methods-use-this
  private handleTabAndEscape(dialog: this): (event: KeyboardEvent) => any {
    return (event: any) => {
      if (event.which === 9) {
        if (document.activeElement.className == 'notranslate dialog-open') {
          event.preventDefault()
          this.firstFocusableElementInModal.focus()
        } else if (document.activeElement === dialog.lastFocusableElementInModal) {
          event.preventDefault()
          this.firstFocusableElementInModal.focus()
        }
      } else if (event.which === 27) {
        dialog.close()
      }
    }
  }
}
