import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'

import BookingService from '../core/booking.service'
import LocalStorageService from '../core/local-storage.service'

@Injectable({
  providedIn: 'root',
})
export default class MyBookingResolverService implements Resolve<any> {
  constructor(private service: BookingService, private localStorageService: LocalStorageService) {}

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const oldToken = route.queryParamMap.get('q')
    const newToken = route.queryParamMap.get('t')

    if (oldToken) {
      return this.handleToken(oldToken, false)
    }
    if (newToken) {
      return this.handleToken(newToken, true)
    }
    return this.service.getBooking(this.localStorageService.getToken())
  }

  private handleToken(token: string, tokenHeader: boolean) {
    this.localStorageService.setXTokenHeader(tokenHeader)
    return this.service.getBooking(token)
  }
}
