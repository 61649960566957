import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import CoreModule from '../core/core.module'
import LocationMoreInformationComponent from './location-more-information/location-more-information.component'
import AccordionComponent from './accordion/accordion.component'
import DialogComponent from './dialog/dialog.component'
import DialogService from './dialog/dialog.service'
import FooterComponent from './footer/footer.component'
import InfoTextBoxComponent from './info-text-box/info-text-box.component'
import MessagesService from './messages.service'
import AutofillErrorModalComponent from './modal/autofill-error-modal/autofill-error.component'
import CreditCardErrorModalComponent from './modal/credit-card-error-modal/credit-card-error.component'
import ModalComponent from './modal/modal.component'
import ModalService from './modal/modal.service'
import SharedAdditionalInformationComponent from './shared-additional-information/shared-additional-information.component'
import SharedContactStationComponent from './shared-contact-station/shared-contact-station.component'
import SharedLocationComponent from './shared-location/shared-location.component'

@NgModule({
  imports: [CommonModule, CoreModule],
  declarations: [
    AccordionComponent,
    AutofillErrorModalComponent,
    CreditCardErrorModalComponent,
    LocationMoreInformationComponent,
    ModalComponent,
    DialogComponent,
    SharedContactStationComponent,
    SharedLocationComponent,
    SharedAdditionalInformationComponent,
    FooterComponent,
    InfoTextBoxComponent,
  ],
  providers: [MessagesService, ModalService, DialogService],
  exports: [
    AccordionComponent,
    AutofillErrorModalComponent,
    CreditCardErrorModalComponent,
    LocationMoreInformationComponent,
    ModalComponent,
    DialogComponent,
    SharedContactStationComponent,
    SharedLocationComponent,
    SharedAdditionalInformationComponent,
    FooterComponent,
    InfoTextBoxComponent,
  ],
})
export default class SharedModule {}
