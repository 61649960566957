import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import CountryComponent from '../../core/country/country.component'
import LocalStorageService from '../../core/local-storage.service'
import PaymentDetails from '../../core/models/payment-details'
import CreditCardService from '../../shared/credit-card.service'

@Component({
  selector: 'app-confirmation-credit-card',
  templateUrl: './confirmation-credit-card.component.html',
  styleUrls: ['./confirmation-credit-card.component.scss'],
})
export default class ConfirmationCustomerDetailsComponent extends CountryComponent implements OnInit {
  @Input() paymentDetails: PaymentDetails
  skipPayment: boolean

  constructor(
    private router: Router,
    private creditCardService: CreditCardService,
    private localStorageService: LocalStorageService
  ) {
    super(localStorageService)
  }

  ngOnInit(): void {
    this.skipPayment = this.localStorageService.getSkipPayment()
  }

  /**
   * Navigate to credit card
   */
  navigateToPayex(): void {
    this.router.navigate(['/credit-card'], { queryParams: { edit: 'edit' } })
  }

  /**
   * Fetch image assets from service
   *
   * @returns string
   */
  getCreditCardAsset(): string {
    const paymentMethod =
      !!this.paymentDetails && !!this.paymentDetails.creditCard && !!this.paymentDetails.creditCard.paymentMethod
        ? this.paymentDetails.creditCard.paymentMethod
        : ''

    return this.creditCardService.getCreditCardAsset(paymentMethod)
  }
}
