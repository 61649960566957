import { AfterViewInit, Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import CountryComponent from '../core/country/country.component'
import LocalStorageService from '../core/local-storage.service'
import CreditCard from '../core/models/credit-card'
import LanguageOption from '../core/models/language-option'
import TranslateService from '../core/translate.service'
import MessagesService from '../shared/messages.service'
import ModalService from '../shared/modal/modal.service'
import CreditCardRegistrationService from './credit-card-registration.services'

@Component({
  selector: 'app-credit-card-registration',
  templateUrl: './credit-card-registration.component.html',
  styleUrls: ['./credit-card-registration.component.scss'],
})
export default class CreditCardRegistrationComponent extends CountryComponent implements OnInit, AfterViewInit {
  creditCardError = false

  creditCard: CreditCard

  showCreditCardNeeded: boolean

  languageOptions: LanguageOption

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private localStorageService: LocalStorageService,
    private messageService: MessagesService,
    private translate: TranslateService,
    private creditCardRegistrationService: CreditCardRegistrationService
  ) {
    super(localStorageService)
  }

  ngOnInit(): void {
    this.creditCard = this.localStorageService.getCreditCard()

    this.messageService.messages.subscribe((message) => {
      if (message === 'credit-card-error-message') {
        this.creditCardError = true
      }
    })

    if (this.localStorageService.getPaymentState()) {
      this.creditCardRegistrationService.setShowCreditCardNeeded(true)
    }

    this.creditCardRegistrationService.showCreditCardNeeded.subscribe((show) => {
      this.showCreditCardNeeded = show
    })

    this.languageOptions = this.getLangOptions()
  }

  ngAfterViewInit(): void {
    if (this.creditCardError) {
      this.openModal('credit-card-error-modal')
      this.creditCardError = false
    }
  }

  /**
   * Opens modal by given id
   *
   * @param id modal id
   */
  openModal(id: string): void {
    this.modalService.open(id)
  }

  /**
   * Closes modal by given id
   *
   * @param id modal id
   */
  closeModal(id: string): void {
    this.modalService.close(id)
  }

  /**
   * Get selected language or default
   */
  getSelectedLang(): string {
    return this.translate.currentLocaleOrDefault
  }
}
