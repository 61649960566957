<div
  *ngIf="(body?.english && isEnglishLanguage) || (body?.native && !isEnglishLanguage)"
  class="informationBox innerPadding {{ class }}"
>
  <img class="informationBox__icon" src="{{ icon }}" />
  <div class="informationContainer">
    <div class="informationBox__title">{{ title }}</div>
    <div class="informationBox__content">{{ isEnglishLanguage ? body?.english : body?.native }}</div>
  </div>
</div>
