import { Injectable } from '@angular/core'

@Injectable()
export default class ModalService {
  private modals: Array<any> = []

  /**
   * Add modal to array of active modals
   *
   * @param modal the modal to add
   */
  add(modal: any): void {
    this.modals.push(modal)
  }

  /**
   * Remove modal from array of active modals
   *
   * @param id modal id
   */
  remove(id: string): void {
    this.modals = this.modals.filter((x) => x.id !== id)
  }

  /**
   * Open modal specified by id
   *
   * @param id the id of the modal to open
   */
  open(id: string): void {
    const modalElement: any = this.modals.filter((x) => x.id === id)[0]

    modalElement.open()
  }

  /**
   * Close modal specified by id
   *
   * @param id modal id
   */
  close(id: string): void {
    const modal: any = this.modals.filter((x) => x.id === id)[0]
    modal.close()
  }
}
