import { Component, OnInit } from '@angular/core'

import { CONTACT_LINK_CLASSNAME } from 'src/constants/gtmSelectors'

import LocalStorageService from '../../core/local-storage.service'
import ThemeService from '../../core/themes.service'
import TranslateService from '../../core/translate.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export default class FooterComponent implements OnInit {
  themeName = ''
  country = ''
  phoneNumber = ''

  contactBlocks = []
  socialMediaBlocks = []
  contactInformation = []

  contactLinkClassName = CONTACT_LINK_CLASSNAME

  constructor(
    private themeService: ThemeService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.useLanguage(this.localStorageService.getLocale()).then(() => {
      this.updateInformation()
    })
  }

  updateInformation(): void {
    if (this.localStorageService.getThemeName()) {
      this.themeService.setTheme(this.localStorageService.getThemeName())
    }
    this.themeName = this.themeService.getThemeName()

    this.country = this.localStorageService.getCurrentCountryAsString()
    this.phoneNumber = this.translate.getTranslation('footer-customer-support-phone-number')
    this.socialMediaBlocks = this.translate.getTranslationArray('footer-social-media-blocks')
    this.contactBlocks = this.translate.getTranslationArray('footer-contact-blocks')
    this.contactInformation = this.translate.getTranslationArray('footer-contact-information')
  }
}
