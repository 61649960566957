import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import { Subscription } from 'rxjs'

import BookingService from '../../core/booking.service'
import CountryComponent from '../../core/country/country.component'
import LocalStorageService from '../../core/local-storage.service'
import Driver from '../../core/models/driver'
import LanguageOption from '../../core/models/language-option'
import TranslateService from '../../core/translate.service'
import MessagesService from '../../shared/messages.service'
import ModalService from '../../shared/modal/modal.service'
import { swedishPersonalIdValidator } from '../swedish-personal-id-validator'

@Component({
  selector: 'app-driver-details-autofill-se',
  templateUrl: './driver-details-autofill-se.component.html',
  styleUrls: ['./driver-details-autofill-se.component.scss'],
})
export default class DriverDetailsAutofillSeComponent extends CountryComponent implements OnInit, OnDestroy {
  @Output() readonly autoFill = new EventEmitter<Driver>()

  autoFillForm: UntypedFormGroup

  message: string

  subscription: Subscription

  languageOptions: LanguageOption

  constructor(
    private service: BookingService,
    private fb: UntypedFormBuilder,
    private modalService: ModalService,
    private localStorageService: LocalStorageService,
    private messageService: MessagesService,
    private translate: TranslateService
  ) {
    super(localStorageService)

    this.subscription = this.messageService.messages.subscribe((message) => {
      this.message = message
    })
  }
  get pin(): AbstractControl {
    return this.autoFillForm.get('pin')
  }

  get zip(): AbstractControl {
    return this.autoFillForm.get('zip')
  }
  ngOnInit(): void {
    this.autoFillForm = this.fb.group({
      pin: ['', [swedishPersonalIdValidator, Validators.required]],
      zip: ['', Validators.required],
    })

    this.autoFillForm.get('pin').setValue('')
    this.autoFillForm.get('zip').setValue('')

    this.message = undefined

    this.languageOptions = this.getLangOptions()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  /**
   * Run the auto fill info on submit, and try to get driver information
   *
   * @param e event
   */
  getAutoFill(): void {
    if (!this.autoFillForm.valid) {
      return
    }

    // Check if timestamp is older than 30 minutes (1800000 milliseconds)
    const timestamp = this.localStorageService.getQuickFillTimeStamp()
    const now = Date.now()
    const timeoutIsDone = now - parseInt(timestamp, 10) >= 1800000

    // Show error if timeout isn't done
    if (!timeoutIsDone && timestamp) {
      this.message = 'error-too-many-tries'
      this.openModal('autofill-error-modal')
    }

    // Run autofill if timeout is done or if there is no timestamp in localStorage
    if (timeoutIsDone || !timestamp) {
      this.service.getCustomerForAutoCompleteSweden(this.pin.value, this.zip.value).subscribe((data: Driver) => {
        const driver = data as Driver
        this.autoFill.emit(driver)
        this.service.sendAutoFillEvent('autofill')
        this.message = undefined
      })
    }
  }

  /**
   * Opens modal by given id
   *
   * @param id modal id
   */
  openModal(id: string): void {
    this.modalService.open(id)
  }

  /**
   * Closes modal by given id
   *
   * @param id modal id
   */
  closeModal(id: string): void {
    this.modalService.close(id)
  }

  /**
   * Get selected language or default
   */
  getSelectedLang(): string {
    return this.translate.currentLocaleOrDefault
  }
}
