import { Injectable } from '@angular/core'

interface GtmWindow extends Window {
  dataLayer?: unknown[]
}

declare const window: GtmWindow

@Injectable({
  providedIn: 'root',
})
export default class GoogleTagManagerService {
  triggerGtmEvent(data: { [key: string]: unknown }) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
  }
}
