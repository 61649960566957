import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'filterCountries',
  pure: false,
})
export default class FilterCountriesPipe implements PipeTransform {
  transform(items: Array<any>, filter: any): any {
    if (!items || !filter) {
      return items
    }
    // filter items array, items which match and return true will be kept, false will be filtered out

    return items.filter((item) => item.name.toLowerCase().indexOf(filter.name.toLowerCase()) !== -1)
  }
}
