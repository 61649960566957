<div class="block">
  <div class="block__body blockBodyPadding">
    <div class="insurancesHeadlineWrapper">
      <div class="insurancesHeadlineWrapper__heading">
        <h2 class="section__heading">{{ 'insurances' | translate }}</h2>
      </div>
    </div>
    <div *ngIf="insurances?.cdw" class="insurance">
      <div class="insurance__checkbox">
        <input id="cdw-checkbox" type="checkbox" class="inputCheckbox" (change)="selectedInsuranceChanged('cdw')" />
      </div>
      <div class="insurance__info">
        <h3 class="section__title">{{ 'insurances-cdw-title' | translate }}</h3>
        <!-- THIS REMOVES A SPACE AFTER CURRENCY SINCE IT HAS TO END ON A '.' -->
        <ng-container *ngIf="sweden() && getSelectedLang() !== languageOptions.nativeLanguage">
          <!-- ENGLISH -->
          <p
            *ngIf="insurances?.cdw.withoutWaivable && insurances?.cdw.withWaivable && !isSpecialLiability()"
            class="section__body section__body--small smallMarginBottom"
          >
            {{
              isLiability()
                ? ('insurances-cdw-short-info' | translate)
                : ('insurances-cdw-short-info' | translate) + ('insurances-cdw-short-info-from' | translate)
            }}
            {{ isLiability() ? '' : insurances?.cdw.withoutWaivable }}
            {{ isLiability() ? '' : insuranceInTextCurrency() }}
            {{ 'insurances-cdw-short-info-to' | translate }}
            {{ insurances?.cdw.withWaivable }}
            {{ insuranceInTextCurrency() }}
            {{ 'insurances-cdw-short-info-per-damage' | translate }}
          </p>
          <p
            *ngIf="insurances?.cdw.withoutWaivable && insurances?.cdw.withWaivable && isSpecialLiability()"
            class="section__body section__body--small smallMarginBottom"
          >
            {{ 'additional-services-cdw-specialLiability' | translate }}
          </p>
        </ng-container>
        <ng-container *ngIf="sweden() && getSelectedLang() === languageOptions.nativeLanguage">
          <p
            *ngIf="insurances?.cdw.withoutWaivable && insurances?.cdw.withWaivable && !isSpecialLiability()"
            class="section__body section__body--small smallMarginBottom"
          >
            {{
              isLiability()
                ? ('insurances-cdw-short-info' | translate)
                : ('insurances-cdw-short-info' | translate) + ('insurances-cdw-short-info-from' | translate)
            }}
            {{ isLiability() ? '' : insurances?.cdw.withoutWaivable }}
            {{ isLiability() ? '' : insuranceInTextCurrency() }}
            {{ 'insurances-cdw-short-info-to' | translate }}
            {{ insurances?.cdw.withWaivable }}
            {{ insuranceInTextCurrency() }}
            {{ 'insurances-cdw-short-info-per-damage' | translate }}
          </p>
          <p
            *ngIf="insurances?.cdw.withoutWaivable && insurances?.cdw.withWaivable && isSpecialLiability()"
            class="section__body section__body--small smallMarginBottom"
          >
            {{ 'additional-services-cdw-specialLiability' | translate }}
          </p>
        </ng-container>

        <ng-container *ngIf="!sweden()">
          <p
            *ngIf="!(norway() && isLiability()) && insurances?.cdw.withoutWaivable && insurances?.cdw.withWaivable"
            class="section__body section__body--small smallMarginBottom"
          >
            {{
              isLiability()
                ? ('insurances-cdw-short-info' | translate)
                : ('insurances-cdw-short-info' | translate) + ('insurances-cdw-short-info-from' | translate)
            }}

            {{
              insurances?.cdw.fullValueOfTheCar
                ? ('insurances-full-value-of-car' | translate)
                : getNonLiabilityPriceWithCurrency('cdw')
            }}

            {{ 'insurances-cdw-short-info-to' | translate }}
            {{ insurances?.cdw.withWaivable }}
            {{ insuranceInTextCurrency() }}
            {{ 'insurances-cdw-short-info-per-damage' | translate }}
          </p>
        </ng-container>

        <p
          *ngIf="!(insurances.cdw.withoutWaivable && insurances.cdw.withWaivable)"
          class="section__body section__body--small smallMarginBottom"
        >
          {{ 'insurances-cdw-short-info-fallback' | translate }}
        </p>
        <div *ngIf="!(norway() && isLiability())">
          <app-accordion
            textAlignLeft="true"
            openedBtnText="show-less"
            closedBtnText="more-info"
            gtmSelectorId="cdw-more-info-accordion"
          >
            <p
              class="section__body section__body--small smallMarginBottom"
              *ngFor="let infoText of getTranslationArray('insurances-cdw-long-info')"
            >
              {{ infoText }}
            </p>
          </app-accordion>
        </div>
      </div>
      <div class="insurance__cost">
        <span *ngIf="isIncluded(insurances.cdw)" class="section__price--included">{{ 'included' | translate }}</span>
        <span *ngIf="!isIncluded(insurances.cdw)" class="section__price">
          {{ insurances?.cdw?.dailyRate?.totalWithTax }}
          {{
            insurances?.cdw?.dailyRate?.currency ||
              insurances?.tp?.dailyRate?.currency ||
              insurances?.pai?.dailyRate?.currency ||
              insurances?.scw?.dailyRate?.currency
          }}
        </span>
        <span *ngIf="!isIncluded(insurances.cdw)" class="section__costPerDay">{{ 'per-day' | translate }}</span>
      </div>
    </div>

    <div *ngIf="insurances?.tp" class="insurance">
      <div class="insurance__checkbox">
        <input id="tp-checkbox" type="checkbox" class="inputCheckbox" (change)="selectedInsuranceChanged('tp')" />
      </div>
      <div class="insurance__info">
        <h3 class="section__title">{{ 'insurances-tp-title' | translate }}</h3>
        <!-- THIS REMOVES A SPACE AFTER CURRENCY SINCE IT HAS TO END ON A '.' -->
        <ng-container *ngIf="sweden() && getSelectedLang() !== languageOptions.nativeLanguage">
          <p
            *ngIf="insurances?.tp.withoutWaivable && insurances?.tp.withWaivable && !isSpecialLiability()"
            class="section__body section__body--small smallMarginBottom"
          >
            {{
              isLiability()
                ? ('insurances-tp-short-info' | translate)
                : ('insurances-tp-short-info' | translate) + ('insurances-tp-short-info-from' | translate)
            }}
            {{ isLiability() ? '' : insurances?.tp.withoutWaivable }}
            {{ isLiability() ? '' : insuranceInTextCurrency() }}
            {{ 'insurances-tp-short-info-to' | translate }}
            {{ insurances?.tp.withWaivable }}
            {{ insuranceInTextCurrency() }}
            {{ 'insurances-tp-short-info-per-damage' | translate }}
          </p>
          <p
            *ngIf="insurances?.tp.withoutWaivable && insurances?.tp.withWaivable && isSpecialLiability()"
            class="section__body section__body--small smallMarginBottom"
          >
            {{ 'additional-services-tp-specialLiability' | translate }}
          </p>
        </ng-container>

        <ng-container *ngIf="sweden() && getSelectedLang() === languageOptions.nativeLanguage">
          <p
            *ngIf="insurances?.tp.withoutWaivable && insurances?.tp.withWaivable && !isSpecialLiability()"
            class="section__body section__body--small smallMarginBottom"
          >
            {{
              isLiability()
                ? ('insurances-tp-short-info' | translate)
                : ('insurances-tp-short-info' | translate) + ('insurances-tp-short-info-from' | translate)
            }}
            {{ isLiability() ? '' : insurances?.tp.withoutWaivable }}
            {{ isLiability() ? '' : insuranceInTextCurrency() }}
            {{ 'insurances-tp-short-info-to' | translate }}
            {{ insurances?.tp.withWaivable }}
            {{ insuranceInTextCurrency() }}
            {{ 'insurances-tp-short-info-per-damage' | translate }}
          </p>
          <p
            *ngIf="insurances?.tp.withoutWaivable && insurances?.tp.withWaivable && isSpecialLiability()"
            class="section__body section__body--small smallMarginBottom"
          >
            {{ 'additional-services-tp-specialLiability' | translate }}
          </p>
        </ng-container>

        <ng-container *ngIf="!sweden()">
          <p
            *ngIf="!(norway() && isLiability()) && insurances?.tp.withoutWaivable && insurances?.tp.withWaivable"
            class="section__body section__body--small smallMarginBottom"
          >
            {{
              isLiability()
                ? ('insurances-tp-short-info' | translate)
                : ('insurances-tp-short-info' | translate) + ('insurances-tp-short-info-from' | translate)
            }}

            {{
              insurances?.tp.fullValueOfTheCar
                ? ('insurances-full-value-of-car' | translate)
                : getNonLiabilityPriceWithCurrency('tp')
            }}

            {{ 'insurances-tp-short-info-to' | translate }}
            {{ insurances?.tp.withWaivable }}
            {{ insuranceInTextCurrency() }}
            {{ 'insurances-tp-short-info-per-damage' | translate }}
          </p>
        </ng-container>
        <p
          *ngIf="!(insurances.tp.withoutWaivable && insurances.tp.withWaivable)"
          class="section__body section__body--small smallMarginBottom"
        >
          {{ 'insurances-tp-short-info-fallback' | translate }}
        </p>
        <div *ngIf="!(norway() && isLiability())">
          <app-accordion
            textAlignLeft="true"
            openedBtnText="show-less"
            closedBtnText="more-info"
            gtmSelectorId="tp-more-info-accordion"
            *ngIf="!!getTranslationArray('insurances-tp-long-info').length"
          >
            <p
              class="section__body section__body--small smallMarginBottom"
              *ngFor="let infoText of getTranslationArray('insurances-tp-long-info')"
            >
              {{ infoText }}
            </p>
          </app-accordion>
        </div>
      </div>
      <div class="insurance__cost">
        <span *ngIf="isIncluded(insurances.tp)" class="section__price--included">
          {{ 'included' | translate }}
        </span>
        <span *ngIf="!isIncluded(insurances.tp)" class="section__price">
          {{ insurances?.tp?.dailyRate?.totalWithTax }}
          {{
            insurances?.cdw?.dailyRate?.currency ||
              insurances?.tp?.dailyRate?.currency ||
              insurances?.pai?.dailyRate?.currency ||
              insurances?.scw?.dailyRate?.currency
          }}
        </span>
        <span *ngIf="!isIncluded(insurances.tp)" class="section__costPerDay">{{ 'per-day' | translate }}</span>
      </div>
    </div>
    <div *ngIf="insurances?.scw" class="insurance">
      <div class="insurance__checkbox">
        <input id="scw-checkbox" type="checkbox" class="inputCheckbox" (change)="selectedInsuranceChanged('scw')" />
      </div>

      <!-- ALL BUT NOT SWEDEN OR FINLAND -->
      <div *ngIf="!sweden() && !finland()" class="insurance__info">
        <h3 class="section__title">
          {{ 'insurances-scdw-title' | translate }}
        </h3>
        <p
          *ngIf="!(norway() && isLiability()) && insurances?.scw.withWaivable"
          class="section__body section__body--small smallMarginBottom"
        >
          {{ 'insurances-scdw-short-info' | translate }}
          {{ insurances?.cdw.withWaivable }}
          {{ insuranceInTextCurrency() }}
          {{ 'insurances-scdw-short-info-to' | translate }}
          {{ insurances?.scw.withWaivable }}
          {{ insuranceInTextCurrency() }}
          {{ 'insurances-scdw-short-info-per-damage' | translate }}
        </p>
        <p *ngIf="!insurances.scw.withWaivable" class="section__body section__body--small smallMarginBottom">
          {{ 'insurances-scdw-short-info-fallback' | translate }}
        </p>
        <div *ngIf="!(norway() && isLiability()) && getTranslationArray('insurances-scdw-long-info').length > 0">
          <app-accordion
            textAlignLeft="true"
            openedBtnText="show-less"
            closedBtnText="more-info"
            gtmSelectorId="scdw-more-info-accordion"
          >
            <p
              class="section__body section__body--small smallMarginBottom"
              *ngFor="let infoText of getTranslationArray('insurances-scdw-long-info')"
            >
              {{ infoText }}
            </p>
          </app-accordion>
        </div>
      </div>

      <!-- ONLY FINLAND -->
      <div *ngIf="finland()" class="insurance__info">
        <h3 class="section__title">
          {{ 'insurances-scdw-title' | translate }}
        </h3>
        <div *ngIf="insurances?.scw.withWaivable != null">
          <p class="section__body section__body--small smallMarginBottom">
            {{ 'insurances-scdw-short-info' | translate }}
            {{ insurances?.cdw.withWaivable }}
            {{ insuranceInTextCurrency() }}
            {{ 'insurances-scdw-short-info-to' | translate }}
            {{ insurances?.scw.withWaivable }}
            {{ insuranceInTextCurrency() }}
            {{ 'insurances-scdw-short-info-per-damage' | translate }}
          </p>
          <p
            *ngFor="let infoText of getTranslationArray('insurances-scdw-info-list')"
            class="section__body section__body--small smallMarginBottom"
          >
            {{ infoText }}
          </p>
        </div>
        <p *ngIf="insurances.scw.withWaivable == null" class="section__body section__body--small smallMarginBottom">
          {{ 'insurances-scdw-short-info-fallback' | translate }}
        </p>
        <app-accordion
          textAlignLeft="true"
          openedBtnText="show-less"
          closedBtnText="more-info"
          gtmSelectorId="scdw-more-info-accordion"
        >
          <p
            class="section__body section__body--small smallMarginBottom"
            *ngFor="let infoText of getTranslationArray('insurances-scdw-long-info')"
          >
            {{ infoText }}
          </p>
        </app-accordion>
      </div>

      <!-- ONLY SWEDEN -->
      <div *ngIf="sweden()" class="insurance__info">
        <h3 class="section__title">
          {{ 'insurances-scdw-title' | translate }}
        </h3>
        <p class="section__body section__body--small smallMarginBottom">
          {{ 'insurances-scdw-short-info' | translate }}
        </p>
        <app-accordion textAlignLeft="true" openedBtnText="show-less" closedBtnText="more-info">
          <p
            class="section__body section__body--small smallMarginBottom"
            *ngFor="let infoText of getTranslationArray('insurances-scdw-long-info')"
          >
            {{ infoText }}
          </p>
        </app-accordion>
      </div>
      <div class="insurance__cost">
        <span *ngIf="isIncluded(insurances.scw)" class="section__price--included">{{ 'included' | translate }}</span>
        <span *ngIf="!isIncluded(insurances.scw)" class="section__price">
          {{ insurances?.scw?.dailyRate?.totalWithTax }}
          {{
            insurances?.cdw?.dailyRate?.currency ||
              insurances?.tp?.dailyRate?.currency ||
              insurances?.pai?.dailyRate?.currency ||
              insurances?.scw?.dailyRate?.currency
          }}
        </span>
        <span *ngIf="!isIncluded(insurances.scw)" class="section__costPerDay">{{ 'per-day' | translate }}</span>
      </div>
    </div>
    <ng-container *ngIf="!finland()">
      <div *ngIf="insurances?.pai" class="insurance">
        <div class="insurance__checkbox">
          <input id="pai-checkbox" type="checkbox" class="inputCheckbox" (change)="selectedInsuranceChanged('pai')" />
        </div>
        <div class="insurance__info">
          <h3 class="section__title">
            {{ 'insurances-pai-title' | translate }}
          </h3>
          <p
            *ngIf="
              !(norway() && isLiability()) &&
              insurances?.pai.withWaivable &&
              ((insurances.cdw.selected && insurances.pai.withoutWaivable) ||
                (!insurances.cdw.selected && insurances.cdw.withoutWaivable))
            "
            class="section__body section__body--small smallMarginBottom"
          >
            {{ 'insurances-pai-short-info' | translate }}
            <ng-container *ngIf="!norway()">
              <ng-container *ngIf="insurances.cdw.selected; else elseTemplate">
                {{ insurances.pai.withoutWaivable }}
              </ng-container>
              <ng-template #elseTemplate>
                {{ insurances.cdw.withoutWaivable }}
              </ng-template>
              {{
                insurances?.cdw?.dailyRate?.currency ||
                  insurances?.tp?.dailyRate?.currency ||
                  insurances?.pai?.dailyRate?.currency ||
                  insurances?.scw?.dailyRate?.currency
              }}
              {{ 'insurances-pai-short-info-to' | translate }}
              {{ insurances?.pai.withWaivable }}
              {{
                insurances?.cdw?.dailyRate?.currency ||
                  insurances?.tp?.dailyRate?.currency ||
                  insurances?.pai?.dailyRate?.currency ||
                  insurances?.scw?.dailyRate?.currency
              }}
              {{ 'insurances-pai-short-info2' | translate }}
              {{ insurances?.pai.withWaivable }}
              {{
                insurances?.cdw?.dailyRate?.currency ||
                  insurances?.tp?.dailyRate?.currency ||
                  insurances?.pai?.dailyRate?.currency ||
                  insurances?.scw?.dailyRate?.currency
              }}
              {{ 'insurances-pai-short-info3' | translate }}
            </ng-container>
          </p>
          <p
            *ngIf="
              !(
                insurances?.pai.withWaivable &&
                ((insurances.cdw.selected && insurances.pai.withoutWaivable) ||
                  (!insurances.cdw.selected && insurances.cdw.withoutWaivable))
              )
            "
            class="section__body section__body--small smallMarginBottom"
          >
            {{ 'insurances-pai-short-info-fallback' | translate }}
          </p>
          <div *ngIf="!(norway() && isLiability())">
            <app-accordion
              textAlignLeft="true"
              openedBtnText="show-less"
              closedBtnText="more-info"
              gtmSelectorId="pai-more-info-accordion"
            >
              <p
                class="section__body section__body--small smallMarginBottom"
                *ngFor="let infoText of getTranslationArray('insurances-pai-long-info')"
              >
                {{ infoText }}
              </p>
            </app-accordion>
          </div>
        </div>
        <div class="insurance__cost">
          <span *ngIf="isIncluded(insurances.pai)" class="section__price--included">{{ 'included' | translate }}</span>
          <span *ngIf="!isIncluded(insurances.pai)" class="section__price">
            {{ insurances?.pai?.dailyRate?.totalWithTax }}
            {{
              insurances?.cdw?.dailyRate?.currency ||
                insurances?.tp?.dailyRate?.currency ||
                insurances?.pai?.dailyRate?.currency ||
                insurances?.scw?.dailyRate?.currency
            }}
          </span>
          <span *ngIf="!isIncluded(insurances.pai)" class="section__costPerDay">{{ 'per-day' | translate }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
