import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

interface CountryInformation {
  countryCode: string
  countryName: string
}

interface IssuingCountryInformation {
  countryCode: string
}

/**
 * For changing the issuing country when customer country is selected
 */
@Injectable({ providedIn: 'root' })
export default class ChangeCountryService {
  newSelectedCountry = new Subject<CountryInformation>()
  newSelectedIssuingCountry = new Subject<IssuingCountryInformation>()
}
