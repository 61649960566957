<div class="dialog">
  <div class="dialog__outer">
    <div class="dialog__outer__body">
      <div class="dialog__outer__body__header">
        <h3>{{ title | translate }}</h3>
        <button (click)="close()" class="closeButton" tabIndex="-1">
          <img src="../../../assets/icons/xmark-solid.svg" />
          <span>{{ 'close' | translate }}</span>
        </button>
      </div>
      <div class="dialog__outer__body__content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
