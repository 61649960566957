<div class="flex-row sectionTitle">
  <div class="sectionHeading">{{ 'additional-services' | translate }}</div>
  <span (click)="navigateToAdditionalServices()" class="btn__edit"></span>
</div>

<div class="section__subtitle">
  {{ 'insurances' | translate }}
</div>

<div class="flex-column">
  <div class="flex-row" *ngIf="insurances?.cdw?.selected">
    <div class="section__label">
      {{ 'insurances-cdw-title' | translate }}
    </div>

    <div class="insurance-cost">
      <div *ngIf="insurances?.cdw.restriction !== true" class="price">
        {{ insurances?.cdw.dailyRate.totalWithTax }} {{ insurances?.cdw.dailyRate.currency }}
        <span class="price--per-day"> {{ 'per-day' | translate | lowercase }} </span>
      </div>
      <div *ngIf="insurances?.cdw?.restriction === true" class="price--included">
        {{ 'included' | translate }}
      </div>
    </div>
  </div>

  <div class="flex-row" *ngIf="insurances?.tp?.selected">
    <div class="section__label">
      {{ 'insurances-tp-title' | translate }}
    </div>

    <div class="insurance-cost">
      <div *ngIf="insurances?.tp.restriction !== true" class="price">
        {{ insurances?.tp.dailyRate.totalWithTax }} {{ insurances?.tp.dailyRate.currency }}
        <span class="price--per-day"> {{ 'per-day' | translate | lowercase }} </span>
      </div>
      <div *ngIf="insurances?.tp?.restriction === true" class="price--included">
        {{ 'included' | translate }}
      </div>
    </div>
  </div>

  <div class="flex-row" *ngIf="insurances?.pai?.selected">
    <div class="section__label">
      {{ 'insurances-pai-title' | translate }}
    </div>

    <div class="insurance-cost">
      <div *ngIf="insurances?.pai.restriction !== true" class="price">
        {{ insurances?.pai.dailyRate.totalWithTax }} {{ insurances?.pai.dailyRate.currency }}
        <span class="price--per-day"> {{ 'per-day' | translate | lowercase }} </span>
      </div>
      <div *ngIf="insurances?.pai?.restriction === true" class="price--included">
        {{ 'included' | translate }}
      </div>
    </div>
  </div>

  <div class="flex-row" *ngIf="insurances?.scw?.selected">
    <div class="section__label">
      {{ 'insurances-scdw-title' | translate }}
    </div>

    <div class="insurance-cost">
      <div *ngIf="insurances?.scw.restriction !== true" class="price">
        {{ insurances?.scw.dailyRate.totalWithTax }} {{ insurances?.scw.dailyRate.currency }}
        <span class="price--per-day"> {{ 'per-day' | translate | lowercase }} </span>
      </div>
      <div *ngIf="insurances?.scw?.restriction === true" class="price--included">
        {{ 'included' | translate }}
      </div>
    </div>
  </div>

  <div class="flex-row totalEstimatedCost">
    <div class="section__label">
      {{ 'total-estimated-extra-charges' | translate }}
    </div>

    <div class="insurance-cost">
      <div class="price">{{ sum }} {{ currency }}</div>
    </div>
  </div>
  <div class="finalCost__body">
    {{ 'extra-charges-at-pick-up' | translate }}
  </div>
</div>
