import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

import AdditionalServicesComponent from './additional-services/additional-services.component'
import ConfirmationComponent from './confirmation/confirmation.component'
import { AuthGuardService as AuthGuard } from './core/auth-guard.service'
import CreditCardRegistrationResolverService from './credit-card-registration/credit-card-registration-resolver.service'
import CreditCardRegistrationComponent from './credit-card-registration/credit-card-registration.component'
import DriverDetailsComponent from './driver-details/driver-details.component'
import MyBookingResolverService from './my-booking/my-booking-resolver.service'
import MyBookingComponent from './my-booking/my-booking.component'

const routes: Routes = [
  {
    path: '',
    component: MyBookingComponent,
    resolve: {
      booking: MyBookingResolverService,
    },
  },
  {
    path: 'my-booking',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'additional-services',
    component: AdditionalServicesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'driver-details',
    component: DriverDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'credit-card',
    component: CreditCardRegistrationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    canActivate: [AuthGuard],
    resolve: {
      creditCard: CreditCardRegistrationResolverService,
    },
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule),
    canActivate: [AuthGuard],
  },

  { path: '**', redirectTo: '', pathMatch: 'full' },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export default class AppRoutingModule {}
