import { AbstractControl } from '@angular/forms'

const phoneNumberValidator = (control: AbstractControl): any => {
  const phonenumber = control.value
  const regExp = /^[0-9+][\d]*$/m
  if (!phonenumber) {
    return undefined
  }
  if (!regExp.test(phonenumber)) {
    return { validPhoneNumber: { value: false } }
  }

  return null
}

export default phoneNumberValidator
