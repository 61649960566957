import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'

import LocalStorageService from './local-storage.service'

@Injectable({
  providedIn: 'root',
})
export default class ThemeService {
  themeName = ''
  constructor(@Inject(DOCUMENT) private document: Document, private localStorageService: LocalStorageService) {
    this.themeName = this.localStorageService.getThemeName()
  }

  setTheme(brand: string) {
    // Theme name
    this.themeName = brand.toLowerCase()
    this.localStorageService.saveThemeName(this.themeName)
    const themeSrc = `${this.themeName}.css`

    // Stylesheet
    const head = this.document.getElementsByTagName('head')[0]
    const themeLink = this.document.getElementById('client-theme') as HTMLLinkElement
    if (themeLink) {
      themeLink.href = themeSrc
    } else {
      const style = this.document.createElement('link')
      style.id = 'client-theme'
      style.rel = 'stylesheet'
      style.href = `${themeSrc}`

      head.appendChild(style)
    }

    // Favicon
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      link.type = 'image/x-icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = this.getBrandFavicon(this.themeName)

    // Title
    const title = this.document.getElementsByTagName('title')[0]
    title.innerHTML = `${this.themeName.charAt(0).toUpperCase() + this.themeName.slice(1)} Online Check-in`
  }

  getThemeName() {
    return this.themeName
  }

  getThemeLogo(): string {
    return this.themeName ? `../assets/img/logo_${this.themeName}.svg` : `../assets/img/logo_hertz.svg`
  }

  getBrandFavicon = (brand: string) => {
    switch (brand) {
      case 'dollar':
        return 'https://resources.drivehertz.net/favicons/dollar.ico'
      case 'thrifty':
        return 'https://resources.drivehertz.net/favicons/thrifty.ico'
      default:
        return 'https://resources.drivehertz.net/favicons/hertz.ico'
    }
  }
}
