import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'

import countries from '../../../assets/data/flags'
import BookingService from '../../core/booking.service'
import LocalStorageService from '../../core/local-storage.service'
import Country from '../../core/models/country'

@Component({
  selector: 'app-telephone-country-code-picker',
  templateUrl: './telephone-country-code-picker.component.html',
  styleUrls: ['./telephone-country-code-picker.component.scss'],
})
export default class TelephoneCountryCodePickerComponent implements OnInit, OnDestroy {
  @Input() customerForm: any

  @Input() formControlIsRequired: any

  sortedCountries: any

  selectedFlag: string

  selectedDialCode: string

  show: boolean

  phoneIsDisabled: boolean

  filterValue = ''

  current: any

  autoFillSubscription: Subscription

  message: any

  constructor(
    private localStorageService: LocalStorageService,
    private el: ElementRef,
    private service: BookingService
  ) {
    /**
     * Format phonenumber and set flag on autofill event
     */
    this.autoFillSubscription = this.service.getAutoFillEvent().subscribe((message) => {
      this.message = message
      if (message.text === 'autofill') {
        // TODO: Refactor the formatting to its own function it's being repeated multiple times in this file but with small differences
        if (this.customerForm && this.customerForm.controls && this.customerForm.controls.telephone.value) {
          // Get initial value and replace 00 with
          let str = this.customerForm.controls.telephone.value.substring(0, 5).trim()
          if (str.startsWith('00')) {
            str = str.replace('00', '+')
          }

          // Find country by matching dial_code
          const inputValueCountry: any = Object.values(this.sortedCountries).find((country: any) => {
            if (str.indexOf(country.dial_code) === 0) {
              return country.dial_code
            }
            return undefined
          })

          if (inputValueCountry) {
            this.selectedFlag = inputValueCountry.code.toLowerCase()
            this.selectedDialCode = inputValueCountry.dial_code
            const inputValue = this.customerForm.controls.telephone.value
            // Add space after dialcode

            const newValue = inputValue
              .split(' ')
              .join('')
              .replace(inputValueCountry.dial_code, `${inputValueCountry.dial_code} `)

            this.customerForm.controls.telephone.setValue(newValue)
          }
        }
      }
    })
  }

  ngOnInit(): void {
    if (!this.customerForm) {
      return
    }

    /**
     * Disable/enable telephone field on init
     */
    if (!this.customerForm.controls.telephone.value) {
      this.customerForm.controls.telephone.enable()
      this.phoneIsDisabled = false
    } else {
      this.customerForm.controls.telephone.disable()
      this.phoneIsDisabled = true
    }

    /**
     * Sort countries by name
     */
    const sortedCountries = countries.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }

      return 0
    })

    /**
     * Filter out top countries
     */
    const otherCountries = []
    const topCountries = sortedCountries.filter((c) => {
      if (c.name === 'Sweden' || c.name === 'Norway' || c.name === 'Denmark' || c.name === 'Finland') {
        return true
      }
      otherCountries.push(c)

      return false
    })

    /**
     * Filter out selected country by appCountry
     */
    const selectedCountry = sortedCountries.filter(
      (c) => c.name === Country[this.localStorageService.getCurrentCountry()]
    )

    // Add topCountries on top of sortedCountries array
    this.sortedCountries = [...topCountries, ...otherCountries]

    /**
     * Set initial selected country by input value or app country
     */
    if (this.customerForm.controls.telephone.value) {
      // Get initial value and replace 00 with
      let str = this.customerForm.controls.telephone.value.substring(0, 5).trim()
      if (str.startsWith('00')) {
        str = str.replace('00', '+')
      }

      // Find country by matching dial_code
      const inputValueCountry: any = Object.values(this.sortedCountries).find((element: any) => {
        if (str.indexOf(element.dial_code) === 0) {
          return element.dial_code
        }
        return undefined
      })

      if (inputValueCountry) {
        this.selectedFlag = inputValueCountry.code.toLowerCase()
        this.selectedDialCode = inputValueCountry.dial_code
        let inputValue = this.customerForm.controls.telephone.value
        if (inputValue.startsWith('00')) {
          inputValue = inputValue.replace('00', '+')
        }
        // Add space after dialcode
        const newValue = inputValue
          .split(' ')
          .join('')
          .replace(inputValueCountry.dial_code, `${inputValueCountry.dial_code} `)
        this.customerForm.controls.telephone.setValue(newValue)
      } else {
        // If there is no dialcode remove flag
        this.selectedFlag = 'none'
        this.selectedDialCode = selectedCountry[0].dial_code
      }
    } else {
      this.selectedFlag = selectedCountry[0].code.toLowerCase()
      this.selectedDialCode = selectedCountry[0].dial_code
      this.customerForm.controls.telephone.setValue(`${selectedCountry[0].dial_code} `)
    }

    // Hide dropdown on init
    this.show = false
  }

  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    this.autoFillSubscription.unsubscribe()
  }

  /**
   * Set flag and dial code on click
   *
   * @param countryCode string
   * @param countryDialCode string
   */
  countryCodeSelected(countryCode, countryDialCode): void {
    this.selectedFlag = countryCode.toLowerCase()
    this.selectedDialCode = countryDialCode
    this.show = false

    const curValue = this.customerForm.controls.telephone.value
    let nextValue = curValue

    nextValue.split(' ').join('')

    if (nextValue.startsWith('00')) {
      nextValue = nextValue.replace('00', '+')
    }

    const found: any = Object.values(this.sortedCountries).find((element: any) => {
      // Add '+' to phonenumber if missing from input
      if (nextValue.indexOf('+') === -1) {
        nextValue = `+${nextValue}`
      }
      // Handle autofill
      if (nextValue.length > 5) {
        if (nextValue.indexOf(element.dial_code) === 0) {
          return element.dial_code
        }
      }

      // Handle normal input
      return element.dial_code === nextValue
    })

    const input = this.el.nativeElement.querySelector('.phoneInput')

    if (found) {
      const dialCode = found.dial_code
      const newValue = curValue.split(' ').join('').replace(dialCode, `${countryDialCode} `)
      this.customerForm.controls.telephone.setValue(newValue)
      input.focus()
    } else {
      this.customerForm.controls.telephone.setValue(`${countryDialCode} `)
      input.focus()
    }
  }

  /**
   * Set flag and dial code on input change
   *
   * @param event string input $event
   */
  handleChange(event): void {
    let e = event.target.value

    if (e.startsWith('00')) {
      e = e.replace('00', '+')
    }

    const found: any = Object.values(this.sortedCountries).find((element: any) => {
      // Add '+' to phonenumber if missing from input
      if (e.indexOf('+') === -1) {
        e = `+${e}`
      }
      // Handle autofill
      if (e.length > 5) {
        if (e.indexOf(element.dial_code) === 0) {
          return element.dial_code
        }
      }

      // Handle normal input
      return element.dial_code === e
    })

    if (found) {
      this.selectedDialCode = found.dial_code

      // Add space after dialcode
      let newValue
      if (event.inputType === 'deleteContentBackward' && e === found.dial_code) {
        newValue = e.replace(found.dial_code, this.selectedDialCode)
      } else {
        newValue = e.split(' ').join('').replace(found.dial_code, `${this.selectedDialCode} `)
      }
      this.customerForm.controls.telephone.setValue(newValue)
      this.selectedFlag = found.code.toLowerCase()
    } else {
      this.selectedFlag = 'none'
    }
  }

  /**
   * Toggle flag dropdown list
   */
  toggleDropDown(): void {
    this.show = !this.show
    this.filterValue = ''
    this.current = this.el.nativeElement.querySelector('.countryList__item')
    const input = this.el.nativeElement.querySelector('.filterInput')
    setTimeout(() => {
      input.focus()
    }, 10)
  }

  /**
   * Make country dropdown list navigable by up arrow, down arrow, tab and select on enter
   *
   * @param event event the keyup input event
   * @param filterValue the value entered in filter input
   */

  /* eslint-disable  complexity */
  keyup(event: any, filterValue): void {
    const allItems = this.el.nativeElement.querySelectorAll('.countryList__item')
    const { keyCode } = event

    // Handle no value, backspace,
    if (filterValue.length <= 1 && keyCode === 8) {
      allItems.forEach((element: any) => {
        if (element.classList.contains('countryList__item--selected')) {
          element.classList.remove('countryList__item--selected')
        }
      })

      return
    }

    if (keyCode !== 40 && keyCode !== 38 && keyCode !== 13 && keyCode !== 16) {
      this.current = this.el.nativeElement.querySelector('.countryList__item')
      if (this.current) {
        this.current.classList.add('countryList__item--selected')
      }
    }

    // querySelectorAll to get element as a nodelist
    const selected = this.el.nativeElement.querySelectorAll('.countryList__item--selected')

    // Remove any excess selected classes
    allItems.forEach((element: any) => {
      if (element.classList.contains('countryList__item--selected')) {
        element.classList.remove('countryList__item--selected')
      }
    })

    // Down key
    if (keyCode === 40) {
      event.preventDefault()
      this.current =
        !selected.length || selected[0].nextElementSibling === null ? allItems[0] : selected[0].nextElementSibling

      // Up key
    } else if (keyCode === 38) {
      event.preventDefault()
      this.current =
        !selected.length || selected[0].previousElementSibling === null
          ? allItems[allItems.length - 1]
          : selected[0].previousElementSibling
    }

    // When typing add selected class to current country and scroll into view
    if (keyCode !== 13 && this.current) {
      this.current.classList.add('countryList__item--selected')
    }

    // On enter select country and put focus in phone input field
    if (keyCode === 13) {
      this.current.click()
      const input = this.el.nativeElement.querySelector('.phoneInput')
      input.focus()
    }
  }

  /**
   * On click enable telephone input field
   */
  editPhoneNumber(): void {
    this.customerForm.controls.telephone.enable()
    this.phoneIsDisabled = false
  }

  /**
   * Add selected class when navigating the filter via tab-key
   *
   * @param event event focus event
   */
  addSelectedClass(event: any): void {
    const allItems = this.el.nativeElement.querySelectorAll('.countryList__item')
    allItems.forEach((element: any) => {
      if (element.classList.contains('countryList__item--selected')) {
        element.classList.remove('countryList__item--selected')
      }
    })
    event.target.classList.add('countryList__item--selected')
  }

  /**
   * Trigger click via enter-key when li is focused
   *
   * @param event event keydown
   */
  triggerClick(event: any): void {
    // Add if up or down arrow put focus in input field
    if (event.keyCode === 38 || event.keyCode === 40) {
      const input = this.el.nativeElement.querySelector('.filterInput')
      input.focus()
    }

    if (event.keyCode !== 13) {
      return
    }

    event.target.click()
  }
}
