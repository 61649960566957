export default class LanguageOption {
  nativeLanguage: string

  alternativeLanguage: string

  nativeCountryShort: string

  alternativeCountryShort: string

  nativeLanguageShort: string

  alternativeLanguageShort: string
}
