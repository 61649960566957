import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import CountryComponent from '../../core/country/country.component'
import LocalStorageService from '../../core/local-storage.service'
import Driver from '../../core/models/driver'
import TranslateService from '../../core/translate.service'

@Component({
  selector: 'app-confirmation-customer-details',
  templateUrl: './confirmation-customer-details.component.html',
  styleUrls: ['./confirmation-customer-details.component.scss'],
})
export default class ConfirmationCustomerDetailsComponent extends CountryComponent implements OnInit {
  @Input() customer: Driver

  @Input() licenseNumber: string

  constructor(
    private router: Router,
    private translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    super(localStorageService)
  }

  ngOnInit(): void {
    if (this.customer) {
      this.licenseNumber = this.licenseNumber.replace(/\d{5}$/, '*****')
    }
  }

  getDateFormat(): string {
    if (this.translate.currentLocaleOrDefault === 'fi-FI') {
      return 'Do MMMM[ta] YYYY'
    }
    if (this.translate.currentLocaleOrDefault.includes('en')) {
      return 'MMM D, YYYY'
    }
    return 'D MMMM YYYY'
  }

  currentLocaleIsEnFI(): boolean {
    return this.translate.currentLocaleOrDefault === 'en-FI'
  }

  driverCountryAndLicenseCountryFIFI(): boolean {
    const country = this.localStorageService.getDriver()?.countryCode || 'NO'
    const driverLicenseCountry = this.localStorageService.getDriver()?.driverLicense.licenseIssueLocation || 'NO'

    return country?.toUpperCase() === 'FI' && driverLicenseCountry?.toUpperCase() === 'FI'
  }

  navigateToDriverDetails(): void {
    this.router.navigate(['/driver-details'], { queryParams: { edit: 'edit' } })
  }
}
