import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import * as moment from 'moment'

import CountryComponent from '../core/country/country.component'
import LocalStorageService from '../core/local-storage.service'
import Booking from '../core/models/booking'
import LanguageOption from '../core/models/language-option'
import TranslateService from '../core/translate.service'
import CreditCardRegistrationService from '../credit-card-registration/credit-card-registration.services'

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export default class ConfirmationComponent extends CountryComponent implements OnInit {
  booking: Booking
  skipInsurances: boolean
  skipPayment: boolean
  languageOptions: LanguageOption

  constructor(
    private router: Router,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private creditCardRegistrationService: CreditCardRegistrationService
  ) {
    super(localStorageService)
  }

  ngOnInit(): void {
    this.localStorageService.removePaymentState()

    this.creditCardRegistrationService.setShowCreditCardNeeded(false)

    this.booking = this.localStorageService.getBooking()
    if (!this.booking) {
      this.router.navigate([])
    }
    this.translate.updateMomentLocale()
    const locale = this.translate.currentLocaleOrDefault
    moment.locale(locale)

    this.skipInsurances = this.localStorageService.getSkipInsurances()
    this.skipPayment = this.localStorageService.getSkipPayment()
    this.languageOptions = this.getLangOptions()
  }

  /**
   * Get selected language or default
   */
  getSelectedLang(): string {
    return this.translate.currentLocaleOrDefault
  }
}
