import { Pipe, PipeTransform } from '@angular/core'

import environment from '../../environments/environment'
import TranslateService from './translate.service'

@Pipe({
  name: 'translate',
  pure: false,
})
export default class TranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(key: any): any {
    if (environment.production) {
      return this.translate.data[key] || ''
    }

    return this.translate.data[key] || '{Translation missing}'
  }
}
