<button class="hamburger" (click)="handleOpen()">
  <span class="hamburger__beef"></span>
  <span class="visuallyhidden">{{ 'menu' | translate }}</span>
</button>

<div id="mobileNavOverlay" class="mobileNav__overlay" (click)="handleClose()">
  <nav id="mobileNav" class="mobileNav" role="navigation" (click)="$event.stopPropagation()">
    <button class="mobileNav__close" (click)="handleClose()">
      <span class="visuallyhidden">{{ 'close' | translate }}</span>
    </button>

    <div class="mobileNav__langPicker" (click)="handleClose()">
      <app-change-language></app-change-language>
    </div>

    <ul class="mobileNav__items">
      <li class="mobileNav__item">
        <a class="navigation__mobile__link" [routerLink]="['/my-booking']" (click)="handleClose()">
          {{ 'my-booking' | translate }}
        </a>
      </li>
      <li class="mobileNav__item">
        <a class="navigation__mobile__link mobileNav__helpLnk" [routerLink]="['/help']" (click)="handleClose()">
          {{ 'help' | translate }}
        </a>
      </li>
      <li class="mobileNav__item">
        <a
          *ngIf="finland()"
          class="navigation__mobile__link {{ privacyPolicyLinkClassName }} menu-{{ privacyPolicyLinkClassName }}"
          href="//www.hertz.fi/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp"
          target="_blank"
          (click)="handleClose()"
        >
          {{ 'privacy-policy' | translate }}
        </a>
        <a
          *ngIf="sweden()"
          class="navigation__mobile__link {{ privacyPolicyLinkClassName }} menu-{{ privacyPolicyLinkClassName }}"
          href="//www.hertz.se/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp"
          target="_blank"
          (click)="handleClose()"
        >
          {{ 'privacy-policy' | translate }}
        </a>
        <a
          *ngIf="norway()"
          class="navigation__mobile__link {{ privacyPolicyLinkClassName }} menu-{{ privacyPolicyLinkClassName }}"
          href="//www.hertz.com/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp"
          target="_blank"
          (click)="handleClose()"
        >
          {{ 'privacy-policy' | translate }}
        </a>
        <a
          *ngIf="denmark()"
          class="navigation__mobile__link {{ privacyPolicyLinkClassName }} menu-{{ privacyPolicyLinkClassName }}"
          href="//www.hertzdk.dk/rentacar/navigation/templates/legalView.jsp"
          target="_blank"
          (click)="handleClose()"
        >
          {{ 'privacy-policy' | translate }}
        </a>
      </li>
    </ul>
  </nav>
</div>
