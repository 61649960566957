import { Component } from '@angular/core'

import { PRIVACY_POLICY_LINK_CLASSNAME } from 'src/constants/gtmSelectors'

import CountryComponent from '../country/country.component'
import LocalStorageService from '../local-storage.service'

@Component({
  selector: 'app-desktop-navigation',
  templateUrl: './desktop-navigation.component.html',
  styleUrls: ['./desktop-navigation.component.scss'],
})
export default class DesktopNavigationComponent extends CountryComponent {
  privacyPolicyLinkClassName = PRIVACY_POLICY_LINK_CLASSNAME

  // eslint-disable-next-line
  constructor(localStorageService: LocalStorageService) {
    super(localStorageService)
  }
}
