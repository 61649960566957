import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import { Subscription } from 'rxjs'

import { INFO_BUTTON_OR_LINK_CLASSNAME } from '../../../constants/gtmSelectors'
import BookingService from '../../core/booking.service'
import LocalStorageService from '../../core/local-storage.service'
import Driver from '../../core/models/driver'
import TranslateService from '../../core/translate.service'
import MessagesService from '../../shared/messages.service'
import ModalService from '../../shared/modal/modal.service'
import phoneNumberValidator from '../phone-number-validator'

@Component({
  selector: 'app-driver-details-autofill-no',
  templateUrl: './driver-details-autofill-no.component.html',
  styleUrls: ['./driver-details-autofill-no.component.scss'],
})
export default class DriverDetailsAutofillNoComponent implements OnInit, OnDestroy {
  @Output() readonly autoFill = new EventEmitter<Driver>()

  autoFillForm: UntypedFormGroup

  message: string

  subscription: Subscription

  infoButtonOrLinkClassName = INFO_BUTTON_OR_LINK_CLASSNAME

  constructor(
    private service: BookingService,
    private fb: UntypedFormBuilder,
    private modalService: ModalService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private messageService: MessagesService
  ) {
    this.subscription = this.messageService.messages.subscribe((message) => {
      this.message = message
    })
  }
  get phone(): AbstractControl {
    return this.autoFillForm.get('phone')
  }
  ngOnInit(): void {
    this.autoFillForm = this.fb.group({
      phone: ['', [phoneNumberValidator, Validators.required]],
    })

    this.autoFillForm.get('phone').setValue('')

    this.message = undefined
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  /**
   * Run the auto fill info on submit, and try to get driver information
   *
   * @param e event
   */
  getAutoFill(): void {
    if (!this.autoFillForm.valid) {
      return
    }

    // Check if timestamp is older than 30 minutes (1800000 milliseconds)
    const timestamp = this.localStorageService.getQuickFillTimeStamp()
    const now = Date.now()
    const timeoutIsDone = now - parseInt(timestamp, 10) >= 1800000

    // Show error if timeout isn't done
    if (!timeoutIsDone && timestamp) {
      this.message = 'error-too-many-tries'
      this.openModal('autofill-error-modal')
    }

    // Run autofill if timeout is done or if there is no timestamp in localStorage
    if (timeoutIsDone || !timestamp) {
      this.service.getCustomerForAutoCompleteNorway(this.phone.value).subscribe((data: Driver) => {
        const driver = data as Driver
        this.autoFill.emit(driver)
        this.service.sendAutoFillEvent('autofill')
        this.message = undefined
      })
    }
  }

  getTranslationArray(key: string): Array<string> {
    return this.translate.getTranslationArray(key)
  }

  /**
   * Opens modal by given id
   *
   * @param id modal id
   */
  openModal(id: string): void {
    this.modalService.open(id)
  }

  /**
   * Closes modal by given id
   *
   * @param id modal id
   */
  closeModal(id: string): void {
    this.modalService.close(id)
  }
}
