import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import CoreModule from '../core/core.module'
import SharedModule from '../shared/shared.module'
import CreditCardNeededComponent from './credit-card-needed/credit-card-needed.component'
import CreditCardRegisteredComponent from './credit-card-registered/credit-card-registered.component'
import CreditCardRegistrationRoutingModule from './credit-card-registration-routing.module'
import CreditCardRegistrationComponent from './credit-card-registration.component'

@NgModule({
  imports: [CommonModule, CreditCardRegistrationRoutingModule, CoreModule, SharedModule],
  declarations: [CreditCardRegistrationComponent, CreditCardNeededComponent, CreditCardRegisteredComponent],
  exports: [CreditCardRegistrationComponent],
})
export default class CreditCardRegistrationModule {}
