import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export default class CreditCardRegistrationService {
  showCreditCardNeeded = new BehaviorSubject<boolean>(false)

  setShowCreditCardNeeded(show: boolean): void {
    this.showCreditCardNeeded.next(show)
  }
}
