import { Location } from '@angular/common'
import { Component } from '@angular/core'

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export default class HelpComponent {
  constructor(private location: Location) {}

  goBack(): void {
    this.location.back()
  }
}
