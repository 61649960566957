import { Injectable } from '@angular/core'

import Booking from './models/booking'
import Country from './models/country'
import CreditCard from './models/credit-card'
import Driver from './models/driver'
import Insurance from './models/insurance'

@Injectable({
  providedIn: 'root',
})
export default class LocalStorageService {
  /**
   * Get saved booking from local storage
   */
  getBooking(): Booking {
    return JSON.parse(localStorage.getItem('booking'))
  }

  /**
   * Get saved driver from local storage
   */
  getDriver(): Driver {
    if (this.getBooking()) {
      return this.getBooking().reservation.driver
    }
    return undefined
  }

  /**
   * Get saved insurances from local storage
   */
  getInsurances(): Insurance {
    if (this.getBooking()) {
      return this.getBooking().reservation.insurances
    }
    return undefined
  }

  getLiability(): boolean {
    if (this.getBooking()) {
      return this.getBooking().reservation.liability
    }
    return undefined
  }

  getSpecialLiability(): boolean {
    if (this.getBooking()) {
      return this.getBooking().reservation.specialLiability
    }
    return undefined
  }

  /**
   * Get orderRef from local storage
   */
  getOrderRef(): string {
    let orderRef
    if (this.getBooking()) {
      orderRef = this.getBooking().reservation.paymentDetails.orderReference
    }

    return orderRef
  }

  /**
   * Get getPaymentState from local storage when user comes back from the page
   * where they are confirming card details so OC can render the same payex script
   * on their return
   */
  getPaymentState() {
    return JSON.parse(localStorage.getItem('paymentState'))
  }

  /**
   * Save paymentState to localStorage while user is redirected momentarily to
   * another page to confirm card details with bankId.
   */
  setPaymentState(paymentData: { viewPaymentUrl: string; orderRef: string }) {
    localStorage.setItem('paymentState', JSON.stringify(paymentData))
  }

  /**
   * Remove paymentState from localStorage when payment has been completed
   */
  removePaymentState() {
    localStorage.removeItem('paymentState')
  }

  /**
   * Get token from local storage
   */
  getToken(): string {
    return localStorage.getItem('token')
  }

  /**
   * Get saved locale from local storage
   */
  getLocale(): string {
    return localStorage.getItem('locale')
  }

  /**
   * Get the selected country set in application from local storage
   */
  getCurrentCountry(): Country {
    return Country[localStorage.getItem('country')]

    // TODO: return default if no value
  }

  /**
   * Get the selected country as a string
   */
  getCurrentCountryAsString(): string {
    return localStorage.getItem('country')

    // TODO: return default if no value
  }

  /**
   * Get credit card from local storage
   */
  getCreditCard(): CreditCard {
    if (this.getBooking()) {
      return this.getBooking().reservation.paymentDetails.creditCard
    }
    return undefined
  }

  /**
   * Get quickfill "too many tries"- timestamp from local storage
   */
  getQuickFillTimeStamp(): string {
    return localStorage.getItem('quickFillTimeStamp')
  }

  /**
   * Gets skipPayment information from localStorage
   */
  getSkipPayment(): boolean {
    return localStorage.getItem('skipPayment') === 'true'
  }

  /**
   * Gets TimeZone from localStorage
   */
  getTimeZone(): string {
    return localStorage.getItem('timeZone')
  }

  /**
   * Gets ThemeName from localStorage
   */
  getThemeName(): string {
    return localStorage.getItem('themeName')
  }

  /**
   * Save ThemeName from localStorage
   */
  saveThemeName(themeName: string): void {
    localStorage.removeItem('themeName')
    localStorage.setItem('themeName', themeName)
  }

  /**
   * Save booking info to local storage
   *
   * @param booking The booking to be saved
   */
  saveBooking(booking: Booking): void {
    localStorage.removeItem('booking')
    localStorage.removeItem('locale')
    localStorage.removeItem('country')
    localStorage.removeItem('token')
    localStorage.removeItem('timeZone')
    localStorage.setItem('booking', JSON.stringify(booking))
  }

  // Reset booking info from localstorage
  resetStep(step) {
    localStorage.removeItem(step)
  }

  /**
   * Save driver info to local storage
   *
   * @param driver The driver info to be saved
   */
  saveDriver(driver: Driver): void {
    const booking = this.getBooking()
    booking.reservation.driver = driver

    localStorage.setItem('booking', JSON.stringify(booking))
  }

  /**
   * Save insurance info to local storage
   *
   * @param insurances The insurance info to be saved
   */
  saveInsurances(insurances: Insurance): void {
    const booking = this.getBooking()
    booking.reservation.insurances = insurances

    localStorage.setItem('booking', JSON.stringify(booking))
  }

  saveTimeZone(timeZone: string): void {
    localStorage.setItem('timeZone', timeZone)
  }

  /**
   * Check if there is insurances on reservation
   * If not then skipInsurances returns true
   */
  getSkipInsurances(): boolean {
    return !!this.getInsurances() && Object.keys(this.getInsurances()).length === 0
  }

  // /**
  //  * Save liability info to local storage
  //  * @param reservation The insurance info to be saved
  //  */
  // saveLiability(reservation: Reservation): void {
  //   const booking = this.getBooking();
  //   booking.reservation.liability = reservation.liability;
  //
  //   localStorage.setItem('booking', JSON.stringify(booking));
  // }

  /**
   * Save orderRef to local storage
   *
   * @param orderRef The orderRef to be saved
   */
  saveOrderRef(orderRef: string): void {
    const booking = this.getBooking()
    booking.reservation.paymentDetails.orderReference = orderRef
    localStorage.setItem('booking', JSON.stringify(booking))
  }

  /**
   * Remove the orderRef from local storage
   */
  removeOrderRef(): void {
    const booking = this.getBooking()
    delete booking.reservation.paymentDetails.orderReference
    localStorage.setItem('booking', JSON.stringify(booking))
  }

  /**
   * Saves token to local storage
   *
   * @param token The token to be saved
   */
  saveToken(token: string): void {
    localStorage.setItem('token', token)
  }

  /**
   * Saves locale to local storage
   *
   * @param locale The locale to be saved
   */
  saveCurrentLocale(locale: string): void {
    localStorage.setItem('locale', locale)
  }

  /**
   * Saves country used in application to local storage
   *
   * @param data The data set from which Country is extracted
   */
  saveAppCountry(c: string): any {
    const country = this.mapStringToCountry(c)
    localStorage.setItem('country', Country[country])
  }

  /**
   * Set quickfill timeout timestamp with current time in milliseconds
   */
  saveQuickFillTimeStamp(): void {
    localStorage.setItem('quickFillTimeStamp', Date.now().toString())
  }

  /**
   * Saves cradit card inforation to the existing resercation in local storage
   *
   * @param creditCard The credit card information to be saved
   */
  saveCreditCard(creditCard: CreditCard): any {
    const booking = this.getBooking()
    booking.reservation.paymentDetails.creditCard = creditCard
    localStorage.setItem('booking', JSON.stringify(booking))
  }

  /**
   * Saves skipPayment information to localStorage
   *
   * @param skipPayment skipPayment from booking true if hertz charge card (HCC) is available
   */
  saveSkipPayment(skipPayment: string): void {
    localStorage.setItem('skipPayment', skipPayment)
  }

  /**
   * Maps a country string to enum
   *
   * @param country The string to be mapped to enum
   */
  mapStringToCountry(country: string): Country {
    switch (country) {
      case 'SWEDEN':
        return Country.Sweden
      case 'NORWAY':
        return Country.Norway
      case 'FINLAND':
        return Country.Finland
      case 'DENMARK':
        return Country.Denmark
      default:
        return Country.Sweden
    }
  }

  /**
   * Get status for x-token-header, if this is set the users token is stored in oc-db
   */
  getXTokenHeader(): boolean {
    // Can only stor string
    return localStorage.getItem('x-token-header') === 'true'
  }

  /**
   * Set if x-token-header should be used
   */
  setXTokenHeader(value: boolean) {
    // Can only stor string
    localStorage.setItem('x-token-header', value.toString())
  }

  setStepValid(step: string) {
    localStorage.setItem(step, 'true')
  }

  getStepValid(step: string) {
    return localStorage.getItem(step) === 'true'
  }
}
