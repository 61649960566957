/* eslint-disable no-underscore-dangle */
import { BreakpointObserver } from '@angular/cdk/layout'
import { Component, OnInit } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'
import { Subscription } from 'rxjs'

import { INFO_BUTTON_OR_LINK_CLASSNAME } from '../../constants/gtmSelectors'
import BookingService from '../core/booking.service'
import CountryComponent from '../core/country/country.component'
import LocalStorageService from '../core/local-storage.service'
import Driver from '../core/models/driver'
import LanguageOption from '../core/models/language-option'
import TranslateService from '../core/translate.service'
import ModalService from '../shared/modal/modal.service'
import ChangeCountryService from './country-change-service'
import ChangeDueDateErrorService from './due-date-error-change-service'
import SSnService from './ssn-change-service'
import { isFinnishLicenseNumberValid } from './ssn-validator-fi'
import { isNorwegianLicenseNumberValid } from './ssn-validator-no'
import { isSwedishLicenseNumberValid } from './ssn-validator-se'

/**
 * Gets the value from autofill if any, otherwise from previously stored value in app
 *
 * @param autoFillValue The value from autofill
 * @param storedValue The value stored in the app
 */
const getValueFromAutoFillOrDefault = (autoFillValue: any, storedValue: any): any => {
  return autoFillValue || storedValue
}
/**
 * Marks all controls in a form group as touched, so errors will be displayed if the controls are invalid
 *
 * @param formGroup - The form group to touch
 */
const markFormGroupTouched = (formGroup: UntypedFormGroup): void => {
  ;(Object as any).values(formGroup.controls).forEach((control) => {
    control.markAsTouched()

    if (control.controls) {
      markFormGroupTouched(control)
    }
  })
}

/**
 * checks if a date is a valid date
 *
 * @param d date to check
 * @returns true if valid else false
 */
const isValidDate = (d: any): boolean => {
  return d instanceof Date && !Number.isNaN(d.getTime())
}

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss'],
})
export default class DriverDetailsComponent extends CountryComponent implements OnInit {
  emailIsDisabled: boolean

  selectedCountry: string
  selectedIssuingCountry: string

  selectedFlag: string

  sortedCountries: Record<string, unknown>

  customerForm: UntypedFormGroup

  placeholder = this.translate.getTranslation('personal-identity-number-placeholder')

  r: Subscription

  autoFillSubscription: Subscription

  driverLicenseNumber: string

  driverLicenseHasBeenChanged: boolean

  driverLicenseHarDueDateError: boolean

  languageOptions: LanguageOption

  updateDriverLicenseValidatorSubscription: Subscription

  submitActionActive: boolean

  infoButtonOrLinkClassName = INFO_BUTTON_OR_LINK_CLASSNAME

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private service: BookingService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private localStorageService: LocalStorageService,
    private changeCountryService: ChangeCountryService,
    private changeDueDateErrorService: ChangeDueDateErrorService,
    private ssnService: SSnService
  ) {
    super(localStorageService)
  }

  get firstName(): AbstractControl {
    return this.customerForm.get('firstName')
  }

  get lastName(): AbstractControl {
    return this.customerForm.get('lastName')
  }

  get address(): AbstractControl {
    return this.customerForm.get('address')
  }

  get postCode(): AbstractControl {
    return this.customerForm.get('postCode')
  }

  get city(): AbstractControl {
    return this.customerForm.get('city')
  }

  get countryCode(): AbstractControl {
    return this.customerForm.get('countryCode')
  }

  get telephone(): AbstractControl {
    return this.customerForm.get('telephone')
  }

  get emailAddress(): AbstractControl {
    return this.customerForm.get('emailAddress')
  }

  get licenseNumber(): AbstractControl {
    return this.customerForm.get('driverLicense.licenseNumber')
  }

  get licenseIssueDate(): AbstractControl {
    return this.customerForm.get('driverLicense.licenseIssueDate')
  }

  get licenseExpireDate(): AbstractControl {
    return this.customerForm.get('driverLicense.licenseExpireDate')
  }

  get licenseBirthDate(): AbstractControl {
    return this.customerForm.get('driverLicense.licenseBirthDate')
  }

  get licenseIssueLocation(): AbstractControl {
    return this.customerForm.get('driverLicense.licenseIssueLocation')
  }

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 767px)')
  }

  ngOnInit(): void {
    const driver = this.localStorageService.getDriver()
    if (driver && driver.countryCode) {
      this.selectedFlag = driver.countryCode.toLowerCase()
    }

    if (!driver) {
      this.router.navigate([])
    } else {
      this.driverLicenseNumber = driver.driverLicense?.licenseNumber

      this.customerForm = this.fb.group({
        firstName: [driver.firstName, Validators.required],
        lastName: [driver.lastName, Validators.required],
        address: [driver.address, Validators.required],
        postCode: [driver.postCode, Validators.required],
        city: [driver.city, Validators.required],
        countryCode: [driver.countryCode, Validators.required],
        telephone: [driver.telephone, Validators.required],
        emailAddress: [driver.emailAddress, [Validators.email, Validators.required]],
        driverLicense: this.getDriverLicenseInputGroup(driver),
      })

      this.enableEmailIfNoInitValue()

      this.selectedCountry = driver.countryCode ? driver.countryCode.toUpperCase() : 'SE'
      this.selectedIssuingCountry = driver.driverLicense.licenseIssueLocation
        ? driver.driverLicense.licenseIssueLocation?.toUpperCase()
        : this.selectedCountry

      this.setBirthDateFromSsnOnStartup()
    }
    this.updateValidators()

    this.changeCountryService.newSelectedCountry.subscribe((res) => {
      this.selectedIssuingCountry = res.countryCode.toUpperCase()
      this.selectedCountry = res.countryCode.toUpperCase()
      this.updateValidators()
    })
    this.changeCountryService.newSelectedIssuingCountry.subscribe((res) => {
      this.selectedIssuingCountry = res.countryCode.toUpperCase()
      this.updateValidators()

      const ssn = this.customerForm.controls.driverLicense.get('licenseNumber')
      if (ssn?.value) {
        this.setBirthDateFromSsnOnStartup()
      }
    })
    this.changeDueDateErrorService.newDueDateError.subscribe((res) => {
      this.driverLicenseHarDueDateError = res.hasError
    })

    this.languageOptions = this.getLangOptions()
  }

  updateValidators() {
    this.customerForm?.controls.driverLicense.get('licenseNumber').setValidators(this.isSsnValid())
    this.customerForm?.controls.driverLicense.get('licenseNumber').updateValueAndValidity()

    if (this.driverCountryAndLicenseCountryFIFI()) {
      this.emptyValidatorsForFIFI()
    } else {
      this.fillValidators()
    }
  }

  emptyValidatorsForFIFI() {
    this.customerForm?.controls.driverLicense.patchValue({
      licenseIssueDate: '',
      licenseExpireDate: '',
    })

    this.customerForm?.controls.driverLicense.get('licenseIssueDate').removeValidators(Validators.required)
    this.customerForm?.controls.driverLicense.get('licenseIssueDate').updateValueAndValidity()
    this.customerForm?.controls.driverLicense.get('licenseExpireDate').removeValidators(Validators.required)
    this.customerForm?.controls.driverLicense.get('licenseExpireDate').updateValueAndValidity()
  }

  fillValidators() {
    this.customerForm?.controls.driverLicense.get('licenseIssueDate').setValidators(Validators.required)
    this.customerForm?.controls.driverLicense.get('licenseIssueDate').updateValueAndValidity()
    this.customerForm?.controls.driverLicense.get('licenseExpireDate').setValidators(Validators.required)
    this.customerForm?.controls.driverLicense.get('licenseExpireDate').updateValueAndValidity()
  }

  currentLocaleIsEnFI(): boolean {
    return this.translate.currentLocaleOrDefault === 'en-FI'
  }

  driverCountryAndLicenseCountryFIFI(): boolean {
    return this.selectedIssuingCountry === 'FI' && this.selectedCountry === 'FI'
  }

  /**
   * Triggered when the autofillcomponent emits an event saying autofill has been clicked
   *
   * @param driver the driver details recieved from autofill
   */
  onAutofill(driver: Driver): void {
    this.updateFormFromAutofill(driver)
  }

  /**
   * Enable email field on edit-email-button click
   */
  editEmail(): void {
    this.customerForm.controls.emailAddress.enable()
    this.emailIsDisabled = false
  }

  /**
   * Gets an array of keys to easily loop in template
   *
   * @param key the translation key to get from
   */
  getTranslationArray(key: string): Array<string> {
    return this.translate.getTranslationArray(key)
  }

  /**
   * Open a modal, in this case the help modal with driving license info
   *
   * @param id The id of the modal to be open
   */
  openModal(id: string): void {
    this.modalService.open(id)
  }

  /**
   * Close a modal with a specified id
   *
   * @param id The id of the modal to be closed
   */
  closeModal(id: string): void {
    this.modalService.close(id)
  }

  /**
   * Get selected language or default
   */
  getSelectedLang(): string {
    return this.translate.currentLocaleOrDefault
  }

  /**
   * Checks if a control in the form is required
   *
   * @param controlName the name oc the control to be checked if set to required
   */
  formControlIsRequired(controlName: string): boolean {
    const control = this.customerForm.get(controlName)
    if (control.validator) {
      const validator = control.validator({} as AbstractControl)
      if (validator && validator.required) {
        return true
      }
    }
    return undefined
  }

  /**
   * Triggered when user presses the button "Continue". If the form is valid, the user will be navigated to the credit card page.
   * If the user has navigated to the page from the confirmation view, the user will be sent back to the confirmation view when the continue button is pressed.
   */
  onSubmit(): void {
    this.submitActionActive = true

    const licenseNumber = this.customerForm.controls.driverLicense.get('licenseNumber')

    if (licenseNumber.value.includes('*****') && !licenseNumber.touched) {
      licenseNumber.setValue(this.localStorageService.getDriver().driverLicense.licenseNumber, {
        emitModelToViewChange: false,
      })
    }

    markFormGroupTouched(this.customerForm)
    if (this.customerForm.valid) {
      // Trim whitespace from phone field
      const phoneValue = this.customerForm.controls.telephone.value.split(' ').join('')
      this.customerForm.controls.telephone.setValue(phoneValue)

      // Enable fields before submit
      this.customerForm.controls.telephone.enable()
      this.customerForm.controls.emailAddress.enable()
      const formValues = this.customerForm.value as Driver
      this.localStorageService.saveDriver(formValues)

      if (this.route.snapshot.queryParams.edit === 'edit' || this.localStorageService.getSkipPayment()) {
        this.confirmCheckIn()
      } else {
        this.router.navigate(['/credit-card'])
        this.localStorageService.setStepValid('/credit-card')
      }
    }
  }

  /**
   * PUT booking information to backend and navigate to confirmation page on click
   */
  confirmCheckIn(): void {
    const callback = () => {
      this.localStorageService.setStepValid('/confirmation')
      this.router.navigate(['/confirmation'])
    }
    this.service.putBooking(callback.bind(this))
  }

  isDateExpiredFuture(date: Date): boolean {
    const nowdate = new Date()
    const diff = date.getTime() - nowdate.getTime()
    if (diff < 0) {
      return true
    }
    return false
  }

  isDateExpiredPast(date: Date): boolean {
    const nowdate = new Date()
    const diff = date.getTime() - nowdate.getTime()
    if (diff > 0) {
      return true
    }
    return false
  }

  isDateBirthDatePlus120(date: Date, birthDate: Date): boolean {
    const maxDate = new Date(birthDate)
    maxDate.setFullYear(maxDate.getFullYear() + 120)

    return date <= maxDate
  }

  isAgeOver18(date: Date): boolean {
    const today = new Date()
    const diff = today.getTime() - date.getTime()
    const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25))

    return age >= 18
  }

  licenceNumberkeyup(value: any): void {
    if (value !== this.driverLicenseNumber && !this.driverLicenseHasBeenChanged) {
      this.customerForm.controls.driverLicense.get('licenseNumber').setValidators(this.isSsnValid())
      this.customerForm.controls.driverLicense.get('licenseNumber').updateValueAndValidity()
      this.driverLicenseHasBeenChanged = true
    }

    this.setBirthDateFromSsn()
  }

  /**
   * Event handler for the licenseBirthDate input
   * Makes sure that licenseBirthDate isn't a future date
   *
   * @param _event mat-datepicker dateChange event
   */
  onLicenseBirthDateChange(_event: any): void {
    if (this.licenseBirthDate.value != null) {
      const nowdate = new Date()

      if (isValidDate(this.licenseBirthDate.value._d)) {
        const diff = (this.licenseBirthDate.value._d as Date).getTime() - nowdate.getTime()
        if (diff > 0) {
          this.licenseBirthDate.setErrors({
            expired: true,
          })
        }
      }
    }
  }

  /**
   * keyup event handler for licenseNumber (only for norway)
   * Hvis førerkortnummer er 11 siffer og pos 1-6 har et gyldig datoformat i formatet DDMMYY og fører er > 18 år,
   * benytt dette som fødselsdato automatisk. Dersom dette ikke lar seg validere,
   * i Ebook, så tennes fødselsdato feltet som et mandatory felt ved Check-out.
   * I OC så settes fødselsdato som forhånds utfylt dersom feltene valideres, men er mulig å endre.
   *
   * @param _event standard angular keyup event
   */
  private onLicenseNumberNorway(): void {
    if (this.licenseNumber.valid && this.licenseNumber.value.length === 11) {
      const bdatestr = this.licenseNumber.value.substring(0, 6)
      const individNumber = +this.licenseNumber.value.substring(6, 9)

      // 'logic from https://www.skatteetaten.no/en/person/national-registry/birth-and-name-selection/children-born-in-norway/national-id-number/
      let year = +(bdatestr[4] + bdatestr[5])

      // born 1900-1999: allocated from series 000-499, born 1940-1999: also allocated from series 900-999
      if (
        (individNumber >= 0 && individNumber <= 499) ||
        (individNumber >= 900 && individNumber <= 999 && year >= 40)
      ) {
        year += 1900
        // born 2000-2039: allocated from series 500-999
      } else if (individNumber >= 500 && individNumber <= 999) {
        year += 2000
      }

      const month = +(bdatestr[2] + bdatestr[3])
      const day = +(bdatestr[0] + bdatestr[1])
      const bdate = new Date(year, month - 1, day)

      /**
       * Check if birth date is a valid date
       */
      const b = `${day}-${month}-${year}`
      const m = moment(b, 'DD-MM-YYYY')

      if (isValidDate(bdate) && this.isAgeOver18(bdate) && m.isValid()) {
        this.setDateIfEmpty(bdate)
      }
    }
  }

  /**
   * FINLAND
   * Change birth date if calid ssn input
   */
  private onLicenseNumberFinland(): void {
    if (this.licenseNumber.valid) {
      let bdatestr = this.licenseNumber.value.substring(0, 6)
      let year = this.prefixCenturyYear(Number(bdatestr[4] + bdatestr[5]))

      if (this.licenseNumber.value.length === 13) {
        bdatestr = this.licenseNumber.value.substring(0, 8)
        year = +(bdatestr[4] + bdatestr[5] + bdatestr[6] + bdatestr[7])
      }

      const month = +(bdatestr[2] + bdatestr[3])
      const day = +(bdatestr[0] + bdatestr[1])
      const bdate = new Date(year, month - 1, day)

      /**
       * Check if birth date is a valid date
       */
      const b = `${day}-${month}-${year}`
      const m = moment(b, 'DD-MM-YYYY')

      if (isValidDate(bdate) && this.isAgeOver18(bdate) && m.isValid()) {
        this.setDateIfEmpty(bdate)
      }
    }
  }

  private setDateIfEmpty(bdate: Date) {
    this.licenseBirthDate.setValue(bdate)
    this.ssnService.newSsn.next(bdate)
  }

  /**
   * SWEDEN
   * Change birth date if calid ssn input
   */
  private onLicenseNumberSweden(): void {
    if (this.licenseNumber.valid) {
      const ssn = this.licenseNumber.value

      let bdatestr = ssn.substring(0, 6)
      let year = this.prefixCenturyYear(Number(bdatestr[0] + bdatestr[1]))
      let month = +(bdatestr[2] + bdatestr[3])
      let day = +(bdatestr[4] + bdatestr[5])

      if (ssn.length === 12) {
        bdatestr = ssn.substring(0, 8)
        year = +(bdatestr[0] + bdatestr[1] + bdatestr[2] + bdatestr[3])
        month = +(bdatestr[4] + bdatestr[5])
        day = +(bdatestr[6] + bdatestr[7])
      }

      const bdate = new Date(year, month - 1, day)

      /**
       * Check if birth date is a valid date
       */
      const b = `${day}-${month}-${year}`
      const m = moment(b, 'DD-MM-YYYY')

      if (isValidDate(bdate) && this.isAgeOver18(bdate) && m.isValid()) {
        this.setDateIfEmpty(bdate)
      }
    }
  }

  private prefixCenturyYear(twoDigitYear: number) {
    if (twoDigitYear < 20) {
      return twoDigitYear + 2000
    }
    return twoDigitYear + 1900
  }

  private setBirthDateFromSsnOnStartup() {
    if (!this.customerForm.controls.driverLicense.get('licenseBirthDate').value) {
      this.setBirthDateFromSsn()
    }
  }

  private setBirthDateFromSsn() {
    // eslint-disable-next-line default-case
    switch (this.selectedIssuingCountry) {
      case 'SE':
        this.onLicenseNumberSweden()
        break
      case 'FI':
        this.onLicenseNumberFinland()
        break
      case 'NO':
        this.onLicenseNumberNorway()
        break
    }
  }

  private getDriverLicenseInputGroup(driver: Driver): UntypedFormGroup {
    const maskedLicenseNumber =
      driver && driver.countryCode && driver.countryCode.toLowerCase() === 'no'
        ? driver.driverLicense.licenseNumber.replace(/\d{5}$/, '*****')
        : driver.driverLicense.licenseNumber

    let driverLicense = this.fb.group({
      licenseNumber: [maskedLicenseNumber, maskedLicenseNumber.includes('*') ? Validators.required : this.isSsnValid()],
      licenseExpireDate: [driver.driverLicense.licenseExpireDate, Validators.required],
      licenseIssueDate: [driver.driverLicense.licenseIssueDate, Validators.required],
      licenseBirthDate: [driver.driverLicense.licenseBirthDate, Validators.required],
      licenseIssueLocation: [driver.driverLicense.licenseIssueLocation || driver.countryCode, Validators.required],
    })

    if (this.finland() && !this.currentLocaleIsEnFI) {
      driverLicense = this.fb.group({
        licenseNumber: [
          driver.driverLicense.licenseNumber,
          maskedLicenseNumber.includes('*') ? Validators.required : this.isSsnValid(),
        ],
        licenseExpireDate: [driver.driverLicense.licenseExpireDate],
        licenseIssueDate: [driver.driverLicense.licenseIssueDate],
        licenseBirthDate: [driver.driverLicense.licenseBirthDate, Validators.required],
        licenseIssueLocation: [driver.driverLicense.licenseIssueLocation, Validators.required],
      })
    }

    return driverLicense
  }

  /**
   * Enable the email input field is there is no initial value for the field, else disable.
   */
  private enableEmailIfNoInitValue(): void {
    if (!this.customerForm.controls.emailAddress.value) {
      this.customerForm.controls.emailAddress.enable()
      this.emailIsDisabled = false
    } else {
      this.customerForm.controls.emailAddress.disable()
      this.emailIsDisabled = true
    }
  }

  /**
   * Updates the values oc customerform from the autofill values if any, or from the values stored in app
   *
   * @param driver the driver details from autofill
   */
  private updateFormFromAutofill(driver: Driver): void {
    const storage = this.localStorageService.getDriver()

    const firstName = getValueFromAutoFillOrDefault(driver.firstName, storage.firstName)
    const lastName = getValueFromAutoFillOrDefault(driver.lastName, storage.lastName)
    const address = getValueFromAutoFillOrDefault(driver.address, storage.address)
    const postCode = getValueFromAutoFillOrDefault(driver.postCode, storage.postCode)
    const city = getValueFromAutoFillOrDefault(driver.city, storage.city)
    const telephone = getValueFromAutoFillOrDefault(driver.telephone, storage.telephone)
    const countryCode = getValueFromAutoFillOrDefault(driver.countryCode, storage.countryCode)

    const emailAddress = getValueFromAutoFillOrDefault(driver.emailAddress, storage.emailAddress)
    const driverLicenseNumber = getValueFromAutoFillOrDefault(
      driver.driverLicense.licenseNumber,
      storage.driverLicense.licenseNumber
    )
    const driverLicenseExpireDate = getValueFromAutoFillOrDefault(
      driver.driverLicense.licenseExpireDate,
      storage.driverLicense.licenseExpireDate
    )
    const driverLicenseIssueDate = getValueFromAutoFillOrDefault(
      driver.driverLicense.licenseIssueDate,
      storage.driverLicense.licenseIssueDate
    )
    const driverLicenseBirthDate = getValueFromAutoFillOrDefault(
      driver.driverLicense.licenseBirthDate,
      storage.driverLicense.licenseBirthDate
    )

    const driverLicenseIssueLocation = getValueFromAutoFillOrDefault(
      driver.driverLicense.licenseIssueLocation,
      storage.driverLicense.licenseIssueLocation
    )

    this.customerForm.controls.firstName.setValue(firstName)
    this.customerForm.controls.lastName.setValue(lastName)
    this.customerForm.controls.address.setValue(address)
    this.customerForm.controls.postCode.setValue(postCode)
    this.customerForm.controls.city.setValue(city)
    this.customerForm.controls.telephone.setValue(telephone)
    this.customerForm.controls.countryCode.setValue(countryCode ? countryCode.toUpperCase() : '')
    this.customerForm.controls.emailAddress.setValue(emailAddress)

    const driverLicense = this.customerForm.controls.driverLicense as UntypedFormGroup
    driverLicense.controls.licenseNumber.setValue(driverLicenseNumber)

    if (driverLicenseExpireDate) {
      const control = this.customerForm.controls.driverLicense.get('licenseExpireDate')
      control.setValue(driverLicenseExpireDate)
      this.ssnService.autoFillEvent.next({ control, controlName: 'licenseExpireDate' })
    }
    if (driverLicenseIssueDate) {
      const control = this.customerForm.controls.driverLicense.get('licenseIssueDate')
      control.setValue(driverLicenseIssueDate)
      this.ssnService.autoFillEvent.next({ control, controlName: 'licenseIssueDate' })
    }

    if (driverLicenseIssueDate) {
      const control = this.customerForm.controls.driverLicense.get('licenseIssueDate')
      control.setValue(driverLicenseIssueDate)
      this.ssnService.autoFillEvent.next({ control, controlName: 'licenseIssueDate' })
    }

    if (driverLicenseBirthDate) {
      const control = this.customerForm.controls.driverLicense.get('licenseBirthDate')
      control.setValue(driverLicenseBirthDate)
      this.ssnService.autoFillEvent.next({ control, controlName: 'licenseBirthDate' })
    }

    driverLicense.controls.licenseIssueLocation.setValue(
      driverLicenseIssueLocation
        ? driverLicenseIssueLocation.toUpperCase()
        : this.customerForm.controls.countryCode.value
    )
  }

  private isSsnValid(): ValidatorFn {
    const driverLicenseCountry =
      this.selectedIssuingCountry || this.localStorageService.getDriver().driverLicense.licenseIssueLocation

    switch (driverLicenseCountry?.toUpperCase()) {
      case 'SE':
        return (control: AbstractControl): { [key: string]: any } | null =>
          isSwedishLicenseNumberValid(control.value) ? null : { wrongSsnFormat: control.value }
      case 'NO':
        return (control: AbstractControl): { [key: string]: any } | null =>
          isNorwegianLicenseNumberValid(control.value) ? null : { wrongSsnFormat: control.value }
      case 'FI':
        return (control: AbstractControl): { [key: string]: any } | null =>
          isFinnishLicenseNumberValid(control.value) ? null : { wrongSsnFormat: control.value }
      default:
        return null
    }
  }
}
