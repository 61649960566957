<footer class="footer">
  <span class="footer-title"> {{ 'footer-title' | translate }} </span>

  <div class="footer-contact-container">
    <div class="footer-contact-block" *ngFor="let block of contactBlocks; let i = index">
      <img src="{{ block.imgSrc || '' }}" alt="" key="i" />
      <p key="i">{{ block.text }}</p>
      <a href="{{ block.link || '' }}" key="i" class="{{ contactLinkClassName }} footer-{{ contactLinkClassName }}">{{
        block.linkText || ''
      }}</a>
    </div>
  </div>

  <div class="footer-social-media-container">
    <div *ngFor="let block of socialMediaBlocks; let i = index">
      <a href="{{ block.link || '' }}" class="footer-social-media-block footer-social-media-link" key="i">
        <img src="{{ block.imgSrc || '' }}" alt="{{ block.imgAlt || '' }}" />
      </a>
    </div>
  </div>

  <div class="footer-corporation-information-text">
    <p>
      <strong>{{ themeName | titlecase }} {{ contactInformation[0] || '' }}</strong>
    </p>
    <p>
      {{ contactInformation[1] || '' }}
      <br />
      {{ contactInformation[2] || '' }}
    </p>
    <a class="footer-corporation-information-link" href="{{ 'footer-contact-information-link' | translate }}">
      {{ 'footer-contact-information-text' | translate }}
    </a>
  </div>
</footer>
