<div class="modal-content">
  <h2>{{ 'driver-details-driving-license-help-subtitle' | translate }}</h2>
  <hr />
  <ng-container *ngIf="finland()">
    <p class="modal__paragraph modal__paragraph--center">
      {{ 'driver-details-driving-license-help-p1' | translate }}
    </p>
    <img
      alt="Drivers License Picture"
      src="../assets/img/drivers-license.svg"
      class="driver-license-img driver-license-img--bottomMargin"
    />
  </ng-container>

  <ng-container *ngIf="!finland()">
    <p class="modal__paragraph">
      {{ 'driver-details-driving-license-help-p1' | translate }}
    </p>
    <p class="modal__paragraph">
      {{ 'driver-details-driving-license-help-p2' | translate }}
    </p>
    <p>
      <ng-container *ngIf="norway() && !isEu">
        <img alt="Drivers License Picture" src="../assets/img/drivers-license-norway.svg" class="driver-license-img" />
      </ng-container>
      <ng-container *ngIf="!norway() || isEu">
        <img alt="Drivers License Picture" src="../assets/img/drivers-license-eu.svg" class="driver-license-img" />
      </ng-container>
    </p>
    <ng-container *ngIf="norway() && !isEu">
      <ul>
        <li>{{ 'driver-details-driving-license-help-li1-no' | translate }}</li>
        <li>{{ 'driver-details-driving-license-help-li2-no' | translate }}</li>
        <li>{{ 'driver-details-driving-license-help-li3-no' | translate }}</li>
        <li>{{ 'driver-details-driving-license-help-li4-no' | translate }}</li>
      </ul>
    </ng-container>
    <ng-container *ngIf="!norway() || isEu">
      <ul>
        <li>{{ 'driver-details-driving-license-help-li1' | translate }}</li>
        <li>{{ 'driver-details-driving-license-help-li2' | translate }}</li>
        <li>{{ 'driver-details-driving-license-help-li3' | translate }}</li>
        <li>{{ 'driver-details-driving-license-help-li4' | translate }}</li>
      </ul>
    </ng-container>
    <ng-container *ngIf="norway() && !isEu">
      <div (click)="toggleDrivingLicense()" class="buttonWrapper">
        <div class="drivingLicenseFlag flagIcon flagIcon--eu"></div>
        <p class="toggleDrivingLicenseBtn">{{ 'driver-details-driving-license-european-union' | translate }}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="norway() && isEu">
      <div (click)="toggleDrivingLicense()" class="buttonWrapper">
        <div class="drivingLicenseFlag flagIcon flagIcon--no"></div>
        <p class="toggleDrivingLicenseBtn">{{ 'driver-details-driving-license-norway' | translate }}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="denmark() && isEu">
      <div (click)="toggleDrivingLicense()" class="buttonWrapper">
        <div class="drivingLicenseFlag flagIcon flagIcon--dk"></div>
        <p class="toggleDrivingLicenseBtn">{{ 'driver-details-driving-license-denmark' | translate }}</p>
      </div>
    </ng-container>
  </ng-container>

  <button class="btn__modal" tabindex="0" (click)="closeModal('driving-license-modal')">
    {{ 'close' | translate }}
  </button>
</div>
