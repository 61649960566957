import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

import environment from '../../environments/environment'
import MessagesService from '../shared/messages.service'
import ModalService from '../shared/modal/modal.service'
import LocalStorageService from './local-storage.service'

const handleError = (error: HttpErrorResponse): Observable<never> => {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred.
    console.error('An error occurred:', error.error.message)
  } else {
    // The backend returned an unsuccessful response code.
    console.error('An error occurred:', error.message)
  }

  // return an observable with a user-facing error message
  return throwError(() => new Error('Something bad happened; please try again later.'))
}
@Injectable()
export default class RequestInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private messages: MessagesService,
    private modalService: ModalService,
    private localStorageService: LocalStorageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: (error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status < 200 || error.status >= 300) {
              // notify user about common errors

              // Bad request
              if (error.status === 400) {
                this.messages.add('error-reservation-not-found-title')
              }
              // Unauthorized
              if (error.status === 401) {
                this.messages.add('error-reservation-not-found-title')
              }
              // Forbidden
              if (error.status === 403) {
                this.messages.add('error-reservation-not-found-title')
              }
              // Not found
              if (error.status === 404) {
                this.messages.add('error-page-not-found-title')
              }
              // Locked by user error
              if (error.status === 409) {
                this.messages.addErrorMessage(error.error.message)
                this.messages.add('error-reservation-locked-by-user-title')
              }
              // Internal Server Error
              if (error.status === 500) {
                this.messages.add('error-something-went-wrong-title')
              }
              // Service Unavailable
              if (error.status === 503) {
                this.messages.add('error-something-went-wrong-title')
              }
            }
          }
          if (
            error.error.path.includes(environment.quickFillNorwayBaseUrl) ||
            error.error.path.includes(environment.quickFillSwedenBaseUrl)
          ) {
            // Don't navigate to error page
            return
          }

          if (error.error.path.startsWith(environment.paymentComplete)) {
            // Remove bad orderRef and navigate to credit-card-page with a error message to open credit-card-error modal
            this.localStorageService.removeOrderRef()
            this.messages.add('credit-card-error-message')

            // Remove query params
            this.localStorageService.setStepValid('/credit-card')
            this.router.navigate(['/credit-card'])

            return
          }
          this.localStorageService.setStepValid('/error')
          this.router.navigate(['/error'])
        },
      }),
      catchError(handleError)
    )
  }
}
