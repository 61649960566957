<div class="modal-content">
  <h1 class="modal__heading">{{ 'my-booking-info-title' | translate }}</h1>
  <hr />

  <h3>
    {{ 'my-booking-info-paragraph' | translate }}
  </h3>
  <p class="modal__paragraph">{{ 'my-booking-info-text' | translate }}</p>
  <p class="modal__paragraph">
    {{ 'my-booking-info-subtitle' | translate }}
  </p>

  <ng-container>
    <ul>
      <li class="modal__paragraph">{{ 'my-booking-info-li1' | translate }}</li>
      <li class="modal__paragraph">{{ 'my-booking-info-li2' | translate }}</li>
      <li class="modal__paragraph">{{ 'my-booking-info-li3' | translate }}</li>
      <li class="modal__paragraph">{{ 'my-booking-info-li4' | translate }}</li>
    </ul>
  </ng-container>

  <button class="btn__modal" tabindex="-1" (click)="closeModal('read-more-modal')">
    {{ 'close' | translate }}
  </button>
</div>
