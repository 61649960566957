<div class="flex-row sectionTitle">
  <span class="sectionHeading"> {{ 'credit-card' | translate }} </span>
  <span (click)="navigateToPayex()" class="btn__edit"></span>
</div>

<div class="flex-column">
  <div class="flex-row">
    <div class="section__label">
      {{ 'credit-card-number' | translate }}
    </div>
    <div class="information-label">
      {{ paymentDetails?.creditCard?.maskedCreditCardNumber }}
    </div>
  </div>

  <div class="flex-row">
    <div class="section__label">
      {{ 'credit-card-type' | translate }}
    </div>
    <div class="information-label">
      <img alt="credit-card-icon" [src]="getCreditCardAsset()" class="credit-card-icon" />
    </div>
  </div>
</div>
