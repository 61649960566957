import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import LocalStorageService from '../local-storage.service'
import StepperService from './stepper.service'

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export default class StepperComponent implements OnInit {
  skipPayment: string

  /**
   * Maps url routes to a step number
   */
  private urlMap = {}

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private stepperService: StepperService
  ) {}

  ngOnInit(): void {
    this.urlMap = this.stepperService.getStepMap()
  }

  /**
   * Get a list of urls for steps in application
   */
  getStepUrls(): Array<string> {
    return Object.values(this.urlMap)
  }

  /**
   * Checks if the link for a step should be enabled to click
   *
   * @param stepNo the step to be checked if it should be enabled tp click
   */
  linkEnabled(stepNo: number): boolean {
    let currentRoute = ''
    if (this.activatedRoute.snapshot.routeConfig) {
      currentRoute = this.activatedRoute.snapshot.routeConfig.path
    }
    const currentRouteLinkNo = this.getKeyByLinkValue(currentRoute)

    if (stepNo <= currentRouteLinkNo) {
      return true
    }

    return false
  }

  /**
   * Gets the css-classes for the specifies step
   *
   * @param stepNo The step to get css classes for
   */
  getStepperClass(stepNo: number): string {
    let url = ''
    if (this.activatedRoute.snapshot.routeConfig) {
      url = this.activatedRoute.snapshot.routeConfig.path
    }
    const currentRouteLinkNo = this.getKeyByLinkValue(url)
    const stepperClass = 'stepper__item'
    const stepperClassDone = `${stepperClass} stepper__item--done`
    const stepperClassSelected = `${stepperClass} stepper__item--selected`

    if (stepNo < currentRouteLinkNo) {
      return stepperClassDone
    }
    if (stepNo === currentRouteLinkNo) {
      return stepperClassSelected
    }

    return stepperClass
  }

  /**
   * Gets the stepnumber for a specified step name
   *
   * @param value The step name to get stepnumber for
   */
  private getKeyByLinkValue(value): number {
    return Number(Object.keys(this.urlMap).find((key) => this.urlMap[key] === value))
  }
}
