<div
  [ngClass]="{
    noAdditionalInfo:
      (!showInfoTextsPickup && returnOrPickup === 'pickup') || (!showInfoTextsDropOff && returnOrPickup === 'return')
  }"
  class="dialog-content"
>
  <div
    [ngClass]="{
      fullWidth:
        (!showInfoTextsPickup && returnOrPickup === 'pickup') || (!showInfoTextsDropOff && returnOrPickup === 'return')
    }"
    class="left-panel"
  >
    <div class="informationBox">
      <img class="informationBox__icon" src="../../../assets/icons/black_calendar.svg" />
      <div class="informationBox__title">{{ 'date-and-time' | translate }}</div>
      <div *ngIf="date" class="informationBox__content">
        <span>{{ date | localizedDateFormat: 'LLLL' | async }}</span>
        <span class="timeMargin">{{ date | localizedDateFormat: 'LT' | async }}</span>
      </div>
    </div>
    <div *ngIf="place" class="informationBox">
      <img class="informationBox__icon" src="../../../assets/icons/black_location_pin.svg" />
      <div class="informationBox__title">{{ 'location' | translate }}</div>
      <div class="informationBox__content">
        <p>
          {{ locationToShow?.name }}
        </p>
        <p class="subtext">
          {{ locationToShow?.address }}
        </p>
        <p class="subtext">
          {{ locationToShow?.city }}
        </p>
        <a
          id="{{ gtmSelectorStep }}-location-phone-link"
          class="btn__link--text infoButtonOrLinkClassName"
          href="tel:{{ place?.phoneNumber }}"
        >
          {{ place?.phoneNumber }}</a
        >
      </div>
    </div>
    <div *ngIf="map" class="informationBox informationBox__twoColumnOneRow">
      <div class="informationBox__content">
        <a
          href="{{ url }}"
          target="_blank"
          id="{{ gtmSelectorStep }}-location-map-link"
          class="btn__primary--outlined {{ infoButtonOrLinkClassName }}"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
            <path
              d="M11 0.25C8.53906 0.25 6.57031 2.25391 6.57031 4.67969C6.57031 6.68359 9.45312 10.2695 10.543 11.6055C10.7891 11.8516 11.1758 11.8516 11.4219 11.6055C12.5117 10.2695 15.4297 6.68359 15.4297 4.67969C15.4297 2.25391 13.4258 0.25 11 0.25ZM11 6.15625C10.1562 6.15625 9.52344 5.52344 9.52344 4.67969C9.52344 3.87109 10.1562 3.20312 11 3.20312C11.8086 3.20312 12.4766 3.87109 12.4766 4.67969C12.4766 5.52344 11.8086 6.15625 11 6.15625ZM1.57812 7.84375C1.15625 8.01953 0.875 8.44141 0.875 8.89844V17.6875C0.875 18.1094 1.26172 18.3906 1.61328 18.2148L6.5 16V7.80859C6.18359 7.24609 5.90234 6.71875 5.72656 6.19141L1.57812 7.84375ZM11 12.9062C10.4727 12.9062 10.0156 12.6953 9.69922 12.3086C8.99609 11.5 8.25781 10.5859 7.625 9.60156V16L14.375 18.25V9.60156C13.707 10.5859 12.9688 11.5 12.2656 12.3086C11.9492 12.6953 11.4922 12.9062 11 12.9062ZM20.3516 5.94531L15.5 8.125V18.25L20.3867 16.3164C20.8086 16.1406 21.125 15.7188 21.125 15.2617V6.47266C21.125 6.05078 20.7031 5.76953 20.3516 5.94531Z"
              fill="black"
            />
          </svg>
          {{ 'show-map' | translate }}
        </a>
      </div>
    </div>
    <div class="informationBox">
      <img class="informationBox__icon" src="../../../assets/icons/black_clock.svg" />
      <div class="informationBox__title">{{ 'my-booking-open' | translate }}</div>
      <div class="informationBox__content">
        <div class="openingHours">
          <div #dayEl class="dayRow" *ngFor="let day of openingHours">
            <span class="weekday">{{ getWeekday(day, dayEl) }}</span>
            <span *ngIf="day.open" class="time">
              {{ day.open | localizedDateFormat: 'LT' | async }} -
              {{ day.close | localizedDateFormat: 'LT' | async }}
            </span>
            <span *ngIf="!day.open" class="time">
              {{ 'closed' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="operatedBy" class="informationBox informationBox__twoColumnOneRow">
      <div class="informationBox__content blueStationBox">
        <h2>{{ 'operated-by' | translate }}</h2>
        <p>{{ station?.name }}</p>
        <button (click)="openModal('contact-station-modal')" class="btn__link noMinWidth">
          {{ 'contact-station' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="right-panel"
    *ngIf="
      (showInfoTextsPickup && returnOrPickup === 'pickup') || (showInfoTextsDropOff && returnOrPickup === 'return')
    "
  >
    <div class="informationBox informationBox__twoColumnOneRow">
      <div class="informationBox__title informationBox__content">{{ 'information-from-station' | translate }}</div>
    </div>

    <app-info-text-box
      *ngIf="returnOrPickup === 'pickup'"
      [title]="'important-pickup' | translate"
      [icon]="'../../../assets/icons/red_alert.svg'"
      [class]="'information--alert'"
      [body]="informationTexts.misc"
      [isEnglishLanguage]="isEnglishLanguage"
    ></app-info-text-box>

    <app-info-text-box
      *ngIf="returnOrPickup === 'pickup'"
      [title]="'location-information' | translate"
      [icon]="'../../../assets/icons/black_location_pin.svg'"
      [class]="'information--note'"
      [body]="informationTexts.location"
      [isEnglishLanguage]="isEnglishLanguage"
    ></app-info-text-box>

    <app-info-text-box
      *ngIf="returnOrPickup === 'pickup'"
      [title]="'parking-information' | translate"
      [icon]="'../../../assets/icons/black_parking.svg'"
      [class]="'information--note'"
      [body]="informationTexts.parking"
      [isEnglishLanguage]="isEnglishLanguage"
    ></app-info-text-box>

    <app-info-text-box
      *ngIf="returnOrPickup === 'return'"
      [title]="'important-return' | translate"
      [icon]="'../../../assets/icons/red_alert.svg'"
      [class]="'information--alert'"
      [body]="informationTexts.dropOff"
      [isEnglishLanguage]="isEnglishLanguage"
    ></app-info-text-box>

    <app-info-text-box
      *ngIf="returnOrPickup === 'return'"
      [title]="'return-information' | translate"
      [icon]="'../../../assets/icons/black_car.svg'"
      [class]="'information--note'"
      [body]="informationTexts.return"
      [isEnglishLanguage]="isEnglishLanguage"
    ></app-info-text-box>
  </div>
</div>

<app-modal id="contact-station-modal">
  <app-shared-contact-station [location]="station"></app-shared-contact-station>
</app-modal>
