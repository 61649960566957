import { Component, HostListener, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import BookingService from '../../core/booking.service'
import CountryComponent from '../../core/country/country.component'
import LocalStorageService from '../../core/local-storage.service'
import LanguageOption from '../../core/models/language-option'
import TranslateService from '../../core/translate.service'
import CreditCardService from '../../shared/credit-card.service'
import CreditCardRegistrationService from '../credit-card-registration.services'

@Component({
  selector: 'app-credit-card-registered',
  templateUrl: './credit-card-registered.component.html',
  styleUrls: ['./credit-card-registered.component.scss'],
})
export default class CreditCardRegisteredComponent extends CountryComponent implements OnInit {
  @Input() creditCardNumber

  @Input() creditCardName

  languageOptions: LanguageOption

  constructor(
    private router: Router,
    private creditCardService: CreditCardService,
    private bookingService: BookingService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private creditCardRegistrationService: CreditCardRegistrationService
  ) {
    super(localStorageService)
  }

  ngOnInit(): void {
    this.languageOptions = this.getLangOptions()
  }

  changeCard(): void {
    this.creditCardRegistrationService.setShowCreditCardNeeded(true)
  }

  nextPage(): void {
    this.confirmCheckIn()
  }

  /**
   * PUT booking information to backend and navigate to confirmation page on click
   */
  confirmCheckIn(): void {
    const callback = () => {
      this.localStorageService.setStepValid('/confirmation')
      this.router.navigate(['/confirmation'])
    }
    this.bookingService.putBooking(callback.bind(this))
  }

  /**
   * Fetch image assets from service
   *
   * @returns string
   */
  getCreditCardAsset(): string {
    return this.creditCardService.getCreditCardAsset(this.creditCardName)
  }

  /**
   * Get selected language or default
   */
  getSelectedLang(): string {
    return this.translate.currentLocaleOrDefault
  }
}
